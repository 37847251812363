import type {
  AssignmentArchiveResData,
  AssignmentBulkDestroyResData,
  AssignmentCreateFavoritesResData,
  AssignmentCreateResData,
  AssignmentDAO,
  AssignmentDependencyCountResData,
  AssignmentDestroyFavoritesResData,
  AssignmentDestroyResData,
  AssignmentListResData,
  AssignmentPartialUpdateResData,
  AssignmentRemoveTagsResData,
  AssignmentRetrieveResData,
  AssignmentSetTagsResData,
  AssignmentUpdateResData
} from '~/api/project/Assignment.types'
import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule, FavoriteModule, BulkDestroyModule } from '~/api/shared/core/modules'

export class AssignmentAPI extends DAOBase implements AssignmentDAO {
  private commonModule: CommonModule
  private favoriteModule: FavoriteModule
  private bulkDestroyModule: BulkDestroyModule
  private prefix = '/assignments'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
      favoriteModule: FavoriteModule
      bulkDestroyModule: BulkDestroyModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
    this.favoriteModule = modules.favoriteModule
    this.bulkDestroyModule = modules.bulkDestroyModule
  }

  public readonly list: AssignmentDAO['list'] = (args) =>
    this.commonModule.list<AssignmentListResData>({ ...args, prefix: this.prefix })

  public readonly retrieve: AssignmentDAO['retrieve'] = (args) =>
    this.commonModule.retrieve<AssignmentRetrieveResData>({ ...args, prefix: this.prefix })

  public readonly create: AssignmentDAO['create'] = (args) =>
    this.commonModule.create<AssignmentCreateResData>({ ...args, prefix: this.prefix })

  public readonly update: AssignmentDAO['update'] = (args) =>
    this.commonModule.update<AssignmentUpdateResData>({ ...args, prefix: this.prefix })

  public readonly partialUpdate: AssignmentDAO['partialUpdate'] = (args) =>
    this.commonModule.partialUpdate<AssignmentPartialUpdateResData>({ ...args, prefix: this.prefix })

  public readonly destroy: AssignmentDAO['destroy'] = (args) =>
    this.commonModule.destroy<AssignmentDestroyResData>({ ...args, prefix: this.prefix })

  public readonly bulkDestroy: AssignmentDAO['bulkDestroy'] = (args) =>
    this.bulkDestroyModule.bulkDestroy(<AssignmentBulkDestroyResData>{ ...args, prefix: this.prefix })

  public readonly archive: AssignmentDAO['archive'] = (args) => {
    return this.fetchBase.request<AssignmentArchiveResData>(`${this.prefix}/${args.path}/archive/`, {
      ...args,
      method: 'POST'
    })
  }

  public readonly dependencyCount: AssignmentDAO['dependencyCount'] = (args) => {
    return this.fetchBase.request<AssignmentDependencyCountResData>(`${this.prefix}/${args.path}/dependency_count/`, {
      ...args
    })
  }

  public readonly createFavorites: AssignmentDAO['createFavorites'] = (args) =>
    this.favoriteModule.createFavorites<AssignmentCreateFavoritesResData>({ ...args, prefix: this.prefix })

  public readonly destroyFavorites: AssignmentDAO['destroyFavorites'] = (args) =>
    this.favoriteModule.destroyFavorites<AssignmentDestroyFavoritesResData>({ ...args, prefix: this.prefix })

  public readonly invite: AssignmentDAO['invite'] = (args) => {
    return this.fetchBase.request<AssignmentArchiveResData>(`${this.prefix}/${args.path}/invite/`, {
      ...args,
      method: 'POST'
    })
  }

  public readonly roles: AssignmentDAO['roles'] = (args) => {
    return this.fetchBase.request<AssignmentArchiveResData>(`${this.prefix}/${args.path}/roles/`, { ...args })
  }

  public readonly unarchive: AssignmentDAO['unarchive'] = (args) => {
    return this.fetchBase.request<AssignmentArchiveResData>(`${this.prefix}/${args.path}/unarchive/`, {
      ...args,
      method: 'POST'
    })
  }

  public readonly defaultRoles: AssignmentDAO['defaultRoles'] = (args) => {
    return this.fetchBase.request<AssignmentArchiveResData>(`${this.prefix}/default_roles/`, { ...args })
  }

  public readonly performAction: AssignmentDAO['performAction'] = (args) => {
    return this.fetchBase.request<AssignmentArchiveResData>(`${this.prefix}/perform_action/`, {
      ...args,
      method: 'POST'
    })
  }

  public readonly setTags: AssignmentDAO['setTags'] = (args) => {
    return this.fetchBase.request<AssignmentSetTagsResData>(`${this.prefix}/set_tags/`, { ...args, method: 'POST' })
  }

  public readonly removeTags: AssignmentDAO['removeTags'] = (args) => {
    return this.fetchBase.request<AssignmentRemoveTagsResData>(`${this.prefix}/remove_tags/`, {
      ...args,
      method: 'POST'
    })
  }
}
