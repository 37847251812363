import type { CookieSetOptions } from 'universal-cookie'
import type { $FetchOptions } from '~/api/shared/core/types'
import { getClientHost } from '~/utils/getClientHost'

export const FETCH_OPTIONS: $FetchOptions = {
  headers: {
    'Content-Type': 'application/json',
    Accept: '*/*'
  }
}

export const COOKIE_OPTIONS: CookieSetOptions = {
  path: '/',
  sameSite: false
}

export const TOKEN = {
  AUTH: {
    value: 'AUTH_TOKEN',
    option: (domain: string): CookieSetOptions => ({
      ...COOKIE_OPTIONS,
      domain: import.meta.env.DEV ? getClientHost() : domain
    })
  },
  TENANT: {
    value: 'TENANT_TOKEN',
    option: (domain: string): CookieSetOptions => ({
      ...COOKIE_OPTIONS,
      domain: import.meta.env.DEV ? getClientHost() : domain
    })
  }
} as const
