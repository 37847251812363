<template>
  <div
    v-if="!context.disabled && showDatePicker"
    ref="popover"
    class="absolute z-10 translate-y-0.5 p-0"
    :avoid-collisions="true">
    <DatePicker
      v-if="context.range"
      v-model.range="date"
      class="[&_.vc-time-picker_select]:border-0"
      mode="date"
      :min-date="context.minDate"
      :max-date="context.maxDate"
      :columns="2"
      @update:model-value="setDateOnly($event)">
      <template v-if="context.showTime" #footer>
        <div class="flex justify-around">
          <DatePicker
            v-model="date.start"
            mode="time"
            hide-time-header
            time-accuracy="3"
            @update:model-value="setRangeInput($event, 'start')" />
          <DatePicker
            v-model="date.end"
            mode="time"
            hide-time-header
            time-accuracy="3"
            @update:model-value="setRangeInput($event, 'end')" />
        </div>
      </template>
    </DatePicker>
    <DatePicker
      v-else
      v-model="date"
      :disabled-dates="disabledDates"
      :min-date="context.minDate"
      :max-date="context.maxDate"
      mode="date"
      @dayclick="setInput" />
  </div>
</template>

<script setup>
import dayjs from 'dayjs'
import { DatePicker } from 'v-calendar'
import 'v-calendar/style.css'

const props = defineProps({
  context: {
    type: Object
  }
})

const popover = ref()
const node = props.context.node

let disabledDates = []
if (props.context.availableDates) {
  const availableTimes = props.context.availableDates.map((date) =>
    new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime()
  )
  disabledDates = [
    {
      start: null,
      end: null,
      repeat: {
        on: [({ date }) => !availableTimes.includes(date.getTime())]
      }
    }
  ]
} else if (props.context.disabledDates) {
  disabledDates = props.context.disabledDates
}

function handlePopover(e) {
  if (popover.value && !popover.value.contains(e.target)) {
    node.context.showDatePicker = false
    document.removeEventListener('click', handlePopover)
  }
}

const setRangeInput = (value, key) => {
  date.value[key] = value
  setInput()
}

const setDateOnly = (date) => {
  node.props.date = date
  node.input(`${dayjs(date.start).format('YYYY.MM.DD')} ~ ${dayjs(date.end).format('YYYY.MM.DD')}`)
}

const date = ref(props.context.value || (props.context.range ? {} : ''))
const tempDate = computed(() => props.context.value)
if (props.context.value) props.context.date = props.context.value
const showDatePicker = computed(() => {
  if (node.context.showDatePicker) document.addEventListener('click', handlePopover)
  return node.context.showDatePicker
})

const setInput = () => {
  node.props.date = date.value
  node.input(
    props.context.range
      ? date.value.start
        ? `${dayjs(date.value.start).format('YYYY.MM.DD HH:mm:ss')} ~ ${dayjs(date.value.end).format('YYYY.MM.DD HH:mm:ss')}`
        : ''
      : date.value
        ? `${dayjs(date.value).format('YYYY.MM.DD')}`
        : ''
  )
}

watch(
  tempDate,
  () => {
    if (tempDate.value) setInput()
  },
  { immediate: true }
)
</script>
