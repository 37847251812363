import type { TrainDAO, TrainListResData, TrainRetrieveResData } from '~/api/machine-learning/Train.types'
import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule } from '~/api/shared/core/modules'

export class TrainAPI extends DAOBase implements TrainDAO {
  private readonly commonModule: CommonModule
  private readonly prefix = '/trains'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
  }

  public readonly list: TrainDAO['list'] = (args) =>
    this.commonModule.list<TrainListResData>({
      prefix: this.prefix,
      ...args
    })

  public readonly retrieve: TrainDAO['retrieve'] = (args) =>
    this.commonModule.retrieve<TrainRetrieveResData>({
      prefix: this.prefix,
      ...args
    })
}
