import type {
  JobDAO,
  JobListResData,
  JobPartialUpdateResData,
  JobRetrieveResData,
  JobUpdateResData
} from '~/api/machine-learning/Job.types'
import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule } from '~/api/shared/core/modules'

export class JobAPI extends DAOBase implements JobDAO {
  private readonly commonModule: CommonModule
  private readonly prefix = '/jobs'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
  }

  public readonly list: JobDAO['list'] = (args) =>
    this.commonModule.list<JobListResData>({
      prefix: this.prefix,
      ...args
    })

  public readonly retrieve: JobDAO['retrieve'] = (args) =>
    this.commonModule.retrieve<JobRetrieveResData>({
      prefix: this.prefix,
      ...args
    })

  public readonly update: JobDAO['update'] = (args) =>
    this.commonModule.update<JobUpdateResData>({
      prefix: this.prefix,
      body: args.body,
      options: args?.options
    })

  public readonly partialUpdate: JobDAO['partialUpdate'] = (args) =>
    this.commonModule.partialUpdate<JobPartialUpdateResData>({
      prefix: this.prefix,
      body: args.body,
      options: args?.options
    })

  public readonly consoleLogsRetrieve: JobDAO['consoleLogsRetrieve'] = (args) =>
    this.fetchBase.request(`${this.prefix}/${args.path}/console_logs/`)

  public readonly tailConsoleLogsRetrieve: JobDAO['tailConsoleLogsRetrieve'] = (args) => {
    const controller = new AbortController()

    let baseURL = this.fetchBase.options.baseURL

    if (baseURL?.endsWith('/')) {
      baseURL = baseURL?.slice(0, -1)
    }

    return {
      fetcher: fetch(`${baseURL}${this.prefix}/${args.path}/tail_console_logs/`, {
        headers: this.fetchBase.options.headers,
        keepalive: true,
        signal: controller.signal
      }),
      controller
    }
  }
}
