<template>
  <AvatarImage :src :class="avatarImageVariant({ rounded })" />
</template>

<script setup lang="ts">
import { AvatarImage } from 'radix-vue'
import { avatarImageVariant } from '~/components/ui/avatar/index'
import type { AvatarImageProps } from './types'

const props = defineProps<AvatarImageProps>()

const src = computed<string>((oldValue) => {
  if (oldValue && (props.value.includes('X-Amz-Signature') || props.value.includes('X-Amz-Date'))) {
    const [url] = props.value.split('?')
    const [oldUrl] = oldValue.split('?')

    return url === oldUrl ? oldValue : props.value
  }

  return props.value
})
</script>
