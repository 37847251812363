import {
  outer,
  inner,
  wrapper,
  label,
  help,
  messages,
  message,
  icon,
  fileInput,
  fileItem,
  fileList,
  fileName,
  noFiles,
  files,
  $if,
  $extend,
  defaultIcon
} from '@formkit/inputs'
import { Button } from '@/components/ui/button'
import { removeValue } from '@/lib/formkit/sections'

/**
 * Input definition for a file input.
 * @public
 */
export const file = {
  /**
   * The actual schema of the input, or a function that returns the schema.
   */
  schema: outer(
    wrapper(
      label('$label'),
      inner(
        {
          $cmp: markRaw(Button),
          props: {
            class: 'formkit-prefix rounded',
            size: 'sm',
            disabled: '$disabled',
            color: '$buttonColor',
            variant: 'tint',
            value: '파일 선택'
          }
        },
        fileInput(),
        fileList(fileItem(icon('fileItem'), fileName('$file.name'))),
        noFiles(icon('noFiles'), '선택된 파일 없음'),
        $if(
          '$value.length',
          $extend(removeValue(), {
            attrs: {
              if: '$value.length === 1',
              onClick: '$handlers.resetFiles'
            }
          })
        )
      )
    ),
    help('$help'),
    messages(message('$message.value'))
  ),
  /**
   * The type of node, can be a list, group, or input.
   */
  type: 'input',
  /**
   * The family of inputs this one belongs too. For example "text" and "email"
   * are both part of the "text" family. This is primary used for styling.
   */
  family: 'text',
  /**
   * An array of extra props to accept for this input.
   */
  props: [],
  /**
   * Forces node.props.type to be this explicit value.
   */
  forceTypeProp: 'file',
  /**
   * Additional features that should be added to your input
   */
  features: [
    files,
    defaultIcon('fileItem', 'fileItem'),
    defaultIcon('fileRemove', 'fileRemove'),
    defaultIcon('noFiles', 'noFiles'),
    buttonColor,
    originalFileName
  ],
  /**
   * The key used to memoize the schema.
   */
  schemaMemoKey: '2m4a5jj1qc9'
}

function buttonColor(node) {
  node.on('created', () => {
    const errors = ref(node.props.errors)
    watch(
      errors.value,
      () => {
        node.context.buttonColor = errors.value?.length ? 'red' : 'blue'
      },
      { immediate: true }
    )
  })
}

function originalFileName(node) {
  node.on('created', () => {
    if (node.value.length)
      node.value.forEach((file) => {
        if (node.props.base64) {
          const pathParts = file.file.split('/')
          file.name = pathParts[pathParts.length - 1]
        }
      })
  })
}
