import { undefine } from '@formkit/utils'
import { cloneDeep } from 'lodash-es'

export const base64FilePlugin = (node) => {
  if (node.props.type === 'file') {
    node.addProps(['base64'])
    if (!undefine(node.props.base64)) return

    const multiple = undefine(node.props.attrs.multiple)

    node.hook.input((value, next) => {
      if (node.name in node.parent.valueOriginal) {
        let valueOriginal = node.parent.valueOriginal[node.name]
        if (valueOriginal) {
          value = []
          if (typeof valueOriginal === 'string') {
            valueOriginal = [valueOriginal]
          }
          valueOriginal.forEach((file) => value.push({ name: file.name, file }))
        }
        delete node.parent.valueOriginal[node.name]
      }
      return next(value)
    })

    node.on('input', ({ payload }) => {
      if (multiple) {
        node.props.base64 = []
      } else {
        node.props.base64 = null
      }
      if (payload) {
        try {
          payload.forEach((file) => {
            if (typeof file === 'object') {
              if (typeof file.file === 'object') {
                const reader = new FileReader()
                reader.onload = (event) => {
                  if (multiple) {
                    node.props.base64.push(event.target.result)
                  } else {
                    node.props.base64 = event.target.result
                  }
                }
                reader.readAsDataURL(file.file)
              } else if (multiple) {
                node.props.base64.push(file.file)
              } else {
                node.props.base64 = file.file
              }
            }
          })
        } catch (e) {}
      }
    })
  } else if (node.type === 'group') {
    node.valueOriginal = cloneDeep(node.value)
    const setBase64 = (node, data) => {
      for (const child of node.children) {
        if (child.type === 'group') {
          setBase64(child, data[child.name])
        } else {
          const base64 = child.props.base64
          if (base64 !== undefined) {
            data[child.name] = base64
          }
        }
      }
    }

    node.hook.submit((data, next) => {
      setBase64(node, data)
      return next(data)
    })
  }
}
