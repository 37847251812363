import { cva } from 'class-variance-authority'

export { default as Switch } from './Switch.vue'

export const switchVariants = cva(
  'peer inline-flex shrink-0 cursor-pointer items-center rounded-full !py-0.5 px-0.5 transition-colors disabled:pointer-events-none',
  {
    variants: {
      size: {
        md: 'h-5 w-9',
        sm: 'h-4 w-7'
      },
      disabled: {
        true: 'data-[state=checked]:bg-blue-300 data-[state=unchecked]:bg-slate-300',
        false: 'data-[state=checked]:bg-blue-600 data-[state=unchecked]:bg-slate-500'
      }
    },
    defaultVariants: {
      size: 'md',
      disabled: false
    }
  }
)

export const switchThumbVariants = cva(
  'pointer-events-none block rounded-full bg-white-950 shadow-lg transition-transform',
  {
    variants: {
      size: {
        md: 'h-4 w-4 data-[state=checked]:translate-x-4',
        sm: 'h-3 w-3 data-[state=checked]:translate-x-3'
      }
    },
    defaultVariants: {
      size: 'md'
    }
  }
)
