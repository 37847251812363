<template>
  <div class="relative w-full">
    <Vue3JsonEditor
      :model-value="context._value"
      :mode="context.mode"
      :expanded-on-start="context.expandedOnStart"
      @json-change="handleInput" />
    <div v-if="context.disabled" class="absolute inset-0 bg-slate-100 opacity-25"></div>
  </div>
</template>

<script setup>
const props = defineProps({
  context: {
    type: Object,
    required: true
  }
})

const handleInput = (value) => {
  if (!props.context.disabled) {
    props.context.node.input(value)
  }
}
</script>
