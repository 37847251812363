import { cn } from '~/lib/utils'
import text from './text'

export default {
  ...text,
  outer: cn([text.outer], 'group data-[disabled]:pointer-events-none'),
  inner: cn(
    [text.inner],
    'group/inner ring-inset items-start !outline-0 cursor-pointer group-data-[empty]:items-center group-[&:not([data-multiple])]/wrapper:items-center bg-white-950'
  ),
  input: cn([text.input], 'truncate placeholder:text-slate-500'),
  suffixIcon: cn([text.suffixIcon], 'group-data-[size=md]:w-5'),
  wrapper: tw`group/wrapper relative`,
  multipleInputWrapper: tw`
    grid w-full gap-y-1 [&>div>*]:border [&>div>*]:border-slate-300 [&>div>div]:bg-slate-100 
    [&>svg]:[&>div>*]:shrink-0 [&>svg]:[&>div>*]:!text-slate-500 [&>svg]:hover:[&>div>*]:!text-red-500`,
  multipleInputPlaceholder: tw`truncate text-slate-500`,
  listbox: tw`absolute top-[calc(100%+8px)] z-50 hidden w-full rounded-xl bg-white-950 shadow data-[place-top]:-top-2 data-[place-top]:-translate-y-full group-data-[expanded]/wrapper:block`,
  listItems: tw`max-h-72
    overflow-y-auto overflow-x-hidden p-2
    [&::-webkit-scrollbar-thumb]:rounded-full 
    [&::-webkit-scrollbar-thumb]:bg-black-400 
    [&::-webkit-scrollbar]:h-1.5 
    [&::-webkit-scrollbar]:w-1.5 
    [&::-webkit-scrollbar]:bg-transparent`,
  selection: tw`w-full truncate text-slate-900 group-data-[size=sm]:text-sm [&:not([selected])]:text-slate-500`,
  optionWrapper: tw`pointer-events-none flex h-6 w-11/12 items-center gap-x-2 overflow-hidden px-0.5`,
  optionLabel: tw`pointer-events-none truncate text-sm text-slate-900 group-[[selected]]/listItem:text-blue-700`,
  optionMediaWrapper: tw`
    pointer-events-none h-10 w-11/12 items-center overflow-hidden p-0.5
    [&_:has(>h5)]:truncate
    [&_h5]:block [&_h5]:truncate [&_h5]:group-[[selected]]/listItem:text-blue-700
    [&_p]:truncate
    [&_span]:data-[avatar-type=image]:p-px`,
  listItem: tw`
    group/listItem flex cursor-pointer items-center justify-between rounded px-2 py-1 text-transparent hover:bg-slate-200
    [&>svg]:pointer-events-none 
    last:[&>svg]:h-5 last:[&>svg]:w-5
    last:[&>svg]:[&[selected]]:text-blue-500
    [&_span]:box-border`,
  emptyMessage: tw`rounded px-2 py-1 text-slate-600`,
  loadMore: tw`m-2 cursor-pointer rounded px-2 py-1 text-center text-sm text-blue-600 hover:bg-slate-200`,
  loading: tw`pointer-events-none m-2 flex items-center justify-center gap-x-2 px-2 py-1 text-sm text-slate-600`,
  footerWrapper: tw`border-t border-slate-200 p-2`,
  footer: tw`flex items-center text-sm text-slate-900`
}
