import type {
  WorkshopLabelerDAO,
  WorkshopLabelerJoinCreateResData,
  WorkshopLabelerListResData,
  WorkshopLabelerOutFromQueueResData,
  WorkshopLabelerParticipateResData,
  WorkshopLabelerQueuePositionResData,
  WorkshopLabelerRetrieveResData
} from '~/api/project/WorkshopLabeler.types'
import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule } from '~/api/shared/core/modules'

export class WorkshopLabelerAPI extends DAOBase implements WorkshopLabelerDAO {
  private commonModule: CommonModule
  private prefix = '/workshops/labeler'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
  }

  public readonly list: WorkshopLabelerDAO['list'] = (args) =>
    this.commonModule.list<WorkshopLabelerListResData>({ ...args, prefix: this.prefix })

  public readonly retrieve: WorkshopLabelerDAO['retrieve'] = (args) =>
    this.commonModule.retrieve<WorkshopLabelerRetrieveResData>({ ...args, prefix: this.prefix })

  public readonly join: WorkshopLabelerDAO['join'] = (args) => {
    return this.fetchBase.request<WorkshopLabelerJoinCreateResData>(`${this.prefix}/${args.path}/join/`, {
      ...args,
      method: 'POST'
    })
  }

  public readonly participate: WorkshopLabelerDAO['participate'] = (args) => {
    return this.fetchBase.request<WorkshopLabelerParticipateResData>(`${this.prefix}/${args.path}/participate/`, {
      ...args,
      method: 'POST'
    })
  }

  public readonly outFromQueue: WorkshopLabelerDAO['outFromQueue'] = (args) => {
    return this.fetchBase.request<WorkshopLabelerOutFromQueueResData>(`${this.prefix}/${args.path}/out_from_queue/`, {
      ...args,
      method: 'POST'
    })
  }

  public readonly queuePosition: WorkshopLabelerDAO['queuePosition'] = (args) => {
    return this.fetchBase.request<WorkshopLabelerQueuePositionResData>(`${this.prefix}/${args.path}/queue_position/`, {
      ...args,
      method: 'GET'
    })
  }
}
