import type { DataUnitFileCreateResData, DataUnitFileDAO } from '~/api/catalog/DataUnitFile.types'
import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule } from '~/api/shared/core/modules'

export class DataUnitFileAPI extends DAOBase implements DataUnitFileDAO {
  private commonModule: CommonModule
  private prefix = '/data_unit_files'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
  }

  public readonly create: DataUnitFileDAO['create'] = (args) =>
    this.commonModule.create<DataUnitFileCreateResData>({ ...args, prefix: this.prefix })
}
