import { createInput } from '@formkit/vue'
import { cva } from 'class-variance-authority'
import ToggleInput from './ToggleInput.vue'

export const toggle = createInput(ToggleInput, {
  props: {
    size: { default: 'md' }
  }
})

export const toggleInputLabelVariants = cva('font-medium', {
  variants: {
    size: {
      md: '',
      sm: 'text-xs'
    },
    disabled: {
      true: 'text-slate-400',
      false: 'text-slate-900'
    }
  },
  defaultVariants: {
    size: 'md',
    disabled: false
  }
})

export const toggleInputDescriptionVariants = cva('text-sm', {
  variants: {
    size: {
      md: 'text-sm',
      sm: 'text-xs'
    },
    disabled: {
      true: 'text-slate-400',
      false: 'text-slate-600'
    }
  },
  defaultVariants: {
    size: 'md',
    disabled: false
  }
})
