import type { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import type FetchBase from '~/api/shared/core/fetch-base/FetchBase'
import {
  AssignmentModule,
  BulkDestroyModule,
  CommonModule,
  FavoriteModule,
  FileModule,
  ProjectSettingModule,
  StatisticsModule,
  TagActionModule
} from '~/api/shared/core/modules'

export interface DAOModules {
  assignment: AssignmentModule
  bulkDestroy: BulkDestroyModule
  common: CommonModule
  favorite: FavoriteModule
  file: FileModule
  projectSetting: ProjectSettingModule
  statistics: StatisticsModule
  tagAction: TagActionModule
}

export function unfoldModule(modules: { [key: string]: new (fetchBase: FetchBase) => DAOBase }, fetchBase: FetchBase) {
  const result = {} as DAOModules

  for (const key in modules) {
    // @ts-ignore
    result[`${key.charAt(0).toLowerCase()}${key.slice(1).replace('Module', '')}` as keyof Modules] = new modules[key](
      fetchBase
    )
  }

  return result
}
