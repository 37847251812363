import { createSection } from '@formkit/inputs'

/**
 * Option section used to show an option
 *
 * @public
 */
export const optionLabel = createSection('optionLabel', () => ({
  $el: 'span',
  attrs: {
    class: '$classes.optionLabel'
  },
  children: ['$option.label']
}))
