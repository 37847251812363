import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import type { BulkDestroyModuleDAO } from '~/api/shared/core/modules/types'
import type { ExtractModuleParams } from '~/api/shared/core/types'

export class BulkDestroyModule extends DAOBase implements BulkDestroyModuleDAO {
  public readonly bulkDestroy = <TReturn>({
    prefix,
    params,
    body,
    options
  }: ExtractModuleParams<BulkDestroyModuleDAO['bulkDestroy']>) => {
    const response = this.fetchBase.request<TReturn>(`${prefix}/`, {
      ...options,
      method: 'DELETE',
      params,
      body
    })
    return response
  }
}
