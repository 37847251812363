import type {
  DataGroupLabelerDAO,
  DataGroupLabelerListResData,
  DataGroupLabelerRetrieveResData
} from '~/api/catalog/DataGroupLabeler.types'
import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule } from '~/api/shared/core/modules'

export class DataGroupLabelerAPI extends DAOBase implements DataGroupLabelerDAO {
  private commonModule: CommonModule
  private prefix = '/data_groups/labeler'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
  }

  public readonly list: DataGroupLabelerDAO['list'] = (args) =>
    this.commonModule.list<DataGroupLabelerListResData>({ ...args, prefix: this.prefix })

  public readonly retrieve: DataGroupLabelerDAO['retrieve'] = (args) =>
    this.commonModule.retrieve<DataGroupLabelerRetrieveResData>({ ...args, prefix: this.prefix })
}
