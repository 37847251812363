import type {
  DataGroupReviewerDAO,
  DataGroupReviewerListResData,
  DataGroupReviewerRetrieveResData
} from '~/api/catalog/DataGroupReviewer.types'
import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule } from '~/api/shared/core/modules'

export class DataGroupReviewerAPI extends DAOBase implements DataGroupReviewerDAO {
  private commonModule: CommonModule
  private prefix = '/data_groups/reviewer'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
  }

  public readonly list: DataGroupReviewerDAO['list'] = (args) =>
    this.commonModule.list<DataGroupReviewerListResData>({ ...args, prefix: this.prefix })

  public readonly retrieve: DataGroupReviewerDAO['retrieve'] = (args) =>
    this.commonModule.retrieve<DataGroupReviewerRetrieveResData>({ ...args, prefix: this.prefix })
}
