import type {
  UserEmailCreateResData,
  UserEmailDAO,
  UserEmailDestroyResData,
  UserEmailListResData,
  UserEmailSetAsPrimaryResData
} from '~/api/account/UserEmail.types'
import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule } from '~/api/shared/core/modules'

export class UserEmailAPI extends DAOBase implements UserEmailDAO {
  private commonModule: CommonModule
  private prefix = '/user_emails'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
  }

  public readonly list: UserEmailDAO['list'] = (args) =>
    this.commonModule.list<UserEmailListResData>({ ...args, prefix: this.prefix })

  public readonly create: UserEmailDAO['create'] = (args) =>
    this.commonModule.create<UserEmailCreateResData>({ ...args, prefix: this.prefix })

  public readonly destroy: UserEmailDAO['destroy'] = (args) =>
    this.commonModule.destroy<UserEmailDestroyResData>({ ...args, prefix: this.prefix })

  public readonly setAsPrimary: UserEmailDAO['setAsPrimary'] = (args) => {
    const response = this.fetchBase.request<UserEmailSetAsPrimaryResData>(
      `${this.prefix}/${args.path}/set_as_primary/`,
      {
        ...args,
        method: 'POST'
      }
    )
    return response
  }
}
