import type {
  VerificationMethodDAO,
  VerificationMethodDefaultResData,
  VerificationMethodListResData
} from '~/api/account/VerificationMethod.types'
import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule } from '~/api/shared/core/modules'

export class VerificationMethodAPI extends DAOBase implements VerificationMethodDAO {
  private commonModule: CommonModule
  private prefix = '/verification_methods'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
  }

  public readonly list: VerificationMethodDAO['list'] = (args) =>
    this.commonModule.list<VerificationMethodListResData>({ ...args, prefix: this.prefix })

  public readonly default: VerificationMethodDAO['default'] = (args) => {
    const response = this.fetchBase.request<VerificationMethodDefaultResData>(`${this.prefix}/default/`, { ...args })
    return response
  }
}
