import { createAutoHeightTextareaPlugin } from '@formkit/addons'
import { asteriskToRequiredInputLabelPlugin } from './asteriskToRequiredInputLabelPlugin'
import { base64FilePlugin } from './base64FilePlugin'
import { colorpickerPlugin } from './colorpickerPlugin'
import { iconToHelpPlugin } from './iconToHelpPlugin'
import { inputSizePlugin } from './inputSizePlugin'
import { sliderPlugin } from './sliderPlugin'
import { togglePlugin } from './togglePlugin'
import { verificationPlugin } from './verificationPlugin'

export default [
  asteriskToRequiredInputLabelPlugin,
  iconToHelpPlugin,
  inputSizePlugin,
  createAutoHeightTextareaPlugin(),
  togglePlugin,
  sliderPlugin,
  colorpickerPlugin,
  base64FilePlugin,
  verificationPlugin
]
