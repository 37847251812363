import { findSection } from '@formkit/inputs'

export function togglePlugin(node) {
  if (node.props.type !== 'toggle') return

  node.addProps(['description'])
  const schemaFn = node.props.definition.schema
  node.props.definition.schema = (sectionsSchema = {}) => {
    const definitionSchema = schemaFn(sectionsSchema)
    const outerSection = findSection(definitionSchema, 'outer')[1].else
    const wrapperSection = findSection(outerSection.children, 'wrapper')[1].else
    wrapperSection.children.splice(0, 1)
    return definitionSchema
  }
}
