import { tenantSlugBlackList } from '~/app/router.options'
import { useAPI } from '~/composables/shared/useAPI'
import { useTokenCookies } from '~/composables/shared/useTokenCookies'
import { TOKEN } from '~/constants/shared'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const api = useAPI()
  const { cookie } = useTokenCookies()
  const config = useRuntimeConfig()

  const authToken = useCookie(TOKEN.AUTH.value)
  const tenantToken = useCookie(TOKEN.TENANT.value)
  const isAuthorized = Boolean(authToken.value)
  const isIncludeBlackListOnto = tenantSlugBlackList.some((item) => to.name === 'index' || to.path.includes(item))
  const hasTenantToken = Boolean(tenantToken.value)

  if (to.path.includes('data-lab') && hasTenantToken && from.params.tenant) {
    cookie.remove(TOKEN.TENANT.value, TOKEN.TENANT.option(config.public.baseCookieDomain))
    return navigateTo({ name: 'data-lab-role', params: { role: 'labeler' } })
  }

  if (!isAuthorized) return

  if (!isIncludeBlackListOnto && !to.params.tenant && from.params.tenant) {
    to.params.tenant = from.params.tenant
    return to
  }

  if (isIncludeBlackListOnto) return

  const tenants = await api.worker.tenant.list()

  if (!tenants.results || tenants.results.length === 0) throw createError({ statusCode: 403 })

  if (!to.path.includes('data-lab') && !to.params.tenant && !from.params.tenant) {
    if (!hasTenantToken) {
      cookie.set(
        TOKEN.TENANT.value,
        tenants.results[tenants.results.length - 1].code,
        TOKEN.TENANT.option(config.public.baseCookieDomain)
      )
    }

    to.params.tenant = tenants.results[tenants.results.length - 1].slug

    return to
  }

  if (to.params.tenant) {
    const tenantInfo = tenants.results.find((item) => item.slug === to.params.tenant)
    if (!tenantInfo) {
      from.params.tenant = ''
      to.params.tenant = ''
      throw createError({ statusCode: 403 })
    }

    if (tenantToken.value !== tenantInfo.code) {
      cookie.set(TOKEN.TENANT.value, tenantInfo.code, TOKEN.TENANT.option(config.public.baseCookieDomain))
    }
  }
})
