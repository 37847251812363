// eslint-disable-next-line vue/prefer-import-from-vue
import { hyphenate, camelize } from '@vue/shared'
import { getCurrentInstance, toHandlerKey } from 'vue'

/**
 * Determines if the current component has a listener for a specific event
 *
 * See https://github.com/vuejs/core/blob/main/packages/runtime-core/src/componentEmits.ts#L166
 *
 * @param event Name of the event you wish to look for, eg: 'click'
 */
export function hasEmitListener(event: string) {
  const instance = getCurrentInstance()
  if (!instance) {
    return false
  }
  const props = instance.vnode.props
  const isModelListener = event.startsWith('update:')
  let handler =
    props?.[toHandlerKey(event)] ||
    // also try camelCase event handler (#2249)
    props?.[toHandlerKey(camelize(event))]

  if (!handler && isModelListener) {
    handler = props?.[toHandlerKey(hyphenate(event))]
  }

  return Boolean(handler)
}
