import { findSection } from '@formkit/inputs'
import { extend } from '@formkit/utils'

export function sliderPlugin(node) {
  if (node.props.type !== 'slider') return

  node.addProps({
    showRangeLabel: {
      boolean: true,
      default: false
    },
    mode: {
      default: 'handle'
    }
  })

  if (node.props.mode === 'ratio' && node._value.length === 2) {
    node.on('input', () => {
      node.context.twGradientToPosition = node._value[0] + '%'
    })
  }

  // TODO : 추후 해당 로직 또한 input 이벤트 로직 안에 포함되도록 고려
  if (node.root.props.type === 'form') {
    node.root.hook.submit((payload, next) => {
      if (node.props.mode === 'ratio') {
        if (typeof node._value === 'number') payload[node.name] = [node._value, 100 - node._value]
        else payload[node.name] = [node._value[0], node._value[1] - node._value[0], 100 - node._value[1]]
      }
      return next(payload)
    })
  }

  const schemaFn = node.props.definition.schema

  node.props.definition.schema = (sectionsSchema = {}) => {
    sectionsSchema.prefix = {
      children: [
        {
          $el: 'span',
          if: '$showRangeLabel',
          attrs: {
            class: '$classes.prefix',
            innerHTML: '$min'
          }
        }
      ]
    }
    sectionsSchema.suffix = {
      children: [
        {
          $el: 'span',
          if: '$showRangeLabel',
          attrs: {
            class: '$classes.suffix',
            innerHTML: '$max'
          }
        }
      ]
    }
    sectionsSchema.trackWrapper = extend(sectionsSchema.trackWrapper, {
      attrs: {
        style: {
          '--tw-gradient-to-position': '$twGradientToPosition'
        }
      }
    })
    sectionsSchema.outer = extend(sectionsSchema.outer, {
      attrs: {
        'data-mode': '$mode'
      }
    })
    const definitionSchema = schemaFn(sectionsSchema)
    const wrapperSection = findSection(definitionSchema, 'wrapper')[1].else
    moveArrayItem(wrapperSection.children, 1, 3)
    return definitionSchema
  }
}
