<template>
  <Button
    type="button"
    class="w-full"
    variant="outline"
    :size="context.size === 'md' ? 'xl' : 'lg'"
    :value="context.label || '본인인증'"
    :disabled="context.disabled"
    @click="verify" />
</template>

<script setup>
import * as PortOne from '@portone/browser-sdk/v2'
import { useMutation } from '@tanstack/vue-query'
import { useAPI } from '~/composables/shared/useAPI'
import { useAlertBanner } from '~/composables/shared/useAlertBanner'

const api = useAPI()
const alertBanner = useAlertBanner()

const props = defineProps({
  context: {
    type: Object,
    required: true
  }
})

const { mutate } = useMutation({
  mutationFn: async (body) => {
    return await api.account.user.verify({ body })
  },
  onSuccess: (data) => {
    props.context.node.input(data)
  },
  onError: (error) => {
    const nonFieldErrors = error.response._data.non_field_errors
    if (nonFieldErrors) {
      nonFieldErrors.forEach((message) => {
        alertBanner.add({
          description: message,
          color: 'red'
        })
      })
    }
  }
})

const verify = async () => {
  const body = { verification: {}, verification_method: '1' }
  const response = await PortOne.requestIdentityVerification({
    storeId: 'store-409e2f00-c06f-4261-9e51-7819cc51f6e8',
    identityVerificationId: `identity-verification-${crypto.randomUUID()}`,
    channelKey: 'channel-key-ce1221b6-b4c3-46c3-849a-3ce37220a322'
  })
  const { identityVerificationId } = response
  body.verification = { identity_verification_id: identityVerificationId }
  mutate(body)
}
</script>
