import {
  outer,
  boxInner,
  help,
  boxHelp,
  messages,
  message,
  prefix,
  suffix,
  fieldset,
  decorator,
  box,
  icon,
  legend,
  boxOptions,
  boxWrapper,
  boxLabel,
  options,
  checkboxes,
  $if,
  $extend,
  defaultIcon
} from '@formkit/inputs'
import { optionsIcon, optionsLayout, variant, nestedCheckboxes, outline } from '@/lib/formkit/features'
import { boxOption, optionDescription, optionIcon, optionLabel, optionLabelOuter } from '@/lib/formkit/sections'

/**
 * Input definition for a checkbox(ess).
 * @public
 */

export const checkbox = {
  /**
   * The actual schema of the input, or a function that returns the schema.
   */
  schema: outer(
    $if(
      '$options == undefined',
      /**
       * Single checkbox structure.
       */
      $extend(
        boxWrapper(
          boxInner(prefix(), box(), decorator(icon('decorator')), suffix()),
          $extend(boxLabel('$label'), {
            if: '$label'
          })
        ),
        {
          attrs: {
            outline: '$outline',
            'data-single': true
          }
        }
      ),
      /**
       * Multi checkbox structure.
       */
      fieldset(
        legend('$label'),
        $extend(
          boxOptions(
            boxOption(
              $if(
                '$slots.option',
                $extend(
                  boxWrapper(
                    '$slots.option',
                    $extend(box(), {
                      attrs: {
                        id: '$option.attrs.id',
                        value: '$option.value',
                        checked: '$fns.isChecked($option.value)'
                      }
                    })
                  ),
                  {
                    attrs: {
                      'data-slot': 'true'
                    }
                  }
                ),
                boxWrapper(
                  optionIcon(),
                  boxInner(
                    prefix(),
                    $if(
                      '$option.value',
                      $extend(box(), {
                        attrs: {
                          id: '$option.attrs.id',
                          value: '$option.value',
                          checked: '$fns.isChecked($option.value)'
                        }
                      }),
                      $extend(box(), {
                        attrs: {
                          value: '$option.label',
                          'data-partially-checked': '$option.partiallyChecked',
                          checked: '$fns.isChecked($option.label)'
                        }
                      })
                    ),
                    decorator(icon('decorator')),
                    suffix()
                  ),
                  $extend(boxLabel(optionLabelOuter(optionLabel(), optionDescription()), boxHelp('$option.help')), {
                    if: '$option.label',
                    $el: 'div'
                  })
                )
              ),
              $if(
                '$option.options',
                $extend(
                  boxOptions(
                    $extend(
                      boxOption(
                        boxWrapper(
                          optionIcon(),
                          boxInner(
                            prefix(),
                            $extend(box(), {
                              attrs: {
                                id: '$option.value',
                                value: '$option.value',
                                checked: '$fns.isChecked($option.value)'
                              }
                            }),
                            decorator(icon('decorator')),
                            suffix()
                          ),
                          $extend(
                            boxLabel(optionLabelOuter(optionLabel(), optionDescription()), boxHelp('$option.help')),
                            {
                              if: '$option.label',
                              $el: 'div'
                            }
                          )
                        )
                      ),
                      {
                        for: ['option', '$option.options']
                      }
                    )
                  ),
                  { attrs: { 'data-child': true } }
                )
              )
            )
          ),
          {
            attrs: {
              outline: '$outline'
            }
          }
        ),
        help('$help')
      )
    ),
    // Help text only goes under the input when it is a single.
    $if('$options == undefined && $help', help('$help')),
    messages(message('$message.value'))
  ),
  /**
   * The type of node, can be a list, group, or input.
   */
  type: 'input',
  /**
   * The family of inputs this one belongs too. For example "text" and "email"
   * are both part of the "text" family. This is primary used for styling.
   */
  family: 'box',
  /**
   * An array of extra props to accept for this input.
   */
  props: ['options', 'onValue', 'offValue', 'optionsLoader'],
  /**
   * Forces node.props.type to be this explicit value.
   */
  forceTypeProp: 'checkbox',
  /**
   * Additional features that should be added to your input
   */
  features: [
    options,
    checkboxes,
    nestedCheckboxes,
    defaultIcon('decorator', 'checkboxDecorator'),
    variant,
    optionsLayout,
    optionsIcon,
    outline
  ],
  /**
   * The key used to memoize the schema.
   */
  schemaMemoKey: 'g7sdfnx4a1'
}
