import type { StorageDAO } from '~/api/machine-learning/types'
import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule } from '~/api/shared/core/modules'

export class StorageAPI extends DAOBase implements StorageDAO {
  private commonModule: CommonModule
  private prefix = '/storages'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
  }

  public readonly list: StorageDAO['list'] = ({ options }) =>
    this.commonModule.list<unknown>({ prefix: this.prefix, options })

  public readonly retrieve: StorageDAO['retrieve'] = ({ id, options }) =>
    this.commonModule.retrieve<unknown>({ prefix: this.prefix, id, options })

  public readonly files: StorageDAO['files'] = ({ id, options }) => {
    const response = this.fetchBase.request<unknown>(`${this.prefix}/${id}/files`, options)
    return response
  }
}
