export default defineAppConfig({
  icon: {
    mode: 'svg',
    customize(content: string) {
      const stroke = content.match(/stroke="[^"]*"/g)
      return stroke?.length
        ? content.replace(/stroke="[^"]*"/g, `stroke="currentColor"`)
        : content.replace(/fill="[^"]*"/g, `fill="currentColor"`)
    }
  }
})
