import type {
  ExperimentCreateResData,
  ExperimentDAO,
  ExperimentDestroyResData,
  ExperimentListResData,
  ExperimentPartialUpdateResData,
  ExperimentRetrieveResData,
  ExperimentUpdateResData,
  ExperimentCreateFavoritesResData,
  ExperimentDestroyFavoritesResData
} from '~/api/machine-learning/Experiment.types'
import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule, FavoriteModule } from '~/api/shared/core/modules'

export class ExperimentAPI extends DAOBase implements ExperimentDAO {
  private commonModule: CommonModule
  private favoriteModule: FavoriteModule

  private prefix = '/experiments'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
      favoriteModule: FavoriteModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
    this.favoriteModule = modules.favoriteModule
  }

  public readonly list: ExperimentDAO['list'] = (args) =>
    this.commonModule.list<ExperimentListResData>({ ...args, prefix: this.prefix })

  public readonly retrieve: ExperimentDAO['retrieve'] = (args) =>
    this.commonModule.retrieve<ExperimentRetrieveResData>({ ...args, prefix: this.prefix })

  public readonly create: ExperimentDAO['create'] = (args) =>
    this.commonModule.create<ExperimentCreateResData>({ ...args, prefix: this.prefix })

  public readonly update: ExperimentDAO['update'] = (args) =>
    this.commonModule.update<ExperimentUpdateResData>({ ...args, prefix: this.prefix })

  public readonly partialUpdate: ExperimentDAO['partialUpdate'] = (args) =>
    this.commonModule.partialUpdate<ExperimentPartialUpdateResData>({ ...args, prefix: this.prefix })

  public readonly destroy: ExperimentDAO['destroy'] = (args) =>
    this.commonModule.destroy<ExperimentDestroyResData>({ ...args, prefix: this.prefix })

  public readonly createFavorites: ExperimentDAO['createFavorites'] = (args) =>
    this.favoriteModule.createFavorites<ExperimentCreateFavoritesResData>({ ...args, prefix: this.prefix })

  public readonly destroyFavorites: ExperimentDAO['destroyFavorites'] = (args) =>
    this.favoriteModule.destroyFavorites<ExperimentDestroyFavoritesResData>({ ...args, prefix: this.prefix })
}
