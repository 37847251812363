import {
  outer,
  inner,
  wrapper,
  label,
  help,
  messages,
  message,
  icon,
  prefix,
  textInput,
  casts,
  $extend
} from '@formkit/inputs'
import { datePicker, removeValue } from '@/lib/formkit/sections'

/**
 * Input definition for a date.
 * @public
 */
export const date = {
  /**
   * The actual schema of the input, or a function that returns the schema.
   */
  schema: outer(
    wrapper(
      label('$label'),
      $extend(
        inner(
          icon('prefix', 'label'),
          prefix(),
          $extend(textInput(), {
            attrs: { type: 'text', placeholder: '$placeholder', readonly: true }
          }),
          $extend(removeValue(), { attrs: { onClick: '$handlers.removeValueClick' } }),
          icon('suffix')
        ),
        { attrs: { onClick: '$handlers.innerClick' } }
      ),
      datePicker()
    ),
    help('$help'),
    messages(message('$message.value'))
  ),
  /**
   * The type of node, can be a list, group, or input.
   */
  type: 'input',
  /**
   * The family of inputs this one belongs too. For example "text" and "email"
   * are both part of the "text" family. This is primary used for styling.
   */
  family: 'text',
  /**
   * An array of extra props to accept for this input.
   */
  props: [],
  /**
   * Forces node.props.type to be this explicit value.
   */
  /**
   * Additional features that should be added to your input
   */
  features: [casts, suffixIcon, props, handlers, hooks],
  /**
   * The key used to memoize the schema.
   */
  schemaMemoKey: '8unzevzi3bb'
}

function suffixIcon(node) {
  node.props.suffixIcon = 'heroicons:calendar-days-solid'
}

function props(node) {
  node.addProps({
    availableDates: {
      default: null
    },
    disabledDates: {
      default: null
    },
    minDate: {
      default: null
    },
    maxDate: {
      default: null
    },
    range: {
      boolean: true,
      default: false
    },
    showTime: {
      boolean: true,
      default: true
    },
    date: {
      default: null
    },
    placeholder: {
      default: '날짜를 선택해주세요.'
    }
  })
}

function handlers(node) {
  node.on('created', () => {
    node.context.handlers.innerClick = (e) => {
      e.stopPropagation()
      if (node.context.showDatePicker) node.context.showDatePicker = false
      else node.context.showDatePicker = true
    }
    node.context.handlers.removeValueClick = (e) => {
      e.stopPropagation()
      node.input(undefined)
      node.props.date = undefined
    }
  })
}

function hooks(node) {
  if (node.root.props.type === 'form') {
    node.root.hook.submit((payload, next) => {
      payload[node.name] = node.props.date
      return next(payload)
    })
  }
}
