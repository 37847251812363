import validate from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45auth_45global from "/usr/src/app/middleware/01.auth.global.ts";
import _02_45redirect_45global from "/usr/src/app/middleware/02.redirect.global.ts";
import _03_45tenant_45global from "/usr/src/app/middleware/03.tenant.global.ts";
import manifest_45route_45rule from "/usr/src/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45auth_45global,
  _02_45redirect_45global,
  _03_45tenant_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "password-reset": () => import("/usr/src/app/middleware/password-reset.ts")
}