import { TOKEN } from '~/constants/shared'

export default defineNuxtRouteMiddleware((to, from) => {
  const authToken = useCookie(TOKEN.AUTH.value)
  const tenantToken = useCookie(TOKEN.TENANT.value)
  const isAuthorized = Boolean(authToken.value)
  const hasTenantToken = Boolean(tenantToken.value)
  const skipAuth = Boolean(to.meta.skipAuth)

  if (to.path.includes('account') && !hasTenantToken) {
    to.meta.variant = 'worker'
  }

  if (!skipAuth && !isAuthorized && from?.params?.tenant && !to.path.includes('data-lab')) {
    return navigateTo({ name: 'auth-login' })
  }

  if (!skipAuth && !isAuthorized) {
    return navigateTo({ name: 'auth-login' })
  }

  if (skipAuth && isAuthorized) {
    return navigateTo({ name: 'projects' })
  }
  if (to.name === 'index' && isAuthorized && !hasTenantToken) {
    return navigateTo({ name: 'data-lab-role', params: { role: 'labeler' } })
  }
})
