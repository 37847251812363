import type {
  AgentCreateResData,
  AgentDAO,
  AgentDestroyResData,
  AgentListResData,
  AgentPartialUpdateResData,
  AgentRetrieveResData,
  AgentRunServiceResData,
  AgentSystemResData,
  AgentUpdateResData
} from '~/api/machine-learning/Agent.types'
import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule } from '~/api/shared/core/modules'

export class AgentAPI extends DAOBase implements AgentDAO {
  private commonModule: CommonModule
  private prefix = '/agents'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
  }

  public readonly list: AgentDAO['list'] = (args) =>
    this.commonModule.list<AgentListResData>({
      prefix: this.prefix,
      params: args?.params,
      options: args?.options
    })

  public readonly retrieve: AgentDAO['retrieve'] = (args) =>
    this.commonModule.retrieve<AgentRetrieveResData>({
      prefix: this.prefix,
      path: args?.path,
      options: args?.options
    })

  public readonly create: AgentDAO['create'] = (args) =>
    this.commonModule.create<AgentCreateResData>({
      prefix: this.prefix,
      body: args.body,
      options: args?.options
    })

  public readonly update: AgentDAO['update'] = (args) =>
    this.commonModule.update<AgentUpdateResData>({
      prefix: this.prefix,
      body: args.body,
      options: args?.options
    })

  public readonly partialUpdate: AgentDAO['partialUpdate'] = (args) =>
    this.commonModule.partialUpdate<AgentPartialUpdateResData>({
      prefix: this.prefix,
      body: args.body,
      options: args?.options
    })

  public readonly destroy: AgentDAO['destroy'] = (args) =>
    this.commonModule.destroy<AgentDestroyResData>({
      prefix: this.prefix,
      path: args?.path,
      body: args?.body,
      options: args?.options
    })

  public readonly runService: AgentDAO['runService'] = (args) =>
    this.fetchBase.request<AgentRunServiceResData>(`${this.prefix}/run_service`, {
      ...args?.options,
      method: 'POST',
      body: args.body
    })

  public readonly system: AgentDAO['system'] = (args) =>
    this.fetchBase.request<AgentSystemResData>(`${this.prefix}/system`, args?.options)
}
