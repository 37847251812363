import type { AlertBannerProps, AlertBannerState } from '~/components/ui/alert/types'

export function useAlertBanner() {
  const initialState: AlertBannerState = {
    active: false,
    props: {
      color: 'slate',
      description: 'default description',
      closeButton: false,
      confirmButton: false,
      autoClose: true,
      timeout: 5000
    },
    confirmHandler: null
  }
  const state = useState<AlertBannerState>('alertBanner', () => initialState)

  function add(alertBannerProps: Partial<AlertBannerProps> | string, color?: AlertBannerState['props']['color']) {
    if (typeof alertBannerProps === 'string') {
      alertBannerProps = { description: alertBannerProps, color: color || 'slate' }
    }

    state.value = {
      active: true,
      props: { ...state.value.props, ...alertBannerProps },
      confirmHandler: alertBannerProps.onConfirm || null
    }

    if (state.value.props.autoClose && (!state.value.props.closeButton || !state.value.props.confirmButton)) {
      setTimeout(() => {
        state.value.active = false
      }, state.value.props.timeout)
    }
  }

  function close() {
    state.value.active = false
  }

  function confirm() {
    if (state.value.confirmHandler) state.value.confirmHandler()
    close()
  }

  return {
    state: readonly(state),
    add,
    close,
    confirm
  }
}
