import type { QueryObserverOptions } from '@tanstack/vue-query'

export const VUE_QUERY_OPTIONS: Omit<QueryObserverOptions, 'queryKey'> = {
  staleTime: 0,
  gcTime: 0,
  retryDelay: 0,
  retry: 0,
  refetchOnWindowFocus: false,
  refetchOnReconnect: true
}
