import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import type { CommonModuleDAO } from '~/api/shared/core/modules/types'
import type { ExtractModuleParams } from '~/api/shared/core/types'

export class CommonModule extends DAOBase implements CommonModuleDAO {
  public readonly list = <TReturn>({
    prefix,
    params,
    options
  }: ExtractModuleParams<CommonModuleDAO['list']>): Promise<TReturn> => {
    const response = this.fetchBase.request<TReturn>(`${prefix}/`, {
      ...options,
      params
    })
    return response
  }

  public readonly retrieve = <TReturn>({
    prefix,
    path,
    params,
    options
  }: ExtractModuleParams<CommonModuleDAO['retrieve']>): Promise<TReturn> => {
    const response = this.fetchBase.request<TReturn>(`${prefix}/${path}/`, {
      ...options,
      params
    })
    return response
  }

  public readonly create = <TReturn>({
    prefix,
    body,
    options
  }: ExtractModuleParams<CommonModuleDAO['create']>): Promise<TReturn> => {
    const response = this.fetchBase.request<TReturn>(`${prefix}/`, {
      ...options,
      method: 'POST',
      body
    })
    return response
  }

  public readonly update = <TReturn>({
    prefix,
    path,
    options,
    body
  }: ExtractModuleParams<CommonModuleDAO['update']>): Promise<TReturn> => {
    const response = this.fetchBase.request<TReturn>(`${prefix}/${path}/`, {
      ...options,
      method: 'PUT',
      body
    })
    return response
  }

  public readonly partialUpdate = <TReturn>({
    prefix,
    path,
    body,
    options
  }: ExtractModuleParams<CommonModuleDAO['partialUpdate']>): Promise<TReturn> => {
    const response = this.fetchBase.request<TReturn>(`${prefix}/${path}/`, {
      ...options,
      method: 'PATCH',
      body
    })
    return response
  }

  public readonly destroy = <TReturn>({
    prefix,
    path,
    body,
    options
  }: ExtractModuleParams<CommonModuleDAO['destroy']>): Promise<TReturn> => {
    const response = this.fetchBase.request<TReturn>(`${prefix}/${path}/`, {
      ...options,
      method: 'DELETE',
      body
    })
    return response
  }
}
