import { isEmpty, reduce } from 'lodash-es'
import type { RouteRecordNormalized } from 'vue-router'

const buildPageTree = (pages: RouteRecordNormalized[]) => {
  const createRoutesTreeByPages = (pages: RouteRecordNormalized[]) => {
    const routesTree: DirectoryRoutesPageNode = {
      name: 'index',
      path: '/',
      children: {},
      siblings: []
    }

    for (const page of pages) {
      let pointerNode = routesTree
      const cleanPath = page.path?.replace('/:tenant?', '') || ''
      const pathNames = cleanPath.split('/').slice(1) || []

      pathNames.forEach((pathName, index) => {
        if (index === 0 && !pathName) return

        const node = pointerNode.children[pathName] || pointerNode
        const nodeName = `${pathNames.slice(0, index + 1).join('__')}`
        const path = `/${pathNames.slice(0, index + 1).join('/')}`

        if (node.path === path) {
          pointerNode = node
          return
        }

        node.children[pathName] = { name: nodeName, path, children: {}, siblings: [] }

        pointerNode = node.children[pathName]
      })
    }

    return routesTree
  }

  const routesTree = createRoutesTreeByPages(pages)

  const bindSiblings = (node: DirectoryRoutesPageNode) => {
    for (const key in node.children) {
      const childNode = node.children[key]
      const childs = Object.values(node.children)
      const siblings = reduce(
        childs,
        (acc, child) => {
          const childNodeNames = child.name.split('__')
          const childNodeKey = childNodeNames[childNodeNames.length - 1]

          if (childNodeKey !== key) {
            acc.push({ ...child, siblings: [] })
          }
          return acc
        },
        [] as DirectoryRoutesPageNode[]
      )
      childNode.siblings = siblings

      if (!isEmpty(childNode.children)) bindSiblings(childNode)
    }
  }

  bindSiblings(routesTree)

  return routesTree
}

export const usePageTreeStructure = () => {
  const allRoutes = useNuxtApp().$router.getRoutes()
  const pageTree = buildPageTree(allRoutes)

  return pageTree
}
