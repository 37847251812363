import type { LogCreateResData, LogDAO, LogListResData } from '~/api/machine-learning/Log.types'
import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule } from '~/api/shared/core/modules'

export class LogAPI extends DAOBase implements LogDAO {
  private commonModule: CommonModule
  private prefix = '/logs'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
  }

  public readonly list: LogDAO['list'] = (args) =>
    this.commonModule.list<LogListResData>({ prefix: this.prefix, ...args })

  // public readonly retrieve: LogDAO['retrieve'] = (args) =>
  //   this.commonModule.retrieve<unknown>({ prefix: this.prefix, ...args})

  public readonly create: LogDAO['create'] = (args) =>
    this.commonModule.create<LogCreateResData>({ prefix: this.prefix, ...args })
}
