import { en, ko } from '@formkit/i18n'
import { createProPlugin, inputs as defaultProInputs } from '@formkit/pro'
import { defineFormKitConfig } from '@formkit/vue'
import { inputs, proInputs } from '@/lib/formkit/inputs'
import defaultPlugins from '@/lib/formkit/plugins'
import rules from '@/lib/formkit/rules'
import classes from '@/lib/formkit/theme'
import { loadIcon } from '@/utils/icon'

export default defineFormKitConfig((): any => {
  const plugins = [...defaultPlugins]
  // FIXME: issue #31로 인한 임시책
  const proKey = 'fk-806a893e5b'

  if (proKey) {
    plugins.push(createProPlugin(proKey, { ...proInputs, ...defaultProInputs }))
  }

  return {
    iconLoader: loadIcon,
    inputs,
    plugins,
    config: { locales: { ko, en }, locale: 'en', classes },
    rules
  }
})
