import type { FetchOptions } from 'ofetch'
import type { $FetchCreator, $FetchOptions, $FetchRequest } from '~/api/shared/core/types'

export default abstract class FetchBase {
  protected readonly $fetch: $FetchCreator['instance']
  public readonly options: FetchOptions

  constructor(creator: $FetchCreator) {
    this.$fetch = creator.instance
    this.options = creator.options
  }

  public abstract request<TReturn>(uri: $FetchRequest, options?: $FetchOptions): Promise<TReturn>
}
