<template>
  <SwitchRoot v-bind="forwarded" :class="cn(switchVariants({ size, disabled }), props.class)">
    <SwitchThumb :class="cn(switchThumbVariants({ size }))" />
  </SwitchRoot>
</template>

<script setup lang="ts">
import { SwitchRoot, type SwitchRootEmits, type SwitchRootProps, SwitchThumb, useForwardPropsEmits } from 'radix-vue'
import type { HTMLAttributes } from 'vue'
import { cn } from '@/lib/utils'
import { switchVariants, switchThumbVariants } from '.'

const props = withDefaults(
  defineProps<SwitchRootProps & { size?: 'md' | 'sm' } & { class?: HTMLAttributes['class'] }>(),
  { size: 'md' }
)

const emits = defineEmits<SwitchRootEmits>()

const delegatedProps = computed(() => {
  const { class: _, ...delegated } = props

  return delegated
})

const forwarded = useForwardPropsEmits(delegatedProps, emits)
</script>
