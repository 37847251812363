<template>
  <div v-if="context.label" class="flex w-full flex-col gap-1">
    <div class="flex justify-between">
      <span :class="toggleInputLabelVariants({ size: context.size, disabled: context.disabled })">
        {{ context.label }}
      </span>
      <Switch
        class="m-0.5"
        :checked="context.value || false"
        :size="context.size"
        :disabled="context.disabled"
        @update:checked="handleInput" />
    </div>
    <p
      v-if="context.description"
      :class="toggleInputDescriptionVariants({ size: context.size, disabled: context.disabled })">
      {{ context.description }}
    </p>
  </div>
  <Switch
    v-else
    :checked="context.value || false"
    :size="context.size"
    :disabled="context.disabled"
    @update:checked="handleInput" />
</template>

<script setup lang="ts">
import { toggleInputLabelVariants, toggleInputDescriptionVariants } from '.'
const props = defineProps({
  context: {
    type: Object,
    required: true
  }
})

const handleInput = (value: boolean) => {
  if (!props.context.disabled) {
    props.context.node.input(value)
  }
}
</script>
