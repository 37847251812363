import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule } from '~/api/shared/core/modules'
import type { CKEditorUploadDAO, ModelTemplateDAO } from '~/api/shared/utils/types'

export class CKEditorUploadAPI extends DAOBase implements CKEditorUploadDAO {
  private prefix = '/ckeditor5/image_upload/'
  public readonly imageUpload: CKEditorUploadDAO['imageUpload'] = ({ body, options }): Promise<unknown> => {
    const response = this.fetchBase.request<unknown>(this.prefix, {
      ...options,
      method: 'POST',
      body
    })
    return response
  }
}

export class ModelTemplateAPI extends DAOBase implements ModelTemplateDAO {
  private commonModule: CommonModule
  private prefix = '/model_templates'
  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
  }

  public readonly list: ModelTemplateDAO['list'] = ({ options }) =>
    this.commonModule.list<unknown>({ prefix: this.prefix, options })

  public readonly retrieve: ModelTemplateDAO['retrieve'] = ({ id, options }) =>
    this.commonModule.retrieve<unknown>({ prefix: this.prefix, id, options })

  public readonly create: ModelTemplateDAO['create'] = ({ body, options }) =>
    this.commonModule.create<unknown>({ prefix: this.prefix, body, options })

  public readonly update: ModelTemplateDAO['update'] = ({ id, body, options }) =>
    this.commonModule.update<unknown>({ prefix: this.prefix, id, body, options })

  public readonly partialUpdate: ModelTemplateDAO['partialUpdate'] = ({ id, body, options }) =>
    this.commonModule.partialUpdate<unknown>({ prefix: this.prefix, id, body, options })

  public readonly destroy: ModelTemplateDAO['destroy'] = ({ id, body, options }) =>
    this.commonModule.destroy<unknown>({ prefix: this.prefix, id, body, options })
}
