import type { AllocationReviewerDAO } from '~/api/project/types'
import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule } from '~/api/shared/core/modules'

export class AllocationReviewerAPI extends DAOBase implements AllocationReviewerDAO {
  private commonModule: CommonModule
  private prefix = '/allocations/reviewer'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
  }

  public readonly list: AllocationReviewerDAO['list'] = ({ options }) =>
    this.commonModule.list<unknown>({ prefix: this.prefix, options })

  public readonly retrieve: AllocationReviewerDAO['retrieve'] = ({ id, options }) =>
    this.commonModule.retrieve<unknown>({ prefix: this.prefix, id, options })
}
