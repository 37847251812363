import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule } from '~/api/shared/core/modules'
import type { CourseLabelerDAO } from '~/api/shared/tutorial/types'

export class CourseLabelerAPI extends DAOBase implements CourseLabelerDAO {
  private commonModule: CommonModule
  private prefix = '/courses/labeler'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
  }

  public readonly list: CourseLabelerDAO['list'] = ({ options }) =>
    this.commonModule.list<unknown>({ prefix: this.prefix, options })

  public readonly retrieve: CourseLabelerDAO['retrieve'] = ({ id, options }) =>
    this.commonModule.retrieve<unknown>({ prefix: this.prefix, id, options })

  public readonly create: CourseLabelerDAO['create'] = ({ body, options }) =>
    this.commonModule.create<unknown>({ prefix: this.prefix, body, options })

  public readonly update: CourseLabelerDAO['update'] = ({ id, body, options }) =>
    this.commonModule.update<unknown>({ prefix: this.prefix, id, body, options })

  public readonly partialUpdate: CourseLabelerDAO['partialUpdate'] = ({ id, body, options }) =>
    this.commonModule.partialUpdate<unknown>({ prefix: this.prefix, id, body, options })

  public readonly destroy: CourseLabelerDAO['destroy'] = ({ id, body, options }) =>
    this.commonModule.destroy<unknown>({ prefix: this.prefix, id, body, options })
}
