import { cn } from '~/lib/utils'
import text from './text'

export default {
  ...text,
  outer: cn([text.outer], 'group'),
  input: cn(
    [text.input],
    '[&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-thumb]:bg-black-400 [&::-webkit-scrollbar]:h-1.5 [&::-webkit-scrollbar]:w-1.5 [&::-webkit-scrollbar]:bg-transparent resize-none !h-16 group-data-[size=sm]:!h-11'
  )
}
