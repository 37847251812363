import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule } from '~/api/shared/core/modules'
import type {
  DraftCreateResData,
  DraftDAO,
  DraftDestroyResData,
  DraftRetrieveResData
} from '~/api/shared/utils/Draft.types'

export class DraftAPI extends DAOBase implements DraftDAO {
  private commonModule: CommonModule
  private prefix = '/drafts'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
  }

  public readonly retrieve: DraftDAO['retrieve'] = (args) =>
    this.commonModule.retrieve<DraftRetrieveResData>({ ...args, prefix: this.prefix })

  public readonly create: DraftDAO['create'] = (args) =>
    this.commonModule.create<DraftCreateResData>({ ...args, prefix: this.prefix })

  public readonly destroy: DraftDAO['destroy'] = (args) =>
    this.commonModule.destroy<DraftDestroyResData>({ ...args, prefix: this.prefix })
}
