import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import type { FavoriteModuleDAO } from '~/api/shared/core/modules/types'
import type { ExtractModuleParams } from '~/api/shared/core/types'

export class FavoriteModule extends DAOBase implements FavoriteModuleDAO {
  public readonly createFavorites = <TReturn>({
    prefix,
    path,
    body,
    options
  }: ExtractModuleParams<FavoriteModuleDAO['createFavorites']>) => {
    const response = this.fetchBase.request<TReturn>(`${prefix}/${path}/favorites/`, {
      ...options,
      method: 'POST',
      body
    })
    return response
  }

  public readonly destroyFavorites = <TReturn>({
    prefix,
    path,
    body,
    options
  }: ExtractModuleParams<FavoriteModuleDAO['destroyFavorites']>) => {
    const response = this.fetchBase.request<TReturn>(`${prefix}/${path}/favorites/`, {
      ...options,
      method: 'DELETE',
      body
    })
    return response
  }
}
