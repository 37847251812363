export default defineNuxtPlugin({
  name: 'vue3-json-editor',
  setup(nuxtApp) {
    nuxtApp.hooks.hook('app:beforeMount', async () => {
      const { Vue3JsonEditor } = await import('vue3-json-editor')

      // Vue3JsonEditor를 전역 컴포넌트로 등록
      nuxtApp.vueApp.component('Vue3JsonEditor', Vue3JsonEditor)
    })
  },
  parallel: true
})
