import { cn } from '@/lib/utils'
import text from './text'

export default {
  ...text,
  input: cn(text.input, 'absolute opacity-0 !w-fit'),
  fileList: tw`w-full overflow-hidden text-slate-900 group-data-[disabled]:text-slate-700`,
  fileItem: tw`truncate`,
  noFiles: tw`text-slate-500`
}
