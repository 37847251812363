import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule } from '~/api/shared/core/modules'
import type {
  ReviewTagDAO,
  ReviewTagsCreateResData,
  ReviewTagsDestroyResData,
  ReviewTagsListResData,
  ReviewTagsPartialUpdateResData,
  ReviewTagsRetrieveResData,
  ReviewTagsUpdateResData
} from './ReviewTag.types'

export class ReviewTagAPI extends DAOBase implements ReviewTagDAO {
  private commonModule: CommonModule
  private prefix = '/review_tags'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
  }

  public readonly list: ReviewTagDAO['list'] = (args) =>
    this.commonModule.list<ReviewTagsListResData>({ prefix: this.prefix, ...args })

  public readonly retrieve: ReviewTagDAO['retrieve'] = (args) =>
    this.commonModule.retrieve<ReviewTagsRetrieveResData>({ prefix: this.prefix, ...args })

  public readonly create: ReviewTagDAO['create'] = (args) =>
    this.commonModule.create<ReviewTagsCreateResData>({ prefix: this.prefix, ...args })

  public readonly update: ReviewTagDAO['update'] = (args) =>
    this.commonModule.update<ReviewTagsUpdateResData>({ prefix: this.prefix, ...args })

  public readonly partialUpdate: ReviewTagDAO['partialUpdate'] = (args) =>
    this.commonModule.partialUpdate<ReviewTagsPartialUpdateResData>({ prefix: this.prefix, ...args })

  public readonly destroy: ReviewTagDAO['destroy'] = (args) =>
    this.commonModule.destroy<ReviewTagsDestroyResData>({ prefix: this.prefix, ...args })
}
