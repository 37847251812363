import { createSection } from '@formkit/inputs'

/**
 * Option section used to show an option
 *
 * @public
 */
export const optionIcon = createSection('optionIcon', () => ({
  $el: 'span',
  if: '$option.iconSvg',
  attrs: {
    class: '$classes.icon',
    innerHTML: '$option.iconSvg'
  }
}))
