import { checkbox } from './checkbox'
import { color } from './color'
import { colorpicker } from './colorpicker'
import { date } from './date'
import { file } from './file'
import { iamport } from './iamport'
import { json } from './json'
import { password } from './password'
import { phoneNumber } from './phoneNumber'
import { radio } from './radio'
import { richText } from './rich-text'
import { select } from './select'
import { toggle } from './toggle'

export const inputs = {
  checkbox,
  password,
  radio,
  json,
  file,
  color,
  select,
  date,
  richText,
  iamport,
  toggle
}

export const proInputs = {
  phoneNumber,
  colorpicker
}
