import { getIcon } from '@/utils/icon'

export function iconToHelpPlugin(node) {
  const schemaFn = node.props.definition.schema
  const icon = getIcon('heroicons:exclamation-circle')

  node.props.definition.schema = (sectionsSchema = {}) => {
    sectionsSchema.help = {
      children: [
        {
          $el: 'span',
          attrs: {
            class: '$classes.helpIcon',
            innerHTML: icon
          }
        },
        {
          $el: 'pre',
          attrs: {
            innerHTML: '$help'
          }
        }
      ]
    }
    return schemaFn(sectionsSchema)
  }
}
