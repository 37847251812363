import type { ProjectLabelerStatisticsResData } from '~/api/project/ProjectLabeler.types'
import type { ProjectLabelerDAO, ProjectReviewerDAO } from '~/api/project/types'
import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule, ProjectSettingModule, StatisticsModule } from '~/api/shared/core/modules'

export class ProjectLabelerAPI extends DAOBase implements ProjectLabelerDAO {
  private commonModule: CommonModule
  private projectSettingModule: ProjectSettingModule
  private statisticsModule: StatisticsModule
  private prefix = '/projects/labeler'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
      projectSettingModule: ProjectSettingModule
      statisticsModule: StatisticsModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
    this.projectSettingModule = modules.projectSettingModule
    this.statisticsModule = modules.statisticsModule
  }

  public readonly list: ProjectLabelerDAO['list'] = (args) =>
    this.commonModule.list<unknown>({ prefix: this.prefix, ...args })

  public readonly retrieve: ProjectLabelerDAO['retrieve'] = (args) =>
    this.commonModule.retrieve<unknown>({ prefix: this.prefix, ...args })

  public readonly queuePosition: ProjectLabelerDAO['queuePosition'] = (args) =>
    this.projectSettingModule.queuePosition({ prefix: this.prefix, ...args })

  public readonly outFromQueue: ProjectLabelerDAO['outFromQueue'] = (args) =>
    this.projectSettingModule.outFromQueue({ prefix: this.prefix, ...args })

  public readonly join: ProjectLabelerDAO['join'] = (args) =>
    this.projectSettingModule.join({ prefix: this.prefix, ...args })

  public readonly participate: ProjectReviewerDAO['participate'] = (args) => {
    const response = this.fetchBase.request<unknown>(`${this.prefix}/${args.path}/participate/`, {
      method: 'POST',
      ...args
    })
    return response
  }

  public readonly statistics: ProjectLabelerDAO['statistics'] = (args) =>
    this.statisticsModule.statistics<ProjectLabelerStatisticsResData>({
      prefix: this.prefix,
      path: args.path,
      options: { ...args, method: 'GET' }
    })
}
