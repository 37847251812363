import type { AgentProxyDAO } from '~/api/machine-learning/types'
import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'

export class AgentProxyAPI extends DAOBase implements AgentProxyDAO {
  private prefix = '/agents'

  public readonly retrieve: AgentProxyDAO['retrieve'] = ({ id, path, options }) => {
    const response = this.fetchBase.request<unknown>(`${this.prefix}/${id}/proxy/${path}/`, options)
    return response
  }

  public readonly update: AgentProxyDAO['update'] = ({ id, path, body, options }) => {
    const response = this.fetchBase.request<unknown>(`${this.prefix}/${id}/proxy/${path}/`, {
      method: 'PUT',
      body,
      ...options
    })
    return response
  }

  public readonly create: AgentProxyDAO['create'] = ({ id, path, body, options }) => {
    const response = this.fetchBase.request<unknown>(`${this.prefix}/${id}/proxy/${path}/`, {
      method: 'POST',
      body,
      ...options
    })
    return response
  }

  public readonly partialUpdate: AgentProxyDAO['partialUpdate'] = ({ id, path, body, options }) => {
    const response = this.fetchBase.request<unknown>(`${this.prefix}/${id}/proxy/${path}/`, {
      method: 'PATCH',
      body,
      ...options
    })
    return response
  }

  public readonly destroy: AgentProxyDAO['destroy'] = ({ id, path, body, options }) => {
    const response = this.fetchBase.request<unknown>(`${this.prefix}/${id}/proxy/${path}/`, {
      method: 'DELETE',
      body,
      ...options
    })
    return response
  }
}
