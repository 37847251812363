import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import type { AssignmentModuleDAO } from '~/api/shared/core/modules/types'
import type { ExtractModuleParams } from '~/api/shared/core/types'

export class AssignmentModule extends DAOBase implements AssignmentModuleDAO {
  public readonly start = <TReturn>({
    prefix,
    path,
    body,
    options
  }: ExtractModuleParams<AssignmentModuleDAO['start']>): Promise<TReturn> => {
    const response = this.fetchBase.request<TReturn>(`${prefix}/${path}/start`, {
      ...options,
      method: 'POST',
      body
    })
    return response
  }

  public readonly actions = <TReturn>({
    prefix,
    options
  }: ExtractModuleParams<AssignmentModuleDAO['actions']>): Promise<TReturn> => {
    const response = this.fetchBase.request<TReturn>(`${prefix}/actions`, options)
    return response
  }

  public readonly runService = <TReturn>({
    prefix,
    path,
    body,
    options
  }: ExtractModuleParams<AssignmentModuleDAO['runService']>): Promise<TReturn> => {
    const response = this.fetchBase.request<TReturn>(`${prefix}/${path}/run_service`, {
      ...options,
      method: 'POST',
      body
    })
    return response
  }

  public readonly countByStatus = <TReturn>({
    prefix,
    options,
    params
  }: ExtractModuleParams<AssignmentModuleDAO['countByStatus']>): Promise<TReturn> => {
    return this.fetchBase.request<TReturn>(`${prefix}/count_by_status/`, {
      params,
      ...options
    })
  }
}
