import type { WorkshopLabelerDAO, WorkshopLabelerJoinCreateResData } from '~/api/project/WorkshopLabeler.types'
import type {
  WorkshopReviewerDAO,
  WorkshopReviewerListResData,
  WorkshopReviewerParticipateResData,
  WorkshopReviewerRetrieveResData
} from '~/api/project/WorkshopReviewer.types'
import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule } from '~/api/shared/core/modules'

export class WorkshopReviewerAPI extends DAOBase implements WorkshopReviewerDAO {
  private commonModule: CommonModule
  private prefix = '/workshops/reviewer'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
  }

  public readonly list: WorkshopReviewerDAO['list'] = (args) =>
    this.commonModule.list<WorkshopReviewerListResData>({ ...args, prefix: this.prefix })

  public readonly retrieve: WorkshopReviewerDAO['retrieve'] = (args) =>
    this.commonModule.retrieve<WorkshopReviewerRetrieveResData>({ ...args, prefix: this.prefix })

  public readonly join: WorkshopLabelerDAO['join'] = (args) => {
    return this.fetchBase.request<WorkshopLabelerJoinCreateResData>(`${this.prefix}/${args.path}/join/`, {
      ...args,
      method: 'POST'
    })
  }

  public readonly participate: WorkshopReviewerDAO['participate'] = (args) => {
    return this.fetchBase.request<WorkshopReviewerParticipateResData>(`${this.prefix}/${args.path}/participate/`, {
      ...args,
      method: 'POST'
    })
  }

  public readonly outFromQueue: WorkshopReviewerDAO['outFromQueue'] = (args) => {
    return this.fetchBase.request<WorkshopReviewerParticipateResData>(`${this.prefix}/${args.path}/out_from_queue/`, {
      ...args,
      method: 'POST'
    })
  }

  public readonly queuePosition: WorkshopReviewerDAO['queuePosition'] = (args) => {
    return this.fetchBase.request<unknown>(`${this.prefix}/${args.path}/queue_position/`, {
      ...args,
      method: 'GET'
    })
  }
}
