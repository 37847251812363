import { mask } from '@formkit/pro'
import { cloneAny } from '@formkit/utils'

export const phoneNumber = cloneAny(mask)

const regionMask = {
  KR: '###-####-####',
  GH: '###-###-####',
  US: '###-###-####'
}

function setMask(node) {
  node.addProps({
    region: {
      default: 'KR'
    }
  })

  node.props.mask = regionMask[node.props.region]
  node.props.showMask = false
}

phoneNumber.features.push(setMask)
