
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as deactivatekBZpNFkF8bMeta } from "/usr/src/app/pages/account/deactivate.vue?macro=true";
import { default as index6LTnG94DlyMeta } from "/usr/src/app/pages/account/index.vue?macro=true";
import { default as manageMVvOspC7YkMeta } from "/usr/src/app/pages/account/manage.vue?macro=true";
import { default as profileaKN25GPWXpMeta } from "/usr/src/app/pages/account/profile.vue?macro=true";
import { default as verifications65I9Aqy9RMeta } from "/usr/src/app/pages/account/verification.vue?macro=true";
import { default as find_45accountTndef1C3WzMeta } from "/usr/src/app/pages/auth/find-account.vue?macro=true";
import { default as logink8UcAHPYydMeta } from "/usr/src/app/pages/auth/login.vue?macro=true";
import { default as _91token_93rvxBGUK6A1Meta } from "/usr/src/app/pages/auth/password-reset/[uid]/[token].vue?macro=true";
import { default as confirm7TWXBQQInnMeta } from "/usr/src/app/pages/auth/password-reset/confirm.vue?macro=true";
import { default as index89jtpGgc4CMeta } from "/usr/src/app/pages/auth/password-reset/index.vue?macro=true";
import { default as sign_45upcjsliNTKjoMeta } from "/usr/src/app/pages/auth/sign-up.vue?macro=true";
import { default as data_45unitskegxWUl3QAMeta } from "/usr/src/app/pages/catalog/collections/[collection_id]/data-units.vue?macro=true";
import { default as indexsCVbt6DrZSMeta } from "/usr/src/app/pages/catalog/collections/[collection_id]/imports/index.vue?macro=true";
import { default as indexmjHvlOLHU8Meta } from "/usr/src/app/pages/catalog/collections/[collection_id]/index.vue?macro=true";
import { default as dangerada2yc7FNhMeta } from "/usr/src/app/pages/catalog/collections/[collection_id]/settings/danger.vue?macro=true";
import { default as defaultZsPI0UIq3rMeta } from "/usr/src/app/pages/catalog/collections/[collection_id]/settings/default.vue?macro=true";
import { default as indexno99JekhAaMeta } from "/usr/src/app/pages/catalog/collections/[collection_id]/settings/index.vue?macro=true";
import { default as guestsc39DHGCyppMeta } from "/usr/src/app/pages/catalog/collections/[collection_id]/settings/members/guests.vue?macro=true";
import { default as indexJFqjufFS14Meta } from "/usr/src/app/pages/catalog/collections/[collection_id]/settings/members/index.vue?macro=true";
import { default as schema4NhVWJIu7BMeta } from "/usr/src/app/pages/catalog/collections/[collection_id]/settings/schema.vue?macro=true";
import { default as create_45collectionFv84kdIPrwMeta } from "/usr/src/app/pages/catalog/collections/create-collection.vue?macro=true";
import { default as indexjkBCLc35hMMeta } from "/usr/src/app/pages/catalog/collections/index.vue?macro=true";
import { default as indexflAS2pF2qRMeta } from "/usr/src/app/pages/data-lab/[role]/[project_id]/index.vue?macro=true";
import { default as indexK184OhRqapMeta } from "/usr/src/app/pages/data-lab/[role]/[project_id]/workshop/[workshop_id]/assignments/discarded-tasks/index.vue?macro=true";
import { default as indexn3boEE0ob7Meta } from "/usr/src/app/pages/data-lab/[role]/[project_id]/workshop/[workshop_id]/assignments/index.vue?macro=true";
import { default as indexaNMfu85A8GMeta } from "/usr/src/app/pages/data-lab/[role]/[project_id]/workshop/[workshop_id]/assignments/tasks/index.vue?macro=true";
import { default as _91guide_45line_id_93pAEw5VYRpOMeta } from "/usr/src/app/pages/data-lab/[role]/[project_id]/workshop/[workshop_id]/guide-line/[guide-line_id].vue?macro=true";
import { default as indexCUrsrTbjyiMeta } from "/usr/src/app/pages/data-lab/[role]/[project_id]/workshop/[workshop_id]/guide-line/index.vue?macro=true";
import { default as indexb26U34tPWQMeta } from "/usr/src/app/pages/data-lab/[role]/[project_id]/workshop/[workshop_id]/index.vue?macro=true";
import { default as indexSNWzce6i0uMeta } from "/usr/src/app/pages/data-lab/[role]/[project_id]/workshop/[workshop_id]/statistics/index.vue?macro=true";
import { default as _91assignments_93_46clientVy7ZdTEigWMeta } from "/usr/src/app/pages/data-lab/[role]/[project_id]/workshop/[workshop_id]/statistics/task/[assignments].client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/usr/src/app/node_modules/nuxt/dist/components/runtime/client-component.js").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as index39nthBfn8DMeta } from "/usr/src/app/pages/data-lab/[role]/[project_id]/workshop/[workshop_id]/statistics/task/index.vue?macro=true";
import { default as indexpWG41qOdlEMeta } from "/usr/src/app/pages/data-lab/[role]/[project_id]/workshop/index.vue?macro=true";
import { default as index2zE78EszwVMeta } from "/usr/src/app/pages/data-lab/[role]/index.vue?macro=true";
import { default as indexzOemP96mQlMeta } from "/usr/src/app/pages/data-lab/index.vue?macro=true";
import { default as indexgw2ikJsyhcMeta } from "/usr/src/app/pages/index.vue?macro=true";
import { default as agentsrXI4qGNpY8Meta } from "/usr/src/app/pages/infra/agents.vue?macro=true";
import { default as indexs0nbn2O6OpMeta } from "/usr/src/app/pages/infra/index.vue?macro=true";
import { default as indexVjJPNm5BoyMeta } from "/usr/src/app/pages/ml/experiments/[experiment_id]/index.vue?macro=true";
import { default as _91job_id_93jiuzDgxeIuMeta } from "/usr/src/app/pages/ml/experiments/[experiment_id]/learnings/[job_id].vue?macro=true";
import { default as creategY63ad1vFKMeta } from "/usr/src/app/pages/ml/experiments/[experiment_id]/learnings/create.vue?macro=true";
import { default as indexAMXoXr5T05Meta } from "/usr/src/app/pages/ml/experiments/[experiment_id]/learnings/index.vue?macro=true";
import { default as dangerCecTmKth27Meta } from "/usr/src/app/pages/ml/experiments/[experiment_id]/settings/danger.vue?macro=true";
import { default as indexci1IhmZ1USMeta } from "/usr/src/app/pages/ml/experiments/[experiment_id]/settings/index.vue?macro=true";
import { default as memberybUTiVMxSdMeta } from "/usr/src/app/pages/ml/experiments/[experiment_id]/settings/member.vue?macro=true";
import { default as createpXfVqXqBY2Meta } from "/usr/src/app/pages/ml/experiments/create.vue?macro=true";
import { default as indexS3LoLMf7RJMeta } from "/usr/src/app/pages/ml/experiments/index.vue?macro=true";
import { default as indexJsOg0GSQ50Meta } from "/usr/src/app/pages/ml/index.vue?macro=true";
import { default as indexLCsqPN0ZjwMeta } from "/usr/src/app/pages/ml/models/[model_id]/index.vue?macro=true";
import { default as modifys0ToWRFZm2Meta } from "/usr/src/app/pages/ml/models/[model_id]/modify.vue?macro=true";
import { default as indexBqQuRre1RjMeta } from "/usr/src/app/pages/ml/models/index.vue?macro=true";
import { default as indexGqHvw3vaZEMeta } from "/usr/src/app/pages/plugins/index.vue?macro=true";
import { default as _91plugin_code_93qannoQ9XfNMeta } from "/usr/src/app/pages/plugins/neural_net/[plugin_code].vue?macro=true";
import { default as indexpJPOb6pGvIMeta } from "/usr/src/app/pages/plugins/neural_net/index.vue?macro=true";
import { default as assignmentsWG28ZFcma4Meta } from "/usr/src/app/pages/projects/[project_id]/collection/assignments.vue?macro=true";
import { default as GT19HcSsB8ckMeta } from "/usr/src/app/pages/projects/[project_id]/collection/GT.vue?macro=true";
import { default as indexdTPTE4hWxsMeta } from "/usr/src/app/pages/projects/[project_id]/collection/index.vue?macro=true";
import { default as tasks8ZfeuIIZdTMeta } from "/usr/src/app/pages/projects/[project_id]/collection/tasks.vue?macro=true";
import { default as indexRUXgCpAeb4Meta } from "/usr/src/app/pages/projects/[project_id]/index.vue?macro=true";
import { default as annotationpPOZ4Js5oQMeta } from "/usr/src/app/pages/projects/[project_id]/settings/annotation.vue?macro=true";
import { default as collectionG0o0PybsmnMeta } from "/usr/src/app/pages/projects/[project_id]/settings/collection.vue?macro=true";
import { default as dangerazR9TTPDHSMeta } from "/usr/src/app/pages/projects/[project_id]/settings/danger.vue?macro=true";
import { default as defaultcRLaDvjoaXMeta } from "/usr/src/app/pages/projects/[project_id]/settings/default.vue?macro=true";
import { default as indexL0Hn2MT4L4Meta } from "/usr/src/app/pages/projects/[project_id]/settings/index.vue?macro=true";
import { default as indexotxPspFsrgMeta } from "/usr/src/app/pages/projects/[project_id]/settings/members/index.vue?macro=true";
import { default as tagssPPssQTTL6Meta } from "/usr/src/app/pages/projects/[project_id]/settings/tags.vue?macro=true";
import { default as workflowLM7TDXlvDtMeta } from "/usr/src/app/pages/projects/[project_id]/settings/workflow.vue?macro=true";
import { default as classification_46clienthR1GaILSIoMeta } from "/usr/src/app/pages/projects/[project_id]/statistics/classification.client.vue?macro=true";
import { default as discard_46clientJfumIM7eDeMeta } from "/usr/src/app/pages/projects/[project_id]/statistics/discard.client.vue?macro=true";
import { default as indexY5R6VnPuJ3Meta } from "/usr/src/app/pages/projects/[project_id]/statistics/index.vue?macro=true";
import { default as index47qpYzYkJQMeta } from "/usr/src/app/pages/projects/[project_id]/statistics/reject/index.vue?macro=true";
import { default as QC_46clientwDERL0qpF9Meta } from "/usr/src/app/pages/projects/[project_id]/statistics/reject/QC.client.vue?macro=true";
import { default as worker_46clienttbGdCFIY2vMeta } from "/usr/src/app/pages/projects/[project_id]/statistics/reject/worker.client.vue?macro=true";
import { default as _91assignments_93_46clientIBrvuhixtFMeta } from "/usr/src/app/pages/projects/[project_id]/statistics/worker/[role]/[assignments].client.vue?macro=true";
import { default as indexKEPLz0ckiLMeta } from "/usr/src/app/pages/projects/[project_id]/statistics/worker/[role]/index.vue?macro=true";
import { default as indexh0THaOVKCmMeta } from "/usr/src/app/pages/projects/[project_id]/statistics/worker/index.vue?macro=true";
import { default as indexWSrOrFWECmMeta } from "/usr/src/app/pages/projects/[project_id]/workforce/guides/[guide_id]/index.vue?macro=true";
import { default as modifyFoeeUADnXyMeta } from "/usr/src/app/pages/projects/[project_id]/workforce/guides/[guide_id]/modify.vue?macro=true";
import { default as create6WK9KgvTEqMeta } from "/usr/src/app/pages/projects/[project_id]/workforce/guides/create.vue?macro=true";
import { default as indextPScj556YUMeta } from "/usr/src/app/pages/projects/[project_id]/workforce/guides/index.vue?macro=true";
import { default as indexoXLGSD0x52Meta } from "/usr/src/app/pages/projects/[project_id]/workforce/index.vue?macro=true";
import { default as QCnJTJx7XCoYMeta } from "/usr/src/app/pages/projects/[project_id]/workforce/QC.vue?macro=true";
import { default as _91worker_id_93NfO5b6C4jcMeta } from "/usr/src/app/pages/projects/[project_id]/workforce/workers/[worker_id].vue?macro=true";
import { default as indexeJcc04DbyeMeta } from "/usr/src/app/pages/projects/[project_id]/workforce/workers/index.vue?macro=true";
import { default as _91category_93p0PSVcp6MiMeta } from "/usr/src/app/pages/projects/[project_id]/workforce/workshops/[workshop_id]/assignments/[category].vue?macro=true";
import { default as index3vzWdFgvKVMeta } from "/usr/src/app/pages/projects/[project_id]/workforce/workshops/[workshop_id]/assignments/index.vue?macro=true";
import { default as indexCxbMox97eYMeta } from "/usr/src/app/pages/projects/[project_id]/workforce/workshops/[workshop_id]/index.vue?macro=true";
import { default as _91role_931MHvx8qGrXMeta } from "/usr/src/app/pages/projects/[project_id]/workforce/workshops/[workshop_id]/worker/[role].vue?macro=true";
import { default as indexNYZRCVx3idMeta } from "/usr/src/app/pages/projects/[project_id]/workforce/workshops/[workshop_id]/worker/index.vue?macro=true";
import { default as createvR3JmOdnPpMeta } from "/usr/src/app/pages/projects/[project_id]/workforce/workshops/create.vue?macro=true";
import { default as indexWgVI5NGabBMeta } from "/usr/src/app/pages/projects/[project_id]/workforce/workshops/index.vue?macro=true";
import { default as createTlP28oYuwYMeta } from "/usr/src/app/pages/projects/create.vue?macro=true";
import { default as indexsjG9qRz3rlMeta } from "/usr/src/app/pages/projects/index.vue?macro=true";
import { default as indexPhr4sKDF2rMeta } from "/usr/src/app/pages/settings/index.vue?macro=true";
import { default as membersu90AxaVVibMeta } from "/usr/src/app/pages/settings/members.vue?macro=true";
import { default as personallq74yAB75zMeta } from "/usr/src/app/pages/settings/personal.vue?macro=true";
export default [
  {
    name: "account-deactivate",
    path: "/account/deactivate",
    meta: deactivatekBZpNFkF8bMeta || {},
    component: () => import("/usr/src/app/pages/account/deactivate.vue")
  },
  {
    name: "account",
    path: "/account",
    component: () => import("/usr/src/app/pages/account/index.vue")
  },
  {
    name: "account-manage",
    path: "/account/manage",
    component: () => import("/usr/src/app/pages/account/manage.vue")
  },
  {
    name: "account-profile",
    path: "/account/profile",
    meta: profileaKN25GPWXpMeta || {},
    component: () => import("/usr/src/app/pages/account/profile.vue")
  },
  {
    name: "account-verification",
    path: "/account/verification",
    component: () => import("/usr/src/app/pages/account/verification.vue")
  },
  {
    name: "auth-find-account",
    path: "/auth/find-account",
    meta: find_45accountTndef1C3WzMeta || {},
    component: () => import("/usr/src/app/pages/auth/find-account.vue")
  },
  {
    name: "auth-login",
    path: "/auth/login",
    meta: logink8UcAHPYydMeta || {},
    component: () => import("/usr/src/app/pages/auth/login.vue")
  },
  {
    name: "auth-password-reset-uid-token",
    path: "/auth/password-reset/:uid()/:token()",
    meta: _91token_93rvxBGUK6A1Meta || {},
    component: () => import("/usr/src/app/pages/auth/password-reset/[uid]/[token].vue")
  },
  {
    name: "auth-password-reset-confirm",
    path: "/auth/password-reset/confirm",
    meta: confirm7TWXBQQInnMeta || {},
    component: () => import("/usr/src/app/pages/auth/password-reset/confirm.vue")
  },
  {
    name: "auth-password-reset",
    path: "/auth/password-reset",
    meta: index89jtpGgc4CMeta || {},
    component: () => import("/usr/src/app/pages/auth/password-reset/index.vue")
  },
  {
    name: "auth-sign-up",
    path: "/auth/sign-up",
    meta: sign_45upcjsliNTKjoMeta || {},
    component: () => import("/usr/src/app/pages/auth/sign-up.vue")
  },
  {
    name: "catalog-collections-collection_id-data-units",
    path: "/catalog/collections/:collection_id()/data-units",
    meta: data_45unitskegxWUl3QAMeta || {},
    component: () => import("/usr/src/app/pages/catalog/collections/[collection_id]/data-units.vue")
  },
  {
    name: "catalog-collections-collection_id-imports",
    path: "/catalog/collections/:collection_id()/imports",
    component: () => import("/usr/src/app/pages/catalog/collections/[collection_id]/imports/index.vue")
  },
  {
    name: "catalog-collections-collection_id",
    path: "/catalog/collections/:collection_id()",
    component: () => import("/usr/src/app/pages/catalog/collections/[collection_id]/index.vue")
  },
  {
    name: "catalog-collections-collection_id-settings-danger",
    path: "/catalog/collections/:collection_id()/settings/danger",
    component: () => import("/usr/src/app/pages/catalog/collections/[collection_id]/settings/danger.vue")
  },
  {
    name: "catalog-collections-collection_id-settings-default",
    path: "/catalog/collections/:collection_id()/settings/default",
    meta: defaultZsPI0UIq3rMeta || {},
    component: () => import("/usr/src/app/pages/catalog/collections/[collection_id]/settings/default.vue")
  },
  {
    name: "catalog-collections-collection_id-settings",
    path: "/catalog/collections/:collection_id()/settings",
    component: () => import("/usr/src/app/pages/catalog/collections/[collection_id]/settings/index.vue")
  },
  {
    name: "catalog-collections-collection_id-settings-members-guests",
    path: "/catalog/collections/:collection_id()/settings/members/guests",
    component: () => import("/usr/src/app/pages/catalog/collections/[collection_id]/settings/members/guests.vue")
  },
  {
    name: "catalog-collections-collection_id-settings-members",
    path: "/catalog/collections/:collection_id()/settings/members",
    component: () => import("/usr/src/app/pages/catalog/collections/[collection_id]/settings/members/index.vue")
  },
  {
    name: "catalog-collections-collection_id-settings-schema",
    path: "/catalog/collections/:collection_id()/settings/schema",
    meta: schema4NhVWJIu7BMeta || {},
    component: () => import("/usr/src/app/pages/catalog/collections/[collection_id]/settings/schema.vue")
  },
  {
    name: "catalog-collections-create-collection",
    path: "/catalog/collections/create-collection",
    meta: create_45collectionFv84kdIPrwMeta || {},
    component: () => import("/usr/src/app/pages/catalog/collections/create-collection.vue")
  },
  {
    name: "catalog-collections",
    path: "/catalog/collections",
    meta: indexjkBCLc35hMMeta || {},
    component: () => import("/usr/src/app/pages/catalog/collections/index.vue")
  },
  {
    name: "data-lab-role-project_id",
    path: "/data-lab/:role()/:project_id()",
    component: () => import("/usr/src/app/pages/data-lab/[role]/[project_id]/index.vue")
  },
  {
    name: "data-lab-role-project_id-workshop-workshop_id-assignments-discarded-tasks",
    path: "/data-lab/:role()/:project_id()/workshop/:workshop_id()/assignments/discarded-tasks",
    meta: indexK184OhRqapMeta || {},
    component: () => import("/usr/src/app/pages/data-lab/[role]/[project_id]/workshop/[workshop_id]/assignments/discarded-tasks/index.vue")
  },
  {
    name: "data-lab-role-project_id-workshop-workshop_id-assignments",
    path: "/data-lab/:role()/:project_id()/workshop/:workshop_id()/assignments",
    component: () => import("/usr/src/app/pages/data-lab/[role]/[project_id]/workshop/[workshop_id]/assignments/index.vue")
  },
  {
    name: "data-lab-role-project_id-workshop-workshop_id-assignments-tasks",
    path: "/data-lab/:role()/:project_id()/workshop/:workshop_id()/assignments/tasks",
    meta: indexaNMfu85A8GMeta || {},
    component: () => import("/usr/src/app/pages/data-lab/[role]/[project_id]/workshop/[workshop_id]/assignments/tasks/index.vue")
  },
  {
    name: "data-lab-role-project_id-workshop-workshop_id-guide-line-guideline_id",
    path: "/data-lab/:role()/:project_id()/workshop/:workshop_id()/guide-line/:guideline_id()",
    meta: _91guide_45line_id_93pAEw5VYRpOMeta || {},
    component: () => import("/usr/src/app/pages/data-lab/[role]/[project_id]/workshop/[workshop_id]/guide-line/[guide-line_id].vue")
  },
  {
    name: "data-lab-role-project_id-workshop-workshop_id-guide-line",
    path: "/data-lab/:role()/:project_id()/workshop/:workshop_id()/guide-line",
    meta: indexCUrsrTbjyiMeta || {},
    component: () => import("/usr/src/app/pages/data-lab/[role]/[project_id]/workshop/[workshop_id]/guide-line/index.vue")
  },
  {
    name: "data-lab-role-project_id-workshop-workshop_id",
    path: "/data-lab/:role()/:project_id()/workshop/:workshop_id()",
    component: () => import("/usr/src/app/pages/data-lab/[role]/[project_id]/workshop/[workshop_id]/index.vue")
  },
  {
    name: "data-lab-role-project_id-workshop-workshop_id-statistics",
    path: "/data-lab/:role()/:project_id()/workshop/:workshop_id()/statistics",
    component: () => import("/usr/src/app/pages/data-lab/[role]/[project_id]/workshop/[workshop_id]/statistics/index.vue")
  },
  {
    name: "data-lab-role-project_id-workshop-workshop_id-statistics-task-assignments",
    path: "/data-lab/:role()/:project_id()/workshop/:workshop_id()/statistics/task/:assignments()",
    meta: _91assignments_93_46clientVy7ZdTEigWMeta || {},
    component: () => createClientPage(() => import("/usr/src/app/pages/data-lab/[role]/[project_id]/workshop/[workshop_id]/statistics/task/[assignments].client.vue"))
  },
  {
    name: "data-lab-role-project_id-workshop-workshop_id-statistics-task",
    path: "/data-lab/:role()/:project_id()/workshop/:workshop_id()/statistics/task",
    component: () => import("/usr/src/app/pages/data-lab/[role]/[project_id]/workshop/[workshop_id]/statistics/task/index.vue")
  },
  {
    name: "data-lab-role-project_id-workshop",
    path: "/data-lab/:role()/:project_id()/workshop",
    component: () => import("/usr/src/app/pages/data-lab/[role]/[project_id]/workshop/index.vue")
  },
  {
    name: "data-lab-role",
    path: "/data-lab/:role()",
    meta: index2zE78EszwVMeta || {},
    component: () => import("/usr/src/app/pages/data-lab/[role]/index.vue")
  },
  {
    name: "data-lab",
    path: "/data-lab",
    component: () => import("/usr/src/app/pages/data-lab/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/usr/src/app/pages/index.vue")
  },
  {
    name: "infra-agents",
    path: "/infra/agents",
    meta: agentsrXI4qGNpY8Meta || {},
    component: () => import("/usr/src/app/pages/infra/agents.vue")
  },
  {
    name: "infra",
    path: "/infra",
    component: () => import("/usr/src/app/pages/infra/index.vue")
  },
  {
    name: "ml-experiments-experiment_id",
    path: "/ml/experiments/:experiment_id()",
    meta: indexVjJPNm5BoyMeta || {},
    component: () => import("/usr/src/app/pages/ml/experiments/[experiment_id]/index.vue")
  },
  {
    name: "ml-experiments-experiment_id-learnings-job_id",
    path: "/ml/experiments/:experiment_id()/learnings/:job_id()",
    meta: _91job_id_93jiuzDgxeIuMeta || {},
    component: () => import("/usr/src/app/pages/ml/experiments/[experiment_id]/learnings/[job_id].vue")
  },
  {
    name: "ml-experiments-experiment_id-learnings-create",
    path: "/ml/experiments/:experiment_id()/learnings/create",
    meta: creategY63ad1vFKMeta || {},
    component: () => import("/usr/src/app/pages/ml/experiments/[experiment_id]/learnings/create.vue")
  },
  {
    name: "ml-experiments-experiment_id-learnings",
    path: "/ml/experiments/:experiment_id()/learnings",
    meta: indexAMXoXr5T05Meta || {},
    component: () => import("/usr/src/app/pages/ml/experiments/[experiment_id]/learnings/index.vue")
  },
  {
    name: "ml-experiments-experiment_id-settings-danger",
    path: "/ml/experiments/:experiment_id()/settings/danger",
    component: () => import("/usr/src/app/pages/ml/experiments/[experiment_id]/settings/danger.vue")
  },
  {
    name: "ml-experiments-experiment_id-settings",
    path: "/ml/experiments/:experiment_id()/settings",
    component: () => import("/usr/src/app/pages/ml/experiments/[experiment_id]/settings/index.vue")
  },
  {
    name: "ml-experiments-experiment_id-settings-member",
    path: "/ml/experiments/:experiment_id()/settings/member",
    component: () => import("/usr/src/app/pages/ml/experiments/[experiment_id]/settings/member.vue")
  },
  {
    name: "ml-experiments-create",
    path: "/ml/experiments/create",
    meta: createpXfVqXqBY2Meta || {},
    component: () => import("/usr/src/app/pages/ml/experiments/create.vue")
  },
  {
    name: "ml-experiments",
    path: "/ml/experiments",
    meta: indexS3LoLMf7RJMeta || {},
    component: () => import("/usr/src/app/pages/ml/experiments/index.vue")
  },
  {
    name: "ml",
    path: "/ml",
    component: () => import("/usr/src/app/pages/ml/index.vue")
  },
  {
    name: "ml-models-model_id",
    path: "/ml/models/:model_id()",
    meta: indexLCsqPN0ZjwMeta || {},
    component: () => import("/usr/src/app/pages/ml/models/[model_id]/index.vue")
  },
  {
    name: "ml-models-model_id-modify",
    path: "/ml/models/:model_id()/modify",
    meta: modifys0ToWRFZm2Meta || {},
    component: () => import("/usr/src/app/pages/ml/models/[model_id]/modify.vue")
  },
  {
    name: "ml-models",
    path: "/ml/models",
    meta: indexBqQuRre1RjMeta || {},
    component: () => import("/usr/src/app/pages/ml/models/index.vue")
  },
  {
    name: "plugins",
    path: "/plugins",
    component: () => import("/usr/src/app/pages/plugins/index.vue")
  },
  {
    name: "plugins-neural_net-plugin_code",
    path: "/plugins/neural_net/:plugin_code()",
    meta: _91plugin_code_93qannoQ9XfNMeta || {},
    component: () => import("/usr/src/app/pages/plugins/neural_net/[plugin_code].vue")
  },
  {
    name: "plugins-neural_net",
    path: "/plugins/neural_net",
    meta: indexpJPOb6pGvIMeta || {},
    component: () => import("/usr/src/app/pages/plugins/neural_net/index.vue")
  },
  {
    name: "projects-project_id-collection-assignments",
    path: "/projects/:project_id()/collection/assignments",
    meta: assignmentsWG28ZFcma4Meta || {},
    component: () => import("/usr/src/app/pages/projects/[project_id]/collection/assignments.vue")
  },
  {
    name: "projects-project_id-collection-GT",
    path: "/projects/:project_id()/collection/GT",
    meta: GT19HcSsB8ckMeta || {},
    component: () => import("/usr/src/app/pages/projects/[project_id]/collection/GT.vue")
  },
  {
    name: "projects-project_id-collection",
    path: "/projects/:project_id()/collection",
    component: () => import("/usr/src/app/pages/projects/[project_id]/collection/index.vue")
  },
  {
    name: "projects-project_id-collection-tasks",
    path: "/projects/:project_id()/collection/tasks",
    meta: tasks8ZfeuIIZdTMeta || {},
    component: () => import("/usr/src/app/pages/projects/[project_id]/collection/tasks.vue")
  },
  {
    name: "projects-project_id",
    path: "/projects/:project_id()",
    component: () => import("/usr/src/app/pages/projects/[project_id]/index.vue")
  },
  {
    name: "projects-project_id-settings-annotation",
    path: "/projects/:project_id()/settings/annotation",
    meta: annotationpPOZ4Js5oQMeta || {},
    component: () => import("/usr/src/app/pages/projects/[project_id]/settings/annotation.vue")
  },
  {
    name: "projects-project_id-settings-collection",
    path: "/projects/:project_id()/settings/collection",
    component: () => import("/usr/src/app/pages/projects/[project_id]/settings/collection.vue")
  },
  {
    name: "projects-project_id-settings-danger",
    path: "/projects/:project_id()/settings/danger",
    component: () => import("/usr/src/app/pages/projects/[project_id]/settings/danger.vue")
  },
  {
    name: "projects-project_id-settings-default",
    path: "/projects/:project_id()/settings/default",
    meta: defaultcRLaDvjoaXMeta || {},
    component: () => import("/usr/src/app/pages/projects/[project_id]/settings/default.vue")
  },
  {
    name: "projects-project_id-settings",
    path: "/projects/:project_id()/settings",
    component: () => import("/usr/src/app/pages/projects/[project_id]/settings/index.vue")
  },
  {
    name: "projects-project_id-settings-members",
    path: "/projects/:project_id()/settings/members",
    component: () => import("/usr/src/app/pages/projects/[project_id]/settings/members/index.vue")
  },
  {
    name: "projects-project_id-settings-tags",
    path: "/projects/:project_id()/settings/tags",
    component: () => import("/usr/src/app/pages/projects/[project_id]/settings/tags.vue")
  },
  {
    name: "projects-project_id-settings-workflow",
    path: "/projects/:project_id()/settings/workflow",
    meta: workflowLM7TDXlvDtMeta || {},
    component: () => import("/usr/src/app/pages/projects/[project_id]/settings/workflow.vue")
  },
  {
    name: "projects-project_id-statistics-classification",
    path: "/projects/:project_id()/statistics/classification",
    component: () => createClientPage(() => import("/usr/src/app/pages/projects/[project_id]/statistics/classification.client.vue"))
  },
  {
    name: "projects-project_id-statistics-discard",
    path: "/projects/:project_id()/statistics/discard",
    component: () => createClientPage(() => import("/usr/src/app/pages/projects/[project_id]/statistics/discard.client.vue"))
  },
  {
    name: "projects-project_id-statistics",
    path: "/projects/:project_id()/statistics",
    component: () => import("/usr/src/app/pages/projects/[project_id]/statistics/index.vue")
  },
  {
    name: "projects-project_id-statistics-reject",
    path: "/projects/:project_id()/statistics/reject",
    component: () => import("/usr/src/app/pages/projects/[project_id]/statistics/reject/index.vue")
  },
  {
    name: "projects-project_id-statistics-reject-QC",
    path: "/projects/:project_id()/statistics/reject/QC",
    component: () => createClientPage(() => import("/usr/src/app/pages/projects/[project_id]/statistics/reject/QC.client.vue"))
  },
  {
    name: "projects-project_id-statistics-reject-worker",
    path: "/projects/:project_id()/statistics/reject/worker",
    component: () => createClientPage(() => import("/usr/src/app/pages/projects/[project_id]/statistics/reject/worker.client.vue"))
  },
  {
    name: "projects-project_id-statistics-worker-role-assignments",
    path: "/projects/:project_id()/statistics/worker/:role()/:assignments()",
    meta: _91assignments_93_46clientIBrvuhixtFMeta || {},
    component: () => createClientPage(() => import("/usr/src/app/pages/projects/[project_id]/statistics/worker/[role]/[assignments].client.vue"))
  },
  {
    name: "projects-project_id-statistics-worker-role",
    path: "/projects/:project_id()/statistics/worker/:role()",
    component: () => import("/usr/src/app/pages/projects/[project_id]/statistics/worker/[role]/index.vue")
  },
  {
    name: "projects-project_id-statistics-worker",
    path: "/projects/:project_id()/statistics/worker",
    component: () => import("/usr/src/app/pages/projects/[project_id]/statistics/worker/index.vue")
  },
  {
    name: "projects-project_id-workforce-guides-guide_id",
    path: "/projects/:project_id()/workforce/guides/:guide_id()",
    meta: indexWSrOrFWECmMeta || {},
    component: () => import("/usr/src/app/pages/projects/[project_id]/workforce/guides/[guide_id]/index.vue")
  },
  {
    name: "projects-project_id-workforce-guides-guide_id-modify",
    path: "/projects/:project_id()/workforce/guides/:guide_id()/modify",
    meta: modifyFoeeUADnXyMeta || {},
    component: () => import("/usr/src/app/pages/projects/[project_id]/workforce/guides/[guide_id]/modify.vue")
  },
  {
    name: "projects-project_id-workforce-guides-create",
    path: "/projects/:project_id()/workforce/guides/create",
    meta: create6WK9KgvTEqMeta || {},
    component: () => import("/usr/src/app/pages/projects/[project_id]/workforce/guides/create.vue")
  },
  {
    name: "projects-project_id-workforce-guides",
    path: "/projects/:project_id()/workforce/guides",
    meta: indextPScj556YUMeta || {},
    component: () => import("/usr/src/app/pages/projects/[project_id]/workforce/guides/index.vue")
  },
  {
    name: "projects-project_id-workforce",
    path: "/projects/:project_id()/workforce",
    component: () => import("/usr/src/app/pages/projects/[project_id]/workforce/index.vue")
  },
  {
    name: "projects-project_id-workforce-QC",
    path: "/projects/:project_id()/workforce/QC",
    component: () => import("/usr/src/app/pages/projects/[project_id]/workforce/QC.vue")
  },
  {
    name: "projects-project_id-workforce-workers-worker_id",
    path: "/projects/:project_id()/workforce/workers/:worker_id()",
    component: () => import("/usr/src/app/pages/projects/[project_id]/workforce/workers/[worker_id].vue")
  },
  {
    name: "projects-project_id-workforce-workers",
    path: "/projects/:project_id()/workforce/workers",
    component: () => import("/usr/src/app/pages/projects/[project_id]/workforce/workers/index.vue")
  },
  {
    name: "projects-project_id-workforce-workshops-workshop_id-assignments-category",
    path: "/projects/:project_id()/workforce/workshops/:workshop_id()/assignments/:category()",
    meta: _91category_93p0PSVcp6MiMeta || {},
    component: () => import("/usr/src/app/pages/projects/[project_id]/workforce/workshops/[workshop_id]/assignments/[category].vue")
  },
  {
    name: "projects-project_id-workforce-workshops-workshop_id-assignments",
    path: "/projects/:project_id()/workforce/workshops/:workshop_id()/assignments",
    component: () => import("/usr/src/app/pages/projects/[project_id]/workforce/workshops/[workshop_id]/assignments/index.vue")
  },
  {
    name: "projects-project_id-workforce-workshops-workshop_id",
    path: "/projects/:project_id()/workforce/workshops/:workshop_id()",
    component: () => import("/usr/src/app/pages/projects/[project_id]/workforce/workshops/[workshop_id]/index.vue")
  },
  {
    name: "projects-project_id-workforce-workshops-workshop_id-worker-role",
    path: "/projects/:project_id()/workforce/workshops/:workshop_id()/worker/:role()",
    component: () => import("/usr/src/app/pages/projects/[project_id]/workforce/workshops/[workshop_id]/worker/[role].vue")
  },
  {
    name: "projects-project_id-workforce-workshops-workshop_id-worker",
    path: "/projects/:project_id()/workforce/workshops/:workshop_id()/worker",
    component: () => import("/usr/src/app/pages/projects/[project_id]/workforce/workshops/[workshop_id]/worker/index.vue")
  },
  {
    name: "projects-project_id-workforce-workshops-create",
    path: "/projects/:project_id()/workforce/workshops/create",
    meta: createvR3JmOdnPpMeta || {},
    component: () => import("/usr/src/app/pages/projects/[project_id]/workforce/workshops/create.vue")
  },
  {
    name: "projects-project_id-workforce-workshops",
    path: "/projects/:project_id()/workforce/workshops",
    meta: indexWgVI5NGabBMeta || {},
    component: () => import("/usr/src/app/pages/projects/[project_id]/workforce/workshops/index.vue")
  },
  {
    name: "projects-create",
    path: "/projects/create",
    meta: createTlP28oYuwYMeta || {},
    component: () => import("/usr/src/app/pages/projects/create.vue")
  },
  {
    name: "projects",
    path: "/projects",
    meta: indexsjG9qRz3rlMeta || {},
    component: () => import("/usr/src/app/pages/projects/index.vue")
  },
  {
    name: "settings",
    path: "/settings",
    component: () => import("/usr/src/app/pages/settings/index.vue")
  },
  {
    name: "settings-members",
    path: "/settings/members",
    component: () => import("/usr/src/app/pages/settings/members.vue")
  },
  {
    name: "settings-personal",
    path: "/settings/personal",
    meta: personallq74yAB75zMeta || {},
    component: () => import("/usr/src/app/pages/settings/personal.vue")
  }
]