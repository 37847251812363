import { createSection } from '@formkit/inputs'

/**
 * Option section used to show an option
 *
 * @public
 */
export const optionDescription = createSection('optionDescription', () => ({
  $el: 'span',
  if: '$option.description',
  class: '$classes.optionDescription',
  children: ['$option.description']
}))
