import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule } from '~/api/shared/core/modules'
import type {
  WorkerPostLabelerDAO,
  WorkerPostLabelerListResData,
  WorkerPostLabelerRetrieveResData
} from '~/api/worker/WorkerPostLabeler.types'

export class WorkerPostLabelerAPI extends DAOBase implements WorkerPostLabelerDAO {
  private commonModule: CommonModule
  private prefix = '/worker_posts/labeler'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
  }

  public readonly list: WorkerPostLabelerDAO['list'] = (args) =>
    this.commonModule.list<WorkerPostLabelerListResData>({ ...args, prefix: this.prefix })

  public readonly retrieve: WorkerPostLabelerDAO['retrieve'] = (args) =>
    this.commonModule.retrieve<WorkerPostLabelerRetrieveResData>({ ...args, prefix: this.prefix })
}
