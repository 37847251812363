export default {
  outer: tw`mb-6`,
  prefixIcon: tw`group-data-[size=sm]:w-5`,
  suffixIcon: tw`w-4 text-slate-500`,
  inner: tw`
    group/inner items-center gap-x-2 rounded-lg border-0 px-3 py-2 ring-1 ring-slate-300
    focus-within:outline focus-within:outline-2 
    focus-within:outline-offset-2 
    focus-within:outline-blue-600
    focus-within:ring-blue-600 
    hover:ring-blue-600
    group-has-[.formkit-prefix]:pl-2
    group-has-[.formkit-suffix]:pr-2
    group-data-[disabled]:bg-slate-100  
    group-data-[size=sm]:px-2
    group-data-[size=sm]:py-1.5  
    group-data-[errors]:outline-red-500
    group-data-[invalid]:outline-red-500 
    group-data-[errors]:ring-red-500 
    group-data-[invalid]:ring-red-500 
    group-data-[disabled]:hover:ring-slate-300
    group-data-[errors]:hover:ring-red-500
    group-has-[.formkit-prefix]:group-data-[size=sm]:py-1
    group-has-[.formkit-suffix]:group-data-[size=sm]:py-1
    group-has-[.formkit-prefix]:group-data-[size=sm]:pl-1
    group-has-[.formkit-suffix]:group-data-[size=sm]:pr-1`,
  input: tw`
    h-6 w-full grow appearance-none border-none bg-transparent p-0 text-slate-900 outline-none placeholder:text-slate-500 focus:ring-0
    group-data-[size=sm]:h-5
    group-data-[size=sm]:text-sm
    group-data-[disabled]:text-slate-700
    group-data-[disabled]:placeholder:text-slate-500`
}
