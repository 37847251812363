import { createSection } from '@formkit/inputs'
import { Icon } from '#components'

export const removeValue = createSection('removeValue', () => ({
  $el: 'div',
  attrs: {
    class:
      'invisible flex items-center z-10 text-slate-500 group-[&:not([data-disabled])]:group-[&:not([data-empty])]:group-hover/inner:visible'
  },
  children: [
    {
      $cmp: markRaw(Icon),
      props: {
        name: 'heroicons:x-circle'
      }
    }
  ]
}))
