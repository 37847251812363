import type {
  DataUnitCreateResData,
  DataUnitDAO,
  DataUnitDependencyCountResData,
  DataUnitBulkDestroyResData,
  DataUnitListResData,
  DataUnitPartialUpdateResData,
  DataUnitRetrieveResData,
  DataUnitUpdateResData,
  DataUnitCreateTasksResData
} from '~/api/catalog/DataUnit.types'
import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { BulkDestroyModule, CommonModule } from '~/api/shared/core/modules'

export class DataUnitAPI extends DAOBase implements DataUnitDAO {
  private commonModule: CommonModule
  private bulkDestroyModule: BulkDestroyModule

  private prefix = '/data_units'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
      bulkDestroyModule: BulkDestroyModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
    this.bulkDestroyModule = modules.bulkDestroyModule
  }

  public readonly list: DataUnitDAO['list'] = (args) =>
    this.commonModule.list<DataUnitListResData>({ ...args, prefix: this.prefix })

  public readonly retrieve: DataUnitDAO['retrieve'] = (args) =>
    this.commonModule.retrieve<DataUnitRetrieveResData>({ ...args, prefix: this.prefix })

  public readonly create: DataUnitDAO['create'] = (args) =>
    this.commonModule.create<DataUnitCreateResData>({ ...args, prefix: this.prefix })

  public readonly update: DataUnitDAO['update'] = (args) =>
    this.commonModule.update<DataUnitUpdateResData>({ ...args, prefix: this.prefix })

  public readonly partialUpdate: DataUnitDAO['partialUpdate'] = (args) =>
    this.commonModule.partialUpdate<DataUnitPartialUpdateResData>({ ...args, prefix: this.prefix })

  public readonly bulkDestroy: DataUnitDAO['bulkDestroy'] = (args) =>
    this.bulkDestroyModule.bulkDestroy<DataUnitBulkDestroyResData>({ ...args, prefix: this.prefix })

  public readonly dependencyCount: DataUnitDAO['dependencyCount'] = (args) => {
    return this.fetchBase.request<DataUnitDependencyCountResData>(`${this.prefix}/${args.path}/dependency_count/`, {
      ...args
    })
  }

  public readonly createTasks: DataUnitDAO['createTasks'] = (args) => {
    return this.fetchBase.request<DataUnitCreateTasksResData>(`${this.prefix}/create_tasks/`, {
      ...args,
      method: 'POST'
    })
  }
}
