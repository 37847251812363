import type { DialogOptions, DialogStore } from '~/components/ui/dialog/types'

let eventStore: DialogOptions['events'] = {
  onOpen: () => {},
  onClose: () => {}
}

export const useDialogStore = () => {
  return useState<DialogStore>('DIALOG_STORE', () => ({}))
}

export const defineDialogStore = (object: { [key: string]: boolean }) => {
  const store = useDialogStore()
  store.value = { ...object }
  return store
}

export function useDialog(key: string = 'initialization') {
  const dialogStore = useDialogStore()

  const dialog = useState<DialogOptions>(key, () => ({
    get open() {
      return dialogStore.value[key]
    },
    set open(value) {
      dialogStore.value[key] = value
    },
    size: 'md',
    headerButtons: [{ variant: 'link', color: 'slate', icon: 'heroicons:x-mark' }],
    footerRightButtons: [{ label: '확인' }]
  }))

  function open(options?: Omit<DialogOptions, 'open'> | null, managedKey?: string) {
    if (!(options instanceof Event) && options) {
      dialog.value = { ...options, open: true }
      if (options.events && import.meta.client) eventStore = { ...options.events }
    }

    dialog.value.open = true
    dialogStore.value[managedKey ?? key] = true
    eventStore?.onOpen?.()
  }

  function close(managedKey?: string) {
    if (typeof managedKey === 'string') {
      dialogStore.value[managedKey] = false
    } else {
      dialogStore.value[key] = false
    }

    dialog.value.open = false
    eventStore?.onClose?.()
  }

  return {
    open,
    close,
    dialog,
    dialogStore
  }
}
