import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule } from '~/api/shared/core/modules'
import type {
  WorkerPostReviewerDAO,
  WorkerPostReviewerListResData,
  WorkerPostReviewerRetrieveResData
} from '~/api/worker/WorkerPostReviewer.types'

export class WorkerPostReviewerAPI extends DAOBase implements WorkerPostReviewerDAO {
  private commonModule: CommonModule
  private prefix = '/worker_posts/reviewer'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
  }

  public readonly list: WorkerPostReviewerDAO['list'] = (args) =>
    this.commonModule.list<WorkerPostReviewerListResData>({ ...args, prefix: this.prefix })

  public readonly retrieve: WorkerPostReviewerDAO['retrieve'] = (args) =>
    this.commonModule.retrieve<WorkerPostReviewerRetrieveResData>({ ...args, prefix: this.prefix })
}
