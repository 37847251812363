/**
 * @fileoverview 테넌트(Tenant) 관련 API 작업을 처리하는 DAO 구현 클래스
 */

import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule } from '~/api/shared/core/modules'
import type {
  TenantCreateResData,
  TenantDAO,
  TenantDestroyResData,
  TenantListResData,
  TenantPartialUpdateResData,
  TenantRetrieveResData,
  TenantUpdateResData
} from '~/api/worker/Tenant.types'

/**
 * 테넌트 관련 API 작업을 처리하는 클래스
 *
 * 테넌트 데이터에 대한 CRUD 작업을 제공하며, 권한에 따라 공개/비공개 엔드포인트를 사용합니다.
 *
 * @extends DAOBase
 * @implements {TenantDAO}
 */
export class TenantAPI extends DAOBase implements TenantDAO {
  /**
   * CommonModule 인스턴스. API 요청의 표준화된 처리를 위해 사용됩니다.
   * @private
   */
  private commonModule: CommonModule

  /**
   * 비공개 테넌트 API 엔드포인트 경로
   * 인증된 사용자만 접근할 수 있는 엔드포인트입니다.
   * @private
   * @readonly
   */
  private readonly ENDPOINT_PATH = '/tenants'

  /**
   * 공개 테넌트 API 엔드포인트 경로
   * 인증 없이 접근 가능한 엔드포인트로, 주로 조회와 생성 작업에 사용됩니다.
   * @private
   * @readonly
   */
  private readonly PUBLIC_ENDPOINT_PATH = '/public_tenants'

  /**
   * TenantAPI 클래스의 생성자
   *
   * @param {DefaultFetchBase} fetchBase - API 요청을 실행하기 위한 기본 fetch 인스턴스
   * @param {Object} modules - 필요한 모듈을 포함하는 객체
   * @param {CommonModule} modules.commonModule - 표준화된 API 요청 처리를 위한 공통 모듈
   */
  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
  }

  /**
   * 테넌트 목록을 조회합니다.
   * 공개 엔드포인트를 사용하여 인증 없이도 접근 가능합니다.
   *
   * @param {Object} args - 리스트 조회에 필요한 인자
   * @param {Object} [args.params] - 쿼리 파라미터
   * @param {Object} [args.config] - 요청 설정
   * @returns {Promise<TenantListResData>} 테넌트 목록 데이터
   * @throws API 요청 실패 시 오류
   *
   * @example
   * // 기본 테넌트 목록 조회
   * const tenants = await tenantAPI.list({});
   *
   * // 페이지네이션과 필터링을 적용한 테넌트 목록 조회
   * const filteredTenants = await tenantAPI.list({
   *   params: { page: 1, limit: 10, name: "example" }
   * });
   */
  public readonly list: TenantDAO['list'] = (args) =>
    this.commonModule.list<TenantListResData>({ ...args, prefix: this.PUBLIC_ENDPOINT_PATH })

  /**
   * 특정 ID의 테넌트 정보를 조회합니다.
   * 비공개 엔드포인트를 사용하므로 적절한 인증이 필요합니다.
   *
   * @param {Object} args - 테넌트 조회에 필요한 인자
   * @param {string|number} args.id - 조회할 테넌트의 고유 식별자
   * @param {Object} [args.params] - 쿼리 파라미터
   * @param {Object} [args.config] - 요청 설정
   * @returns {Promise<TenantRetrieveResData>} 테넌트 상세 정보
   * @throws API 요청 실패 시 오류 또는 테넌트를 찾을 수 없는 경우
   *
   * @example
   * // ID를 사용하여 테넌트 조회
   * const tenant = await tenantAPI.retrieve({ id: "tenant-123" });
   */
  public readonly retrieve: TenantDAO['retrieve'] = (args) =>
    this.commonModule.retrieve<TenantRetrieveResData>({ ...args, prefix: this.ENDPOINT_PATH })

  /**
   * 새로운 테넌트를 생성합니다.
   * 공개 엔드포인트를 사용하여 회원가입 등의 과정에서 인증 없이 생성 가능합니다.
   *
   * @param {Object} args - 테넌트 생성에 필요한 인자
   * @param {Object} args.data - 생성할 테넌트 정보
   * @param {Object} [args.params] - 쿼리 파라미터
   * @param {Object} [args.config] - 요청 설정
   * @returns {Promise<TenantCreateResData>} 생성된 테넌트 정보
   * @throws API 요청 실패 시 오류 또는 유효성 검사 실패 시
   *
   * @example
   * // 새 테넌트 생성
   * const newTenant = await tenantAPI.create({
   *   data: {
   *     name: "Example Tenant",
   *     email: "contact@example.com",
   *     // 기타 필요한 테넌트 정보
   *   }
   * });
   */
  public readonly create: TenantDAO['create'] = (args) =>
    this.commonModule.create<TenantCreateResData>({ ...args, prefix: this.PUBLIC_ENDPOINT_PATH })

  /**
   * 테넌트 정보를 전체 업데이트합니다.
   * 비공개 엔드포인트를 사용하므로 적절한 인증과 권한이 필요합니다.
   *
   * @param {Object} args - 테넌트 업데이트에 필요한 인자
   * @param {string|number} args.id - 업데이트할 테넌트의 고유 식별자
   * @param {Object} args.data - 업데이트할 테넌트 정보 (모든 필드 필요)
   * @param {Object} [args.params] - 쿼리 파라미터
   * @param {Object} [args.config] - 요청 설정
   * @returns {Promise<TenantUpdateResData>} 업데이트된 테넌트 정보
   * @throws API 요청 실패 시 오류, 테넌트를 찾을 수 없는 경우, 또는 유효성 검사 실패 시
   *
   * @example
   * // 테넌트 전체 정보 업데이트
   * const updatedTenant = await tenantAPI.update({
   *   id: "tenant-123",
   *   data: {
   *     name: "Updated Tenant Name",
   *     email: "updated@example.com",
   *     // 모든 필수 필드 포함
   *   }
   * });
   */
  public readonly update: TenantDAO['update'] = (args) =>
    this.commonModule.update<TenantUpdateResData>({ ...args, prefix: this.ENDPOINT_PATH })

  /**
   * 테넌트 정보를 부분적으로 업데이트합니다.
   * 비공개 엔드포인트를 사용하므로 적절한 인증과 권한이 필요합니다.
   *
   * @param {Object} args - 테넌트 부분 업데이트에 필요한 인자
   * @param {string|number} args.id - 업데이트할 테넌트의 고유 식별자
   * @param {Object} args.data - 업데이트할 테넌트 정보 (변경할 필드만 포함)
   * @param {Object} [args.params] - 쿼리 파라미터
   * @param {Object} [args.config] - 요청 설정
   * @returns {Promise<TenantPartialUpdateResData>} 업데이트된 테넌트 정보
   * @throws API 요청 실패 시 오류, 테넌트를 찾을 수 없는 경우, 또는 유효성 검사 실패 시
   *
   * @example
   * // 테넌트 부분 정보 업데이트 (PATCH)
   * const patchedTenant = await tenantAPI.partialUpdate({
   *   id: "tenant-123",
   *   data: {
   *     name: "New Name"
   *     // 변경할 필드만 포함
   *   }
   * });
   */
  public readonly partialUpdate: TenantDAO['partialUpdate'] = (args) =>
    this.commonModule.partialUpdate<TenantPartialUpdateResData>({ ...args, prefix: this.ENDPOINT_PATH })

  /**
   * 특정 테넌트를 삭제합니다.
   * 비공개 엔드포인트를 사용하므로 적절한 인증과 권한이 필요합니다.
   *
   * @param {Object} args - 테넌트 삭제에 필요한 인자
   * @param {string|number} args.id - 삭제할 테넌트의 고유 식별자
   * @param {Object} [args.params] - 쿼리 파라미터
   * @param {Object} [args.config] - 요청 설정
   * @returns {Promise<TenantDestroyResData>} 삭제 결과 (일반적으로 빈 객체 또는 성공 메시지)
   * @throws API 요청 실패 시 오류 또는 테넌트를 찾을 수 없는 경우
   *
   * @example
   * // 테넌트 삭제
   * await tenantAPI.destroy({ id: "tenant-123" });
   */
  public readonly destroy: TenantDAO['destroy'] = (args) =>
    this.commonModule.destroy<TenantDestroyResData>({ ...args, prefix: this.ENDPOINT_PATH })
}
