const prefix = tw`text-base group-data-[size=sm]:text-xs`
const prefixIcon = tw`h-6 w-6 group-data-[size=sm]:h-4 group-data-[size=sm]:w-4`

export default {
  outer: tw`mb-6 data-[disabled]:pointer-events-none`,
  sliderInner: tw`flex items-center gap-x-2 px-2 py-1`,
  track: tw`grow py-1 group-has-[.formkit-marks]:py-3`,
  trackWrapper: tw`
    rounded-full bg-slate-400 px-0.5
    group-data-[disabled]:bg-slate-300
    group-data-[mode=ratio]:bg-blue-600 
    group-data-[mode=ratio]:group-has-[.formkit-handle-min]:bg-triple-striped
    group-data-[mode=ratio]:group-has-[.formkit-handle-min]:from-blue-800
    group-data-[mode=ratio]:group-has-[.formkit-handle-min]:to-blue-400`,
  trackInner: tw`
    relative mx-0.5 h-5
    group-data-[size=sm]:h-4
    group-data-[size=md]:group-has-[.formkit-marks]:h-1
    group-data-[size=sm]:group-has-[.formkit-marks]:h-1`,
  fill: tw`
    absolute top-0 -mx-1 mr-0 h-full rounded-s-full bg-blue-600 
    group-data-[disabled]:bg-slate-400
    group-data-[mode=ratio]:bg-blue-800
    group-data-[mode=ratio]:group-has-[.formkit-handle-min]:bg-blue-600`,
  marks: tw`pointer-events-none absolute bottom-0 left-0 right-0 top-0`,
  mark: tw`absolute top-1/2 h-0.5 w-0.5 -translate-x-1/2 -translate-y-1/2 rounded-full bg-white-950`,
  handles: tw`m-0 list-none p-0`,
  handle: tw`
    group absolute left-0 top-1/2 h-5 w-5 -translate-x-3 -translate-y-1/2 rounded-full bg-white-950 shadow  
    focus-visible:outline-0 focus-visible:ring-2 
    group-has-[.formkit-marks]:-translate-x-2
    group-data-[size=sm]:h-4
    group-data-[size=sm]:w-4 
    group-data-[disabled]:border-white-950`,
  tooltip: tw`
    pointer-events-none absolute bottom-full left-1/2 -translate-x-1/2 -translate-y-1 whitespace-nowrap rounded-sm bg-slate-900 px-2 py-1 text-xs leading-none text-white-950 opacity-0 transition-opacity
    after:absolute after:left-1/2 after:top-full after:-translate-x-1/2 after:-translate-y-[1px] after:border-4 after:border-transparent after:border-t-slate-900 after:content-[""]
    group-hover:opacity-100 group-focus-visible:opacity-100 
    group-data-[show-tooltip="true"]:opacity-100`,
  prefix,
  suffix: prefix,
  prefixIcon,
  suffixIcon: prefixIcon
}
