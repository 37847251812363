export const ANNOTATOR_CATEGORY_COLOR = {
  TEXT: 'emerald',
  IMAGE: 'amber',
  VIDEO: 'rose',
  PCD: 'fuchsia',
  PROMPT: 'indigo'
} as const

export const ANNOTATOR_CATEGORY_ICON = {
  TEXT: 'annotator:text',
  IMAGE: 'annotator:image',
  VIDEO: 'annotator:video',
  PCD: 'annotator:pcd',
  PROMPT: 'annotator:prompt'
} as const

export const ANNOTATOR_TASK_ICON: {
  [key: string]: string
} = {
  TEXT: 'annotator:alphabet-magnifying-glass',
  IMAGE: 'annotator:photo-magnifying-glass',
  VIDEO: 'annotator:video-magnifying-glass',
  PCD: 'annotator:cube-magnifying-glass',
  PROMPT: 'annotator:prompt',
  AUDIO: 'annotator:wave-magnifying-glass',
  DICOM: 'annotator:bone-magnifying-glass'
}
export const DRAFT_TARGETS = {
  CREATE: 'project-create',
  GT: 'gt-datasets'
} as const

export const SCHEMA_TYPE = {
  DM_SCHEMA: 'dm_schema',
  JSON_SCHEMA: 'json_schema'
} as const
