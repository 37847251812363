import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { BulkDestroyModule, CommonModule } from '~/api/shared/core/modules'
import type {
  WorkerPostBulkDestroyResData,
  WorkerPostCreateResData,
  WorkerPostDAO,
  WorkerPostListResData,
  WorkerPostPartialUpdateResData,
  WorkerPostRetrieveResData,
  WorkerPostUpdateResData
} from '~/api/worker/WorkerPost.types'

export class WorkerPostAPI extends DAOBase implements WorkerPostDAO {
  private commonModule: CommonModule
  private bulkDestroyModule: BulkDestroyModule
  private prefix = '/worker_posts'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
      bulkDestroyModule: BulkDestroyModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
    this.bulkDestroyModule = modules.bulkDestroyModule
  }

  public readonly list: WorkerPostDAO['list'] = (args) =>
    this.commonModule.list<WorkerPostListResData>({ ...args, prefix: this.prefix })

  public readonly retrieve: WorkerPostDAO['retrieve'] = (args) =>
    this.commonModule.retrieve<WorkerPostRetrieveResData>({ ...args, prefix: this.prefix })

  public readonly create: WorkerPostDAO['create'] = (args) =>
    this.commonModule.create<WorkerPostCreateResData>({ ...args, prefix: this.prefix })

  public readonly update: WorkerPostDAO['update'] = (args) =>
    this.commonModule.update<WorkerPostUpdateResData>({ ...args, prefix: this.prefix })

  public readonly partialUpdate: WorkerPostDAO['partialUpdate'] = (args) =>
    this.commonModule.partialUpdate<WorkerPostPartialUpdateResData>({ ...args, prefix: this.prefix })

  public readonly bulkDestroy: WorkerPostDAO['bulkDestroy'] = (args) =>
    this.bulkDestroyModule.bulkDestroy<WorkerPostBulkDestroyResData>({ ...args, prefix: this.prefix })
}
