// https://tanstack.com/query/latest/docs/framework/vue/guides/ssr#nuxt-3
import { type DefaultError, type QueryClient, type QueryKey, useQuery, type UseQueryOptions } from '@tanstack/vue-query'

export const usePrefetchQuery = async <
  TQueryFnData = unknown,
  TError = DefaultError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey
>(
  options: UseQueryOptions<TQueryFnData, TError, TData, TQueryFnData, TQueryKey>,
  queryClient?: QueryClient
) => {
  const queryResult = useQuery<TQueryFnData, TError, TData, TQueryKey>(options, queryClient)

  onServerPrefetch(async () => await queryResult.suspense())

  return await queryResult.suspense()
}
