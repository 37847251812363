import { upperFirst } from 'lodash-es'
import { useAPI } from '~/composables/shared/useAPI'
import { usePageMetaQuery } from '~/composables/shared/usePageMetaQuery'
import { ANNOTATOR_CATEGORY_COLOR, ANNOTATOR_CATEGORY_ICON } from '~/constants/project'

// TODO : 미완성 입니다. 필요한 api 를 더 추가해서 써야합니다
export const useProjectMetaQuery = async () => {
  const { t } = useI18n({ useScope: 'global' })
  const api = useAPI()

  return await usePageMetaQuery(async (route, createPageMeta) => {
    const results = await Promise.all([
      route.params.project_id &&
        api.project.project.retrieve({
          path: Number(route.params.project_id),
          params: {
            expand: 'dataset'
          }
        }),
      route.params.workshop_id &&
        api.project.workshop.retrieve({
          path: Number(route.params.workshop_id)
        }),
      route.params.guide_id &&
        api.worker.workerPost.retrieve({
          path: Number(route.params.guide_id)
        })
    ])

    const [project, workshop, workerPost] = results || []

    return createPageMeta({
      headline: {
        value: project?.id || '',
        favorite: {
          active: project.is_favorite,
          target: project.id,
          category: 'project'
        }
      },
      title: project?.title || '',
      description: project?.description || '',
      badgeGroups: [
        {
          badges: [
            {
              variant: 'tint',
              color:
                ANNOTATOR_CATEGORY_COLOR[project?.category?.toUpperCase() as keyof typeof ANNOTATOR_CATEGORY_COLOR] ||
                'slate',
              size: 'md',
              value: upperFirst(project?.category || ''),
              avatarType: 'icon',
              avatarValue:
                ANNOTATOR_CATEGORY_ICON[project?.category?.toUpperCase() as keyof typeof ANNOTATOR_CATEGORY_ICON] || ''
            }
          ]
        },
        {
          badges: project?.annotation_types?.map((types) => ({ value: t(`annotationTool.${types}`) })) || [],
          maxLength: 20
        }
      ],
      link: {
        value: `${project.dataset.name}`,
        to: `/catalog/collections/${project.dataset.id}/data-units`
      },
      breadcrumbs: {
        project_id: {
          label: project?.title || 'project_id'
        },
        worker_id: {
          // TODO: API 미연동
          label: 'worker_id'
        },
        workshop_id: {
          label: workshop?.name || 'workshop_id'
        },
        guide_id: {
          label: workerPost?.title || 'guide_id'
        },
        role: {
          label: (route.params.role as string) || 'role'
        }
      },
      directoryNavigation: {
        collection: {
          children: {
            tasks: {},
            assignments: {},
            GT: {}
          }
        },
        workforce: {
          children: {
            workers: {},
            workshops: {},
            QC: {},
            guides: {}
          }
        },
        statistics: {
          children: {
            worker: {},
            discard: {},
            classification: {},
            rejected: {}
          }
        },
        settings: {
          prefixIcon: 'heroicons:cog-8-tooth',
          children: {
            default: {},
            annotation: {},
            collection: {
              class: 'hidden'
            },
            workflow: {},
            // tags: {
            //   class: 'hidden'
            // },
            members: {},
            danger: {
              variant: 'destructive'
            }
          }
        }
      }
    })
  })
}
