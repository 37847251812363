import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { BulkDestroyModule, CommonModule } from '~/api/shared/core/modules'
import type {
  GroundTruthBulkDestroyResData,
  GroundTruthCalculateCategoryCountsRetrieveResData,
  GroundTruthCategoryCountsRetrieveResData,
  GroundTruthChangeCategoriesCreateResData,
  GroundTruthCreateResData,
  GroundTruthDAO,
  GroundTruthDestroyResData,
  GroundTruthListResData,
  GroundTruthPartialUpdateDataResData,
  GroundTruthRetrieveResData
} from '~/api/shared/ground-truth/GroundTruth.types'

export class GroundTruthAPI extends DAOBase implements GroundTruthDAO {
  private commonModule: CommonModule
  private bulkDestroyModule: BulkDestroyModule
  private prefix = '/ground_truths'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
      bulkDestroyModule: BulkDestroyModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
    this.bulkDestroyModule = modules.bulkDestroyModule
  }

  public readonly list: GroundTruthDAO['list'] = (args) =>
    this.commonModule.list<GroundTruthListResData>({ prefix: this.prefix, ...args })

  public readonly retrieve: GroundTruthDAO['retrieve'] = (args) =>
    this.commonModule.retrieve<GroundTruthRetrieveResData>({ prefix: this.prefix, ...args })

  public readonly create: GroundTruthDAO['create'] = (args) =>
    this.commonModule.create<GroundTruthCreateResData>({ prefix: this.prefix, ...args })

  public readonly destroy: GroundTruthDAO['destroy'] = (args) =>
    this.commonModule.destroy<GroundTruthDestroyResData>({ prefix: this.prefix, ...args })

  public readonly bulkDestroy: GroundTruthDAO['bulkDestroy'] = (args) =>
    this.bulkDestroyModule.bulkDestroy<GroundTruthBulkDestroyResData>({ prefix: this.prefix, ...args })

  public readonly calculateCategoryCounts: GroundTruthDAO['calculateCategoryCounts'] = (args) =>
    this.fetchBase.request<GroundTruthCalculateCategoryCountsRetrieveResData>(
      `${this.prefix}/calculate_category_counts/`,
      {
        ...args,
        method: 'GET'
      }
    )

  public readonly categoryCounts: GroundTruthDAO['categoryCounts'] = (args) =>
    this.fetchBase.request<GroundTruthCategoryCountsRetrieveResData>(`${this.prefix}/category_counts/`, {
      ...args,
      method: 'GET'
    })

  public readonly changeCategories: GroundTruthDAO['changeCategories'] = (args) =>
    this.fetchBase.request<GroundTruthChangeCategoriesCreateResData>(`${this.prefix}/change_categories/`, {
      ...args,
      method: 'POST'
    })

  public readonly partialUpdateData: GroundTruthDAO['partialUpdateData'] = (args) =>
    this.fetchBase.request<GroundTruthPartialUpdateDataResData>(`${this.prefix}/update_data/`, {
      ...args,
      method: 'PATCH'
    })
}
