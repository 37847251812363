export default function optionsLayout(node) {
  node.addProps({
    layout: {
      default: 'vertical'
    },
    toClass: {
      type: Object
    }
  })

  node.props.toClass = (layout) => {
    return {
      vertical: 'grid-cols-1',
      'col-2': 'grid-cols-2',
      'col-3': 'grid-cols-3',
      'col-4': 'grid-cols-4',
      'col-5': 'grid-cols-5',
      'col-6': 'grid-cols-6',
      'col-7': 'grid-cols-7',
      'col-8': 'grid-cols-8',
      'col-9': 'grid-cols-9',
      'col-10': 'grid-cols-10',
      'col-11': 'grid-cols-11',
      'col-12': 'grid-cols-12'
    }[layout]
  }

  const schemaFn = node.props.definition.schema
  node.props.definition.schema = (sectionsSchema = {}) => {
    sectionsSchema.options = {
      attrs: {
        class: '$classes.options + " " + $toClass($layout)'
      }
    }
    return schemaFn(sectionsSchema)
  }
}
