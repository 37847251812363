import type {
  ModelCreateResData,
  ModelDAO,
  ModelDestroyResData,
  ModelListResData,
  ModelPartialUpdateResData,
  ModelRetrieveResData,
  ModelUpdateResData,
  ModelAncestorsResData
} from '~/api/machine-learning/Model.types'
import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule } from '~/api/shared/core/modules'

export class ModelAPI extends DAOBase implements ModelDAO {
  private commonModule: CommonModule
  private prefix = '/models'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
  }

  public readonly list: ModelDAO['list'] = (args) =>
    this.commonModule.list<ModelListResData>({
      prefix: this.prefix,
      ...args
    })

  public readonly retrieve: ModelDAO['retrieve'] = (args) =>
    this.commonModule.retrieve<ModelRetrieveResData>({
      prefix: this.prefix,
      ...args
    })

  public readonly create: ModelDAO['create'] = (args) =>
    this.commonModule.create<ModelCreateResData>({
      prefix: this.prefix,
      ...args
    })

  public readonly update: ModelDAO['update'] = (args) =>
    this.commonModule.update<ModelUpdateResData>({
      prefix: this.prefix,
      ...args
    })

  public readonly partialUpdate: ModelDAO['partialUpdate'] = (args) =>
    this.commonModule.partialUpdate<ModelPartialUpdateResData>({
      prefix: this.prefix,
      ...args
    })

  public readonly destroy: ModelDAO['destroy'] = (args) =>
    this.commonModule.destroy<ModelDestroyResData>({
      prefix: this.prefix,
      ...args
    })

  public readonly ancestors: ModelDAO['ancestors'] = (args) =>
    this.fetchBase.request<ModelAncestorsResData>(`${this.prefix}/ancestors`, args?.options)
}
