import { cva } from 'class-variance-authority'

export { default as Badge } from './Badge.vue'

export const badgeAvatarVariants = cva('ring-inset', {
  variants: {
    avatarType: {
      image: 'p-px',
      text: '',
      icon: 'bg-transparent !ring-0'
    },
    size: {
      md: '',
      sm: ''
    }
  },
  compoundVariants: [
    { avatarType: 'icon', size: 'md', class: '[&_svg]:h-5 [&_svg]:w-5' },
    { avatarType: 'icon', size: 'sm', class: '[&_svg]:h-4 [&_svg]:w-4' }
  ]
})

export const badgeIconVariants = cva('h-4 w-4', {
  variants: {
    variant: {
      solid: 'text-white-950',
      tint: '',
      outline: '',
      dimmed: 'text-white-950'
    },
    color: {
      slate: '',
      red: '',
      orange: '',
      amber: '',
      yellow: '',
      lime: '',
      green: '',
      emerald: '',
      teal: '',
      cyan: '',
      sky: '',
      blue: '',
      indigo: '',
      violet: '',
      purple: '',
      fuchsia: '',
      pink: '',
      rose: ''
    }
  },
  compoundVariants: [
    { variant: ['tint', 'outline'], color: 'slate', class: '!text-slate-500' },
    { variant: ['tint', 'outline'], color: 'red', class: '!text-red-500' },
    { variant: ['tint', 'outline'], color: 'orange', class: '!text-orange-500' },
    { variant: ['tint', 'outline'], color: 'amber', class: '!text-amber-500' },
    { variant: ['tint', 'outline'], color: 'yellow', class: '!text-yellow-500' },
    { variant: ['tint', 'outline'], color: 'lime', class: '!text-lime-500' },
    { variant: ['tint', 'outline'], color: 'green', class: '!text-green-500' },
    { variant: ['tint', 'outline'], color: 'emerald', class: '!text-emerald-500' },
    { variant: ['tint', 'outline'], color: 'teal', class: '!text-teal-500' },
    { variant: ['tint', 'outline'], color: 'cyan', class: '!text-cyan-500' },
    { variant: ['tint', 'outline'], color: 'sky', class: '!text-sky-500' },
    { variant: ['tint', 'outline'], color: 'blue', class: '!text-blue-500' },
    { variant: ['tint', 'outline'], color: 'indigo', class: '!text-indigo-500' },
    { variant: ['tint', 'outline'], color: 'violet', class: '!text-violet-500' },
    { variant: ['tint', 'outline'], color: 'purple', class: '!text-purple-500' },
    { variant: ['tint', 'outline'], color: 'fuchsia', class: '!text-fuchsia-500' },
    { variant: ['tint', 'outline'], color: 'pink', class: '!text-pink-500' },
    { variant: ['tint', 'outline'], color: 'rose', class: '!text-rose-500' }
  ]
})

export const badgeVariants = cva('flex max-w-fit items-center justify-center gap-x-1', {
  variants: {
    avatarType: {
      image: '',
      text: '',
      icon: ''
    },
    variant: {
      solid: 'text-white-950',
      tint: '',
      outline: '',
      dimmed: 'text-white-950'
    },
    size: {
      md: 'h-6 px-2 text-sm',
      sm: 'h-5 px-1.5 text-xs'
    },
    color: {
      slate: '',
      red: '',
      orange: '',
      amber: '',
      yellow: '',
      lime: '',
      green: '',
      emerald: '',
      teal: '',
      cyan: '',
      sky: '',
      blue: '',
      indigo: '',
      violet: '',
      purple: '',
      fuchsia: '',
      pink: '',
      rose: ''
    },
    rounded: {
      true: 'rounded-full',
      false: 'rounded'
    }
  },
  compoundVariants: [
    { variant: 'solid', color: 'slate', class: 'bg-slate-600' },
    { variant: 'solid', color: 'red', class: 'bg-red-600' },
    { variant: 'solid', color: 'orange', class: 'bg-orange-600' },
    { variant: 'solid', color: 'amber', class: 'bg-amber-600' },
    { variant: 'solid', color: 'yellow', class: 'bg-yellow-600' },
    { variant: 'solid', color: 'lime', class: 'bg-lime-600' },
    { variant: 'solid', color: 'green', class: 'bg-green-600' },
    { variant: 'solid', color: 'emerald', class: 'bg-emerald-600' },
    { variant: 'solid', color: 'teal', class: 'bg-teal-600' },
    { variant: 'solid', color: 'cyan', class: 'bg-cyan-600' },
    { variant: 'solid', color: 'sky', class: 'bg-sky-600' },
    { variant: 'solid', color: 'blue', class: 'bg-blue-600' },
    { variant: 'solid', color: 'indigo', class: 'bg-indigo-600' },
    { variant: 'solid', color: 'violet', class: 'bg-violet-600' },
    { variant: 'solid', color: 'purple', class: 'bg-purple-600' },
    { variant: 'solid', color: 'fuchsia', class: 'bg-fuchsia-600' },
    { variant: 'solid', color: 'pink', class: 'bg-pink-600' },
    { variant: 'solid', color: 'rose', class: 'bg-rose-600' },
    { variant: 'tint', color: 'slate', class: 'bg-slate-200 text-slate-600' },
    { variant: 'tint', color: 'red', class: 'bg-red-200 text-red-600' },
    { variant: 'tint', color: 'orange', class: 'bg-orange-200 text-orange-600' },
    { variant: 'tint', color: 'amber', class: 'bg-amber-200 text-amber-600' },
    { variant: 'tint', color: 'yellow', class: 'bg-yellow-200 text-yellow-600' },
    { variant: 'tint', color: 'lime', class: 'bg-lime-200 text-lime-600' },
    { variant: 'tint', color: 'green', class: 'bg-green-200 text-green-600' },
    { variant: 'tint', color: 'emerald', class: 'bg-emerald-200 text-emerald-600' },
    { variant: 'tint', color: 'teal', class: 'bg-teal-200 text-teal-600' },
    { variant: 'tint', color: 'cyan', class: 'bg-cyan-200 text-cyan-600' },
    { variant: 'tint', color: 'sky', class: 'bg-sky-200 text-sky-600' },
    { variant: 'tint', color: 'blue', class: 'bg-blue-200 text-blue-600' },
    { variant: 'tint', color: 'indigo', class: 'bg-indigo-200 text-indigo-600' },
    { variant: 'tint', color: 'violet', class: 'bg-violet-200 text-violet-600' },
    { variant: 'tint', color: 'purple', class: 'bg-purple-200 text-purple-600' },
    { variant: 'tint', color: 'fuchsia', class: 'bg-fuchsia-200 text-fuchsia-600' },
    { variant: 'tint', color: 'pink', class: 'bg-pink-200 text-pink-600' },
    { variant: 'tint', color: 'rose', class: 'bg-rose-200 text-rose-600' },
    { variant: 'outline', color: 'slate', class: 'text-slate-600 ring-1 ring-slate-300' },
    { variant: 'outline', color: 'red', class: 'text-red-600 ring-1 ring-red-300' },
    { variant: 'outline', color: 'orange', class: 'text-orange-600 ring-1 ring-orange-300' },
    { variant: 'outline', color: 'amber', class: 'text-amber-600 ring-1 ring-amber-300' },
    { variant: 'outline', color: 'yellow', class: 'text-yellow-600 ring-1 ring-yellow-300' },
    { variant: 'outline', color: 'lime', class: 'text-lime-600 ring-1 ring-lime-300' },
    { variant: 'outline', color: 'green', class: 'text-green-600 ring-1 ring-green-300' },
    { variant: 'outline', color: 'emerald', class: 'text-emerald-600 ring-1 ring-emerald-300' },
    { variant: 'outline', color: 'teal', class: 'text-teal-600 ring-1 ring-teal-300' },
    { variant: 'outline', color: 'cyan', class: 'text-cyan-600 ring-1 ring-cyan-300' },
    { variant: 'outline', color: 'sky', class: 'text-sky-600 ring-1 ring-sky-300' },
    { variant: 'outline', color: 'blue', class: 'text-blue-600 ring-1 ring-blue-300' },
    { variant: 'outline', color: 'indigo', class: 'text-indigo-600 ring-1 ring-indigo-300' },
    { variant: 'outline', color: 'violet', class: 'text-violet-600 ring-1 ring-violet-300' },
    { variant: 'outline', color: 'purple', class: 'text-purple-600 ring-1 ring-purple-300' },
    { variant: 'outline', color: 'fuchsia', class: 'text-fuchsia-600 ring-1 ring-fuchsia-300' },
    { variant: 'outline', color: 'pink', class: 'text-pink-600 ring-1 ring-pink-300' },
    { variant: 'outline', color: 'rose', class: 'text-rose-600 ring-1 ring-rose-300' },
    { variant: 'dimmed', color: 'slate', class: 'ring-1 ring-slate-200' },
    { variant: 'dimmed', color: 'red', class: 'ring-1 ring-red-200' },
    { variant: 'dimmed', color: 'yellow', class: 'ring-1 ring-yellow-200' },
    { variant: 'dimmed', color: 'green', class: 'ring-1 ring-green-200' },
    { variant: 'dimmed', color: 'blue', class: 'ring-1 ring-blue-200' }
  ],
  defaultVariants: {
    variant: 'solid',
    size: 'md',
    color: 'slate',
    rounded: false
  }
})
