import {
  outer,
  inner,
  wrapper,
  label,
  help,
  messages,
  message,
  prefix,
  suffix,
  icon,
  optionSlot,
  $if,
  $extend,
  defaultIcon,
  createSection,
  options as baseOptions
} from '@formkit/inputs'
import { Icon } from '#components'
import { Avatar } from '@/components/ui/avatar'
import { BadgeGroup } from '@/components/ui/badge-group'
import { Media } from '@/components/ui/media'
import { removeValue } from '@/lib/formkit/sections'
import { Spinner } from '~/components/ui/spinner'

/**
 * Input definition for a select.
 * @public
 */

const selections = createSection('selections', () => ({
  $cmp: markRaw(BadgeGroup),
  props: {
    class: 'truncate',
    badges: '$badges',
    maxCount: '$initialMatchedCount',
    maxLength: '$maxBadgeLength'
  }
}))

const selection = createSection('selection', () => ({
  $el: 'div',
  attrs: {
    class: '$classes.selection',
    selected: '$keyword !== undefined && $keyword !== "" || undefined'
  }
}))

const optionMedia = createSection('optionMedia', () => ({
  $cmp: markRaw(Media),
  props: {
    type: '$option.type',
    thumbnail: '$option.avatarValue',
    title: '$option.label',
    description: '$option.description',
    rounded: true,
    size: 'sm'
  }
}))

const optionMediaWrapper = createSection('optionMediaWrapper', () => ({
  $el: 'div',
  attrs: {
    class: '$classes.optionMediaWrapper'
  }
}))

const listItem = createSection('listItem', () => ({
  $el: 'div',
  attrs: {
    class: '$classes.listItem',
    value: '$option.value',
    valueType: '$option.valueType',
    label: '$option.label',
    selected: '$value === $option.value || $option.isIncluded || undefined',
    onClick: '$handlers.optionClick',
    'data-avatar-type': '$option.type',
    'data-avatar-value': '$option.avatarValue',
    'data-color': '$option.color'
  }
}))

const optionWrapper = createSection('optionWrapper', () => ({
  $el: 'div',
  bind: '$option.attrs',
  attrs: {
    class: '$classes.optionWrapper'
  }
}))

const optionLabel = createSection('optionLabel', () => ({
  $el: 'div',
  attrs: {
    class: '$classes.optionLabel'
  }
}))

const optionAvatar = createSection('optionLabel', () => ({
  $cmp: markRaw(Avatar),
  if: '$hasAvatar',
  props: {
    type: '$option.type',
    value: '$option.avatarValue',
    border: true,
    rounded: true,
    size: '2xs'
  }
}))

const selectIcon = createSection('selectIcon', () => ({
  $cmp: markRaw(Icon),
  props: {
    name: 'heroicons:check'
  }
}))

const multipleSearchInputWrapper = createSection('multipleSearchInputWrapper', () => ({
  $el: 'div',
  attrs: {
    class: '[&>input]:trancate w-1/3 grow !border-none !bg-transparent [&>input]:w-full'
  }
}))

const searchInput = createSection('input', () => ({
  $el: 'input',
  if: '$searchable',
  attrs: {
    type: 'text',
    disabled: '$disabled',
    class: '$classes.input',
    value: '$keyword',
    placeholder: '$placeholder || "검색"',
    inputId: '$id',
    onInput: '$handlers.searchInput',
    onClick: '$handlers.searchClick',
    onKeydown: '$handlers.searchKeyDown',
    onBlur: '$handlers.blur'
  }
}))

const multipleInputWrapper = createSection('multipleInputWrapper', () => ({
  $el: 'div',
  attrs: {
    class: '$classes.multipleInputWrapper'
  }
}))

const multipleInputPlaceholder = createSection('multipleInputPlaceholder', () => ({
  $el: 'div',
  if: '$disabled === undefined && $searchable === false',
  attrs: {
    class: '$classes.multipleInputPlaceholder'
  }
}))

const listItems = createSection('listItems', () => ({
  $el: 'div',
  attrs: {
    class: '$classes.listItems'
  }
}))

const listbox = createSection('listbox', () => ({
  $el: 'div',
  attrs: {
    class: '$classes.listbox',
    'data-place-top': '$placeTop || undefined'
  }
}))

const emptyMessage = createSection('emptyMessage', () => ({
  $el: 'div',
  if: '$options.length === 0 || $option.options.length === 0 || $options === undefined',
  attrs: {
    class: '$classes.emptyMessage'
  }
}))

const loadMore = createSection('loadMore', () => ({
  $el: 'div',
  if: '$matchedCount > 10 && $matchedCount !== $options.length && $isLoading === false',
  attrs: {
    class: '$classes.loadMore',
    onClick: '$handlers.loadMore'
  }
}))

const loaderIcon = createSection('loaderIcon', () => ({
  $cmp: markRaw(Spinner),
  props: {
    size: 'sm'
  }
}))

const loading = createSection('loading', () => ({
  $el: 'div',
  if: '$isLoading',
  attrs: {
    class: '$classes.loading'
  }
}))

// listbox-footer 슬롯
const listboxFooterWrapper = createSection('footerWrapper', () => ({
  $el: 'div',
  if: '$slots.footerWrapper || $slots.footer || $footer',
  attrs: { class: '$classes.footerWrapper' }
}))

const listboxFooter = createSection('footer', () => ({
  $el: 'div',
  attrs: { class: '$classes.footer' }
}))

export const select = {
  /**
   * The actual schema of the input, or a function that returns the schema.
   */
  schema: outer(
    $extend(
      wrapper(
        label('$label', {
          $el: 'span',
          if: '$attrs.required === "" || $attrs.required === true',
          attrs: {
            class: '$classes.asterisk'
          },
          children: ['*']
        }),
        $extend(
          inner(
            icon('prefix'),
            prefix(),
            $if(
              '$multiple',
              multipleInputWrapper(
                selections(multipleSearchInputWrapper(searchInput())),
                multipleInputPlaceholder('$placeholder || "선택"')
              ),
              $if(
                '$searchable',
                searchInput(),
                $if('$slots.selection', () => '$slots.selection', selection('$keyword || $placeholder || "선택"'))
              )
            ),
            $extend(removeValue(), {
              if: '$multiple === false && $searchable',
              attrs: { onClick: '$handlers.removeValueClick' }
            }),
            suffix(),
            icon('suffix')
          ),
          { attrs: { onClick: '$handlers.innerClick' } }
        ),
        listbox(
          emptyMessage('$emptyMessage || "표시할 내용이 없습니다."'),
          listItems(
            $if(
              '$slots.default',
              () => '$slots.default',
              optionSlot(
                $if(
                  '$slots.option',
                  () => '$slots.option',
                  listItem(
                    $if(
                      '$option.description',
                      optionMediaWrapper(optionMedia()),
                      optionWrapper(optionAvatar(), optionLabel('$option.label || $option'))
                    ),
                    selectIcon()
                  )
                )
              )
            )
          ),
          loadMore('더보기'),
          loading(loaderIcon(), '로딩중'),
          listboxFooterWrapper(listboxFooter())
        )
      ),
      { attrs: { 'data-expanded': '$isExpanded || undefined', 'data-multiple': '$multiple || undefined' } }
    ),
    help('$help'),
    messages(message('$message.value'))
  ),
  /**
   * The type of node, can be a list, group, or input.
   */
  type: 'input',
  /**
   * An array of extra props to accept for this input.
   */
  props: ['options', 'placeholder', 'optionsLoader', 'resource'],
  /**
   * Forces node.props.type to be this explicit value.
   */
  forceTypeProp: 'select',
  /**
   * Additional features that should be added to your input
   */
  features: [
    baseOptions,
    options,
    defaultIcon('select', 'select'),
    search,
    icons,
    singleValue,
    multipleValue,
    avatar,
    footer,
    api
  ],
  /**
   * The key used to memoize the schema.
   */
  schemaMemoKey: 'v1j3qxb9hs'
}

const LOAD_SIZE = 10

const getOptions = async (node) => {
  const hasArgs = Object.keys(node.props.args).length > 0
  if (hasArgs) {
    node.props.args.params.page = node.context.page
    node.props.args.params.page_size = LOAD_SIZE
  }
  node.context.isLoading = true
  node.props.params.page = node.context.page
  node.props.params.page_size = LOAD_SIZE
  if (node.context.keyword) node.props.params.search = node.context.keyword
  else delete node.props.params.search
  if (!node.props.multiple && node._value) delete node.props.params.search
  return await node.props.resource[node.props.method](hasArgs ? node.props.args : { params: node.props.params })
}

const getOption = async (node, id) => {
  return await node.props.resource.retrieve({ path: id })
}

/**
 * The mappingSchema function resolves the property accessor using dot notation. ex) person.name
 * And you can also include index of an array. ex) people.3.name
 */
const mappingSchema = (schema, target) => {
  const option = {}
  Object.keys(schema).forEach((key) => {
    if (key === 'value') {
      if (typeof schema[key] === 'string') {
        option[key] = schema[key]
          .split('.')
          .reduce((prev, cur) => prev[cur], target)
          .toString()
      } else if (typeof schema[key] === 'object') {
        const objectValue = {}
        Object.keys(schema[key]).forEach((subKey) => {
          const subData = schema[key][subKey].split('.').reduce((prev, cur) => prev[cur], target)
          objectValue[subKey] = subData
        })
        option[key] = JSON.stringify(objectValue)
      }
    } else if (typeof schema[key] === 'function') {
      option[key] = schema[key](target)
    } else {
      option[key] = schema[key].split('.').reduce((prev, cur) => prev[cur], target)
    }
  })
  return option
}

const addAvatarAttribute = (option) => {
  if (option.thumbnail) {
    option.type = 'image'
    option.avatarValue = option.thumbnail
  } else if (option.icon) {
    option.type = 'icon'
    option.avatarValue = option.icon
  } else if (option.text) {
    option.type = 'text'
    option.avatarValue = option.text
  }
}

const addBadge = (node, label, badgeId, avatarType = null, avatarValue = null, color = null) => {
  node.context.badges.push({
    value: label,
    id: badgeId,
    avatarType,
    avatarValue,
    color,
    suffixIcon: 'heroicons:x-mark',
    onSuffixIconClick: (e) => {
      const optionObj = node.context.options.find((option) => option.value === badgeId)
      if (optionObj) optionObj.isIncluded = false
      node.input(node._value.filter((value) => value !== badgeId))
      node.context.badges = node.context.badges.filter((badge) => badgeId !== badge.id)
      e.stopPropagation()
    }
  })
}

function search(node) {
  node.addProps({
    searchable: {
      boolean: true,
      default: false
    }
  })
  node.on('created', () => {
    node.context.handlers.searchInput = (e) => {
      if (!node.props.multiple) node.input('')
      node.context.isExpanded = !!e.target.value
      node.props.suffixIcon = node.context.isExpanded ? 'heroicons:chevron-up' : 'heroicons:chevron-down'
      node.context.state.empty = !e.target.value && !node._value
      node.context.keyword = e.target.value
      node.context.page = 1
      node.context.options = []
      if (node.props.resource) {
        getOptions(node)
          .then((data) => {
            node.context.matchedCount = data.count
            const options = []
            const results = data.results ?? data
            results.forEach((result) => {
              const option = mappingSchema(node.props.schema, result)
              option.valueType = typeof option.value
              if (
                (!node.props.multiple && node._value === option.value) ||
                (node.props.multiple && node._value?.includes(option.value))
              )
                option.isIncluded = true
              addAvatarAttribute(option)
              options.push(option)
            })
            node.props.options = node.context.searchedOptions = options
          })
          .finally(() => {
            node.context.isLoading = false
          })
        if (!e.target.value) node.context.matchedCount = node.context.initialMatchedCount
      } else {
        node.context.initialOptions.forEach((option) => {
          if (
            (!node.props.multiple && node._value === option.value) ||
            (node.props.multiple && node._value?.includes(option.value))
          )
            option.isIncluded = true
          else {
            option.isIncluded = false
          }
        })
        if (!e.target.value) {
          node.context.matchedCount = node.context.initialOptions.length
          node.context.searchedOptions = node.context.initialOptions
          node.context.options = node.context.initialOptions.slice(0, LOAD_SIZE)
        } else {
          node.context.searchedOptions = []
          node.context.initialOptions.forEach((option) => {
            if (
              option.label.toLowerCase().includes(e.target.value.toLowerCase()) ||
              (option.description && option.description.toLowerCase().includes(e.target.value.toLowerCase()))
            ) {
              node.context.searchedOptions.push(option)
            }
          })
          node.context.matchedCount = node.context.searchedOptions.length
          node.context.options = node.context.searchedOptions.slice(0, LOAD_SIZE)
        }
      }
    }
    node.context.handlers.searchKeyDown = (e) => {
      if (e.key === 'Enter') e.preventDefault()
    }
    node.context.handlers.searchClick = (e) => {
      e.stopPropagation()
    }
  })
}

function singleValue(node) {
  if (node.props.multiple) return
  node.on('input', () => {
    if (node._value) {
      node.context.keyword = node.props.options?.find((option) => option.value === node._value)?.label
    }
  })
}

function multipleValue(node) {
  if (!node.props.multiple) return
  node.on('input', () => {
    if (node._value && node._value.length) {
      node.context.placeholder = ' '
    } else {
      node.context.placeholder = node.props.placeholder || '검색'
    }
  })
}

function initializeOptions(inputId) {
  const inputElement = document.querySelector(`input[inputid="${inputId}"]`)
  if (inputElement) {
    inputElement.value = ''
    inputElement.dispatchEvent(new Event('input'))
  }
}

function options(node) {
  node.addProps({
    multiple: {
      boolean: true,
      default: false
    },
    maxBadgeLength: {
      default: 15
    },
    placeTop: {
      boolean: true,
      default: false
    }
  })
  node.on('input', () => {
    if (!node._value || (node.props.multiple && node._value && !node._value.length)) {
      node.props.options?.forEach((option) => (option.isIncluded = false))
      node.context.badges = []
      node.context.keyword = ''
    }
  })
  node.on('created', () => {
    node.context.page = 1
    node.context.isLoading = false
    if (node.props.resource) {
      getOptions(node)
        .then((data) => {
          node.context.initialMatchedCount = node.context.matchedCount = data.count
          const options = []
          const results = data.results ?? data
          results.forEach((result) => {
            const option = mappingSchema(node.props.schema, result)
            option.valueType = typeof option.value
            addAvatarAttribute(option)
            options.push(option)
          })
          node.context.searchedOptions = options
          node.props.options = node.context.searchedOptions
          if (!node.props.multiple) {
            node.context.keyword = node.props.options?.find((option) => option.value === node._value)?.label
          }
        })
        .finally(() => {
          node.context.isLoading = false
        })
    } else {
      node.props.options.forEach((option) => {
        if (node.props.multiple && node._value?.includes(option.value)) option.isIncluded = true
      })
      node.context.initialOptions = node.context.searchedOptions = node.props.options
      node.context.initialOptions.forEach((option) => {
        option.valueType = typeof option.value
        addAvatarAttribute(option)
      })
      node.context.initialMatchedCount = node.context.matchedCount = node.context.initialOptions.length
      node.props.options = node.context.initialOptions.slice(0, LOAD_SIZE)
    }
    if (!node._value) node._value = node.props.multiple ? [] : ''
    if (node.props.multiple) {
      node.context.badges = []
      if (node.props.resource) {
        node._value.forEach((id) => {
          getOption(node, id).then((data) => {
            const option = mappingSchema(node.props.schema, data)
            addBadge(node, option.label, option.value, option.type, option.avatarValue, option.color)
          })
        })
      } else {
        node.context.initialOptions.forEach((option) => {
          if (node._value.includes(option.value))
            addBadge(node, option.label, option.value, option.type, option.avatarValue, option.color)
        })
      }
      node.context.handlers.optionClick = (e) => {
        const option = e.target
        let optionValue = option.getAttribute('value')
        const optionObj = node.context.options.find((option) => option.value === optionValue)
        if (option.getAttribute('valueType') === 'number') optionValue = +optionValue
        const optionLabel = option.getAttribute('label')
        if (!node._value) node._value = []
        if (node._value.includes(optionValue)) {
          node.input(node._value.slice().filter((value) => optionValue !== value))
          node.context.badges = node.context.badges.filter((badge) => optionValue !== badge.id)
          if (optionObj) optionObj.isIncluded = false
        } else {
          node.input([optionValue, ...node._value])
          addBadge(
            node,
            optionLabel,
            optionValue,
            option.getAttribute('data-avatar-type'),
            option.getAttribute('data-avatar-value'),
            option.getAttribute('data-color')
          )
          if (optionObj) optionObj.isIncluded = true
          node.context.state.empty = false
          node.context.keyword = ''
          node.context.isExpanded = false
          node.props.suffixIcon = 'heroicons:chevron-down'
          if (node.props.searchable) initializeOptions(node.props.id)
        }
      }
    } else {
      node.context.handlers.optionClick = (e) => {
        const option = e.target
        node.context.keyword = option.getAttribute('label')
        let optionValue = option.getAttribute('value')
        if (option.getAttribute('valueType') === 'number') optionValue = +optionValue
        node.input(optionValue)
        node.context.isExpanded = false
        node.props.suffixIcon = 'heroicons:chevron-down'
      }
    }
    node.context.handlers.loadMore = () => {
      node.context.page += 1
      if (node.props.resource) {
        getOptions(node)
          .then((data) => {
            const results = data.results ?? data
            results.forEach((result) => {
              const option = mappingSchema(node.props.schema, result)
              option.valueType = typeof option.value
              addAvatarAttribute(option)
              if (
                (!node.props.multiple && node._value === option.value) ||
                (node.props.multiple && node._value?.includes(option.value))
              )
                option.isIncluded = true
              node.context.options.push(option)
            })
            node.context.searchedOptions = node.props.options
          })
          .finally(() => {
            node.context.isLoading = false
          })
      } else {
        node.context.options.push(
          ...node.context.searchedOptions.slice(LOAD_SIZE * (node.context.page - 1), LOAD_SIZE * node.context.page)
        )
      }
    }
    node.context.handlers.innerClick = () => {
      if (node.context.isExpanded) {
        node.context.isExpanded = false
        node.props.suffixIcon = 'heroicons:chevron-down'
      } else {
        node.context.isExpanded = true
        node.props.suffixIcon = 'heroicons:chevron-up'
      }
    }
  })
}

function icons(node) {
  if (node.props.searchable) node.props.prefixIcon = 'heroicons:magnifying-glass'
  node.props.suffixIcon = 'heroicons:chevron-down'
  node.on('created', () => {
    node.context.handlers.removeValueClick = (e) => {
      e.stopPropagation()
      node.input('')
      node.context.keyword = ''
      node.context.options = node.context.searchedOptions
      if (!node.props.resource) {
        node.context.matchedCount = node.context.initialOptions.length
        node.context.searchedOptions = node.context.initialOptions
        node.context.options = node.context.searchedOptions.slice(0, LOAD_SIZE)
      }
      node.context.state.empty = true
      if (node.props.searchable) initializeOptions(node.props.id)
    }
  })
}

function avatar(node) {
  node.on('created', () => {
    if (node.props.options)
      node.context.hasAvatar = !!node.props.options.find((option) => option.thumbnail || option.icon || option.text)
  })
}

function footer(node) {
  node.addProps({ footer: { default: '' } })
  node.on('created', () => {
    node.context.footer = node.props.footer
  })
}

function api(node) {
  if (node.props.resource) return
  node.addProps({
    params: {
      default: {}
    },
    args: {
      default: {}
    },
    schema: {
      default: {}
    },
    method: {
      default: 'list'
    }
  })
}
