import { findSection } from '@formkit/inputs'

export function colorpickerPlugin(node) {
  if (node.props.type !== 'colorpicker') return

  const schemaFn = node.props.definition.schema
  node.props.definition.schema = (sectionsSchema = {}) => {
    const definitionSchema = schemaFn(sectionsSchema)
    const outerSection = findSection(definitionSchema, 'outer')[1].else
    const wrapperSection = findSection(outerSection.children, 'wrapper')[1].else
    const innerSection = findSection(wrapperSection.children, 'inner')[1].else
    const swatchPreviewSection = findSection(innerSection.children, 'swatchPreview')[1].else
    swatchPreviewSection.children.splice(1, 1)
    return definitionSchema
  }
}
