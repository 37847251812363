import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import type { FileModuleDAO } from '~/api/shared/core/modules/types'
import type { ExtractModuleParams } from '~/api/shared/core/types'

export class FileModule extends DAOBase implements FileModuleDAO {
  public readonly files = <TReturn>({
    prefix,
    path,
    options
  }: ExtractModuleParams<FileModuleDAO['files']>): Promise<TReturn> => {
    const response = this.fetchBase.request<TReturn>(`${prefix}/${path}/files`, options)
    return response
  }
}
