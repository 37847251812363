import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { BulkDestroyModule, CommonModule } from '~/api/shared/core/modules'
import type {
  WorkerBulkDestroyResData,
  WorkerCountAssignmentsNotConfirmedResData,
  WorkerCreateResData,
  WorkerDAO,
  WorkerDestroyResData,
  WorkerDependencyCountResData,
  WorkerListResData,
  WorkerPartialUpdateResData,
  WorkerRetrieveResData,
  WorkerUpdateResData
} from '~/api/worker/Worker.types'

export class WorkerAPI extends DAOBase implements WorkerDAO {
  private commonModule: CommonModule
  private bulkDestroyModule: BulkDestroyModule
  private prefix = '/workers'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
      bulkDestroyModule: BulkDestroyModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
    this.bulkDestroyModule = modules.bulkDestroyModule
  }

  public readonly list: WorkerDAO['list'] = (args) =>
    this.commonModule.list<WorkerListResData>({ ...args, prefix: this.prefix })

  public readonly retrieve: WorkerDAO['retrieve'] = (args) =>
    this.commonModule.retrieve<WorkerRetrieveResData>({ ...args, prefix: this.prefix })

  public readonly create: WorkerDAO['create'] = (args) =>
    this.commonModule.create<WorkerCreateResData>({ ...args, prefix: this.prefix })

  public readonly update: WorkerDAO['update'] = (args) =>
    this.commonModule.update<WorkerUpdateResData>({ ...args, prefix: this.prefix })

  public readonly partialUpdate: WorkerDAO['partialUpdate'] = (args) =>
    this.commonModule.partialUpdate<WorkerPartialUpdateResData>({ ...args, prefix: this.prefix })

  public readonly destroy: WorkerDAO['destroy'] = (args) =>
    this.commonModule.destroy<WorkerDestroyResData>({ ...args, prefix: this.prefix })

  public readonly bulkDestroy: WorkerDAO['bulkDestroy'] = (args) =>
    this.bulkDestroyModule.bulkDestroy<WorkerBulkDestroyResData>({ ...args, prefix: this.prefix })

  public readonly dependencyCount: WorkerDAO['dependencyCount'] = (args) => {
    return this.fetchBase.request<WorkerDependencyCountResData>(`${this.prefix}/${args.path}/dependency_count/`, {
      ...args
    })
  }

  public readonly countAssignmentsNotConfirmed: WorkerDAO['countAssignmentsNotConfirmed'] = (args) => {
    return this.fetchBase.request<WorkerCountAssignmentsNotConfirmedResData>(
      `${this.prefix}/count_assignments_not_confirmed/`,
      {
        ...args
      }
    )
  }
}
