import type { LabelParticipationDAO } from '~/api/project/types'
import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule, BulkDestroyModule } from '~/api/shared/core/modules'

export class LabelParticipationAPI extends DAOBase implements LabelParticipationDAO {
  private commonModule: CommonModule
  private bulkDestroyModule: BulkDestroyModule
  private prefix = '/label_participations'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
      bulkDestroyModule: BulkDestroyModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
    this.bulkDestroyModule = modules.bulkDestroyModule
  }

  public readonly list: LabelParticipationDAO['list'] = (args) =>
    this.commonModule.list<unknown>({ prefix: this.prefix, ...args })

  public readonly retrieve: LabelParticipationDAO['retrieve'] = (args) =>
    this.commonModule.retrieve<unknown>({ prefix: this.prefix, ...args })

  public readonly create: LabelParticipationDAO['create'] = ({ body, options }) =>
    this.commonModule.create<unknown>({ prefix: this.prefix, body, options })

  public readonly update: LabelParticipationDAO['update'] = (args) =>
    this.commonModule.update<unknown>({ prefix: this.prefix, ...args })

  public readonly partialUpdate: LabelParticipationDAO['partialUpdate'] = (args) =>
    this.commonModule.partialUpdate<unknown>({ prefix: this.prefix, ...args })

  public readonly destroy: LabelParticipationDAO['destroy'] = (args) =>
    this.commonModule.destroy<unknown>({ prefix: this.prefix, ...args })

  public readonly bulkDestroy: LabelParticipationDAO['bulkDestroy'] = (args) =>
    this.bulkDestroyModule.bulkDestroy({ prefix: this.prefix, ...args })
}
