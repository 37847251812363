import { cloneDeep, has } from 'lodash-es'
import type { RouteParamsGeneric } from 'vue-router'
import type { PageMetaQueryResult } from '~/composables/shared/usePageMetaQuery'
import { usePageTreeStructure } from '~/composables/shared/usePageTreeStructure'

export default defineNuxtPlugin({
  name: 'route-parser',
  parallel: true,
  setup(nuxtApp) {
    const directoryRoutes = usePageTreeStructure()
    const route = useRoute()
    const oneLevelDepthPageList = Object.keys(directoryRoutes.children)

    if (!directoryRoutes) return

    const findMatchedPathSources = (searchValue: string, mode: 'route-name' | 'directory-path') => {
      if (mode === 'directory-path') {
        return searchValue.split('/').filter(Boolean)
      }

      return (
        route.matched
          .find((route) => route.name === searchValue)
          ?.path.split('/')
          .filter(Boolean) || []
      )
    }

    const addNodeByDepth = (stack: DirectoryRoutesPageNode[], pathSources: string[], node: DirectoryRoutesPageNode) => {
      const childNode = node?.children?.[pathSources[stack.length]] as DirectoryRoutesPageNode

      if (!childNode) return

      stack.push(childNode)
      addNodeByDepth(stack, pathSources, childNode)
    }

    const getSlugIndexes = (nodePathes: string[]) => {
      return nodePathes.map((path, index) => (path.includes(':') ? index : -1)).filter((index) => index !== -1)
    }

    const createMappedPath = (node: DirectoryRoutesPageNode) => {
      const routePathes = route.path.split('/').filter(Boolean)
      const nodePathes = node.path.split('/').filter(Boolean)
      const slugIndexes = getSlugIndexes(nodePathes)

      if (!oneLevelDepthPageList.includes(routePathes[0])) routePathes.shift()

      slugIndexes.forEach((slugIndex) => {
        nodePathes[slugIndex] = routePathes[slugIndex]
      })

      return `/${nodePathes.join('/')}`
    }

    const createMappedNodeName = (
      node: DirectoryRoutesPageNode,
      slugMap?: PageMetaQueryResult['breadcrumbs'] | RouteParamsGeneric
    ) => {
      const nodePathNames = node.name.split('__')
      const slugIndexes = getSlugIndexes(nodePathNames)
      const replacedPathName = nodePathNames.map((pathName) => pathName.replace(':', '').replace('()', ''))

      slugIndexes.forEach((slugIndex) => {
        if (has(slugMap, replacedPathName[slugIndex])) {
          const alterTextMap: {
            [slug: string]: string
          } = {}

          for (const key in slugMap) {
            if (!Array.isArray(slugMap[key]) && typeof slugMap[key] !== 'string') {
              const customSlugMap = slugMap as NonNullable<PageMetaQueryResult['breadcrumbs']>
              alterTextMap[key] = customSlugMap[key].label
            } else {
              alterTextMap[key] = slugMap[key] as string
            }
          }

          nodePathNames[slugIndex] = alterTextMap[replacedPathName[slugIndex]]
        }
      })

      return nodePathNames.join('__')
    }

    const getSlugMappedNodes = (
      nodes: Array<DirectoryRoutesPageNode | PageNode>,
      slugMap?: PageMetaQueryResult['breadcrumbs'] | RouteParamsGeneric
    ) => {
      return nodes.map((node) => {
        let nodeName = node.name
        let nodePath = node.path
        let nodeSiblings = has(node, 'siblings') ? (node as DirectoryRoutesPageNode).siblings : []
        const nodeChildren = has(node, 'children') ? (node as DirectoryRoutesPageNode).children : {}

        if (has(node, 'siblings') && nodeSiblings.length) {
          nodeSiblings = getSlugMappedNodes((node as DirectoryRoutesPageNode).siblings, slugMap)
        }

        if (has(node, 'children') && Object.keys(nodeChildren).length) {
          for (const key in nodeChildren) {
            nodeChildren[key] = getSlugMappedNodes([nodeChildren[key]], slugMap)[0]
          }
        }

        if (node.path.includes(':')) nodePath = createMappedPath(node as DirectoryRoutesPageNode)
        if (node.name.includes(':')) nodeName = createMappedNodeName(node as DirectoryRoutesPageNode, slugMap)

        return {
          ...node,
          name: nodeName,
          path: nodePath,
          siblings: nodeSiblings,
          children: nodeChildren
        }
      })
    }

    const getMenuByRouteName = (routeName: string, mode: 'route-name' | 'directory-path') => {
      const pathSources = findMatchedPathSources(routeName, mode)

      if (pathSources.includes(':tenant?')) pathSources.shift()

      const rootCategoryNode = cloneDeep(directoryRoutes.children?.[pathSources[0]])
      const menuItems = [rootCategoryNode]

      addNodeByDepth(menuItems, pathSources, rootCategoryNode)

      return menuItems
    }

    const getPathMappedMenuByRouteName = ({
      routeName,
      mode,
      slugMap = route.params
    }: {
      routeName: string
      mode: 'route-name' | 'directory-path'
      slugMap?: PageMetaQueryResult['breadCrumbs'] | RouteParamsGeneric
    }) => {
      const menuItems = getMenuByRouteName(routeName, mode)

      return getSlugMappedNodes(menuItems, slugMap)
    }

    const computedPathMappedItems = computed(() => {
      return getPathMappedMenuByRouteName({ routeName: route.name as string, mode: 'route-name' })
    })

    nuxtApp.provide('directoryRoutes', {
      menu: computedPathMappedItems,
      getSlugMappedNodes,
      getMenuByRouteName,
      getPathMappedMenuByRouteName
    })
  }
})
