<template>
  <Primitive
    v-bind="props"
    :as="getComponent(as)"
    :as-child
    :class="
      cn(
        buttonVariants({
          variant,
          size,
          color,
          prefixIcon: !!prefixIcon,
          suffixIcon: !!suffixIcon,
          icon: !!icon,
          squared
        }),
        $attrs.class ?? ''
      )
    ">
    <Icon v-if="icon" :name="icon" :class="buttonIconVariants({ variant, size, color })" />
    <div v-else :class="buttonContentVariants({ variant, size })">
      <Icon
        v-if="prefixIcon"
        :name="prefixIcon"
        :class="buttonIconVariants({ variant, size, color, affix: true })"
        @click="$emit('prefixIconClick', $event)" />
      <slot>{{ value }}</slot>
      <Icon
        v-if="suffixIcon"
        :name="suffixIcon"
        :class="buttonIconVariants({ variant, size, color, affix: true })"
        @click="$emit('suffixIconClick', $event)" />
    </div>
  </Primitive>
</template>

<script setup lang="ts">
import { Primitive } from 'radix-vue'
import { NuxtLink } from '#components'
import { cn } from '@/lib/utils'
import { buttonContentVariants, buttonIconVariants, buttonVariants } from '.'
import type { ButtonProps } from './types'

const props = withDefaults(defineProps<ButtonProps>(), {
  variant: 'solid',
  size: 'md',
  as: 'button',
  prefixIcon: null,
  suffixIcon: null,
  icon: null,
  squared: false
})

function getComponent(component: string) {
  if (component === 'NuxtLink') {
    return NuxtLink
  }
  return component
}
</script>
