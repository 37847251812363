const label = tw`text-medium mb-1 flex gap-x-0.5 text-sm text-slate-900`

export default {
  inner: tw`flex`,
  icon: tw`flex h-6 text-slate-400 group-data-[size=sm]:h-5`,
  prefixIcon: tw`
    group-focus-within/inner:text-blue-500 group-hover/inner:text-blue-500 
    group-data-[disabled]:text-slate-400 group-data-[errors]:text-red-500 group-data-[invalid]:text-red-500`,
  label,
  legend: label,
  asterisk: tw`text-blue-600`,
  help: tw`mt-2 flex gap-x-1 text-xs text-slate-600`,
  helpIcon: tw`h-4 w-4`,
  messages: tw`mt-2 text-xs text-red-600`,
  outer: tw`group`
}
