export default defineNuxtPlugin({
  name: 'ckeditor-plugin',
  setup(nuxtApp) {
    // 이미 정의되어 있는지 확인
    if (!nuxtApp.$classicEditor) {
      nuxtApp.hooks.hook('app:beforeMount', async () => {
        await import('@blowstack/ckeditor5-full-free-build/build/translations/ko')
        const fullBuild = await import('@blowstack/ckeditor5-full-free-build')
        const { CkeditorPlugin } = await import('@ckeditor/ckeditor5-vue')

        nuxtApp.vueApp.use(CkeditorPlugin)
        // provide 대신 직접 할당
        nuxtApp.$classicEditor = fullBuild.default
      })
    }
  },
  parallel: true
})
