import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { BulkDestroyModule, CommonModule } from '~/api/shared/core/modules'
import type {
  MemberRoleBulkDestroyResData,
  MemberRoleDAO,
  MemberRoleListResData,
  MemberRolePartialUpdateResData,
  MemberRoleUpdateResData
} from '~/api/worker/MemberRole.types'

export class MemberRoleAPI extends DAOBase implements MemberRoleDAO {
  private commonModule: CommonModule
  private bulkDestroyModule: BulkDestroyModule

  private prefix = '/member_roles'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
      bulkDestroyModule: BulkDestroyModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
    this.bulkDestroyModule = modules.bulkDestroyModule
  }

  public readonly list: MemberRoleDAO['list'] = (args) =>
    this.commonModule.list<MemberRoleListResData>({ ...args, prefix: this.prefix })

  public readonly update: MemberRoleDAO['update'] = (args) =>
    this.commonModule.update<MemberRoleUpdateResData>({ ...args, prefix: this.prefix })

  public readonly partialUpdate: MemberRoleDAO['partialUpdate'] = (args) =>
    this.commonModule.partialUpdate<MemberRolePartialUpdateResData>({ ...args, prefix: this.prefix })

  public readonly bulkDestroy: MemberRoleDAO['bulkDestroy'] = (args) =>
    this.bulkDestroyModule.bulkDestroy<MemberRoleBulkDestroyResData>({ ...args, prefix: this.prefix })
}
