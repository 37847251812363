import { useAccountMetaQuery } from '~/composables/account/useAccountMetaQuery'
import { useCatalogMetaQuery } from '~/composables/catalog/useCatalogMetaQuery'
import { useMLMetaQuery } from '~/composables/ml/useMLMetaQuery'
import { useProjectMetaQuery } from '~/composables/project/useProjectMetaQuery'
import { useTenantMetaQuery } from '~/composables/tenant/useTenantMetaQuery'
import { useWorkerMetaQuery } from '~/composables/worker/useWorkerMetaQuery'

export const usePageMetaPrefetch = async () => {
  const route = useRoute()
  const categoryPath = route.matched[0].path.split('/')[1]

  switch (categoryPath) {
    case 'account': {
      return await useAccountMetaQuery()
    }
    case 'catalog': {
      return await useCatalogMetaQuery()
    }
    case 'ml': {
      return await useMLMetaQuery()
    }
    case 'projects': {
      return await useProjectMetaQuery()
    }
    case 'settings': {
      return await useTenantMetaQuery()
    }
    case 'data-lab': {
      return await useWorkerMetaQuery()
    }
    default: {
      return await useProjectMetaQuery()
    }
  }
}
