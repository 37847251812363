import { TOKEN } from '~/constants/shared'

export default defineNuxtRouteMiddleware((to) => {
  const authToken = useCookie(TOKEN.AUTH.value)
  const isAuthorized = Boolean(authToken.value)

  if (isAuthorized) {
    switch (to.name) {
      case 'index':
        return navigateTo({ name: 'projects', params: to.params })
      case 'account':
        return navigateTo({ name: 'account-profile', params: to.params })
      // settings
      case 'settings-':
        return navigateTo({ name: 'settings-personal', params: to.params })
      case 'infra':
        return navigateTo({ name: 'infra-agents', params: to.params })
      case 'plugins':
        return navigateTo({ name: 'plugins-neural_net', params: to.params })
      // catalog
      case 'catalog-collections-collection_id':
        return navigateTo({
          name: 'catalog-collections-collection_id-data-units',
          params: { collection_id: to.params.collection_id, ...to.params }
        })
      case 'catalog-collections-collection_id-settings':
        return navigateTo({
          name: 'catalog-collections-collection_id-settings-default',
          params: { collection_id: to.params.collection_id, ...to.params }
        })

      // projects
      case 'projects-project_id':
        return navigateTo({
          name: 'projects-project_id-collection-tasks',
          params: { project_id: to.params.project_id, ...to.params }
        })
      case 'projects-project_id-collection':
        return navigateTo({
          name: 'projects-project_id-collection-tasks',
          params: { project_id: to.params.project_id, ...to.params }
        })
      case 'projects-project_id-workforce':
        return navigateTo({
          name: 'projects-project_id-workforce-workers',
          params: { project_id: to.params.project_id, ...to.params }
        })
      case 'projects-project_id-workforce-workshops-workshop_id-assignments':
        return navigateTo({
          name: 'projects-project_id-workforce-workshops-workshop_id-assignments-category',
          params: {
            project_id: to.params.project_id,
            workshop_id: to.params.workshop_id,
            category: 'individual',
            ...to.params
          }
        })
      case 'projects-project_id-workforce-workshops-workshop_id-worker':
        return navigateTo({
          name: 'projects-project_id-workforce-workshops-workshop_id-worker-role',
          params: {
            project_id: to.params.project_id,
            workshop_id: to.params.workshop_id,
            role: 'labeler',
            ...to.params
          }
        })
      case 'projects-project_id-workforce-workshops-workshop_id':
        return navigateTo({
          name: 'projects-project_id-workforce-workshops-workshop_id-assignments-category',
          params: {
            project_id: to.params.project_id,
            workshop_id: to.params.workshop_id,
            category: 'individual',
            ...to.params
          }
        })
      case 'projects-project_id-settings':
        return navigateTo({
          name: 'projects-project_id-settings-default',
          params: { project_id: to.params.project_id }
        })
      // projects statistics
      case 'projects-project_id-statistics':
        return navigateTo({
          name: 'projects-project_id-statistics-worker',
          params: { project_id: to.params.project_id, ...to.params }
        })
      case 'projects-project_id-statistics-worker':
        return navigateTo({
          name: 'projects-project_id-statistics-worker-role-assignments',
          params: { project_id: to.params.project_id, role: 'labeler', assignments: 'assignment', ...to.params }
        })
      case 'projects-project_id-statistics-worker-role':
        return navigateTo({
          name: 'projects-project_id-statistics-worker-role-assignments',
          params: { project_id: to.params.project_id, role: to.params.role, assignments: 'assignment', ...to.params }
        })
      case 'projects-project_id-statistics-reject':
        return navigateTo({
          name: 'projects-project_id-statistics-reject-worker',
          params: { project_id: to.params.project_id, ...to.params }
        })

      // ml
      case 'ml':
        return navigateTo({ name: 'ml-models', params: to.params })
      // data-lab
      case 'data-lab-':
        return navigateTo({ name: 'data-lab-role', params: { role: 'labeler' } })
      case 'data-lab-role-project_id':
        return navigateTo({
          name: 'data-lab-role',
          params: { role: to.params.role }
        })
      case 'data-lab-role-project_id-workshop':
        return navigateTo({
          name: 'data-lab-role',
          params: { role: to.params.role }
        })
      case 'data-lab-role-project_id-workshop-workshop_id':
        return navigateTo({
          name: 'data-lab-role-project_id-workshop-workshop_id-assignments',
          params: { role: to.params.role, project_id: to.params.project_id, workshop_id: to.params.workshop_id }
        })
      case 'data-lab-role-project_id-workshop-workshop_id-assignments':
        return navigateTo({
          name: 'data-lab-role-project_id-workshop-workshop_id-assignments-tasks',
          params: { role: to.params.role, project_id: to.params.project_id, workshop_id: to.params.workshop_id }
        })
      case 'data-lab-role-project_id-workshop-workshop_id-statistics':
        return navigateTo({
          name: 'data-lab-role-project_id-workshop-workshop_id-statistics-task-assignments',
          params: {
            role: to.params.role,
            project_id: to.params.project_id,
            workshop_id: to.params.workshop_id,
            assignments: 'assignment'
          }
        })
      case 'data-lab-role-project_id-workshop-workshop_id-statistics-task':
        return navigateTo({
          name: 'data-lab-role-project_id-workshop-workshop_id-statistics-task-assignments',
          params: {
            role: to.params.role,
            project_id: to.params.project_id,
            workshop_id: to.params.workshop_id,
            assignments: 'assignment'
          }
        })
      case 'data-lab-role-project_id-workshop-workshop_id-statistics-quality':
        return navigateTo({
          name: 'data-lab-role-project_id-workshop-workshop_id-statistics-quality-qc-tags',
          params: {
            role: to.params.role,
            project_id: to.params.project_id,
            workshop_id: to.params.workshop_id
          }
        })
    }
  }
})
