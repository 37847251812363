import { upperFirst } from 'lodash-es'
import { useAPI } from '~/composables/shared/useAPI'
import { usePageMetaQuery } from '~/composables/shared/usePageMetaQuery'
import { COLLECTION_TEMPLATE_TO_COLOR, COLLECTION_TEMPLATE_TO_ICON_NAME } from '~/constants/catalog'

// TODO : 미완성 입니다. 필요한 api 를 더 추가해서 써야합니다
export const useCatalogMetaQuery = async () => {
  const api = useAPI()
  return await usePageMetaQuery(async (route, createPageMeta) => {
    const results = await Promise.all([
      route.params.collection_id &&
        api.project.project.list({
          params: {
            dataset: Number(route.params.collection_id)
          }
        }),
      route.params.collection_id &&
        api.catalog.dataset.retrieve({
          path: Number(route.params.collection_id)
        })
    ])

    const [projects, collection] = results || []

    return createPageMeta({
      headline: {
        value: collection?.id || '',
        favorite: {
          active: collection?.is_favorite,
          target: collection?.id,
          category: 'collection'
        }
      },
      title: collection?.name || '',
      description: collection?.description || '',
      badgeGroups: [
        {
          badges: [
            {
              variant: 'tint',
              color:
                COLLECTION_TEMPLATE_TO_COLOR[collection?.category.toUpperCase() as Uppercase<CollectionTemplate>] ||
                'slate',
              size: 'md',
              value: upperFirst(collection?.category || ''),
              avatarType: 'icon',
              avatarValue:
                COLLECTION_TEMPLATE_TO_ICON_NAME[collection?.category.toUpperCase() as Uppercase<CollectionTemplate>] ||
                ''
            }
          ]
        }
      ],
      select: {
        placeholder: `${projects?.count}개의 프로젝트와 연결됨`,
        options:
          projects?.results?.map((project) => ({
            label: project.title,
            value: String(project.id)
          })) || [],
        toPrefix: '/projects',
        outerClass: '!mb-0'
      },
      breadcrumbs: {
        collection_id: {
          label: collection?.name || ''
        }
      },
      directoryNavigation: {
        'data-units': {
          to: `/catalog/collections/${collection?.id}/data-units`,
          prefixIcon: 'heroicons:squares-2x2'
        },
        imports: { class: 'hidden' },
        settings: {
          prefixIcon: 'heroicons:cog-8-tooth',
          children: {
            default: {},
            schema: {},
            members: {},
            danger: {
              variant: 'destructive'
            }
          }
        }
      }
    })
  })
}
