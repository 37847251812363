import type {
  DatasetArchiveResData,
  DatasetCreateFavoritesResData,
  DatasetCreateResData,
  DatasetDAO,
  DatasetDependencyCountResData,
  DatasetDestroyFavoritesResData,
  DatasetDestroyResData,
  DatasetListResData,
  DatasetMyRoleResData,
  DatasetPartialUpdateResData,
  DatasetRetrieveResData,
  DatasetUpdateResData
} from '~/api/catalog/Dataset.types'
import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule, FavoriteModule } from '~/api/shared/core/modules'

export class DatasetAPI extends DAOBase implements DatasetDAO {
  private commonModule: CommonModule
  private favoriteModule: FavoriteModule
  private prefix = '/datasets'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
      favoriteModule: FavoriteModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
    this.favoriteModule = modules.favoriteModule
  }

  public readonly list: DatasetDAO['list'] = (args) =>
    this.commonModule.list<DatasetListResData>({ ...args, prefix: this.prefix })

  public readonly retrieve: DatasetDAO['retrieve'] = (args) =>
    this.commonModule.retrieve<DatasetRetrieveResData>({ ...args, prefix: this.prefix })

  public readonly create: DatasetDAO['create'] = (args) =>
    this.commonModule.create<DatasetCreateResData>({ ...args, prefix: this.prefix })

  public readonly update: DatasetDAO['update'] = (args) =>
    this.commonModule.update<DatasetUpdateResData>({ ...args, prefix: this.prefix })

  public readonly partialUpdate: DatasetDAO['partialUpdate'] = (args) =>
    this.commonModule.partialUpdate<DatasetPartialUpdateResData>({ ...args, prefix: this.prefix })

  public readonly destroy: DatasetDAO['destroy'] = (args) =>
    this.commonModule.destroy<DatasetDestroyResData>({ ...args, prefix: this.prefix })

  public readonly archive: DatasetDAO['archive'] = (args) => {
    return this.fetchBase.request<DatasetArchiveResData>(`${this.prefix}/${args.path}/archive/`, {
      ...args,
      method: 'POST'
    })
  }

  public readonly dependencyCount: DatasetDAO['dependencyCount'] = (args) => {
    return this.fetchBase.request<DatasetDependencyCountResData>(`${this.prefix}/${args.path}/dependency_count/`, {
      ...args
    })
  }

  public readonly createFavorites: DatasetDAO['createFavorites'] = (args) =>
    this.favoriteModule.createFavorites<DatasetCreateFavoritesResData>({ ...args, prefix: this.prefix })

  public readonly destroyFavorites: DatasetDAO['destroyFavorites'] = (args) =>
    this.favoriteModule.destroyFavorites<DatasetDestroyFavoritesResData>({ ...args, prefix: this.prefix })

  public readonly invite: DatasetDAO['invite'] = (args) => {
    return this.fetchBase.request<DatasetArchiveResData>(`${this.prefix}/${args.path}/invite/`, {
      ...args,
      method: 'POST'
    })
  }

  public readonly roles: DatasetDAO['roles'] = (args) => {
    return this.fetchBase.request<DatasetArchiveResData>(`${this.prefix}/${args.path}/roles/`, { ...args })
  }

  public readonly unarchive: DatasetDAO['unarchive'] = (args) => {
    return this.fetchBase.request<DatasetArchiveResData>(`${this.prefix}/${args.path}/unarchive/`, {
      ...args,
      method: 'POST'
    })
  }

  public readonly defaultRoles: DatasetDAO['defaultRoles'] = (args) => {
    return this.fetchBase.request<DatasetArchiveResData>(`${this.prefix}/default_roles/`, { ...args })
  }

  public readonly myRole: DatasetDAO['myRole'] = (args) => {
    return this.fetchBase.request<DatasetMyRoleResData>(`${this.prefix}/${args?.path}/my_role/`, { ...args })
  }
}
