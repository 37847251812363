export const FILE_TO_ICON_NAME: {
  [key: string]: string
} = {
  TEXT: 'annotator:text',
  IMAGE: 'annotator:image',
  PROJECTION_IMAGE: 'annotator:image',
  VIDEO: 'annotator:video',
  PCD: 'annotator:pcd',
  CALIB: 'heroicons:document',
  CAM_PARAM: 'heroicons:document-text',
  JSON: 'heroicons:document-text'
} as const

export const COLLECTION_TEMPLATE_TO_ICON_NAME: {
  [key: string]: string
} = {
  TEXT: 'annotator:text',
  IMAGE: 'annotator:image',
  VIDEO: 'annotator:video',
  PCD: 'annotator:pcd'
} as const

export const COLLECTION_TEMPLATE_TO_COLOR: {
  [key: string]: string
} = {
  TEXT: 'emerald',
  IMAGE: 'amber',
  VIDEO: 'rose',
  PCD: 'fuchsia'
}

export const DRAFT_TARGETS = {
  CREATE: 'collection-create'
} as const
