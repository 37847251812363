export default function optionsIcon(node) {
  function setIcons() {
    if (node.context && node.context.options) {
      node.context.options.forEach((option) => {
        if (option.icon) {
          loadIcon(option.icon).then((icon) => {
            option.iconSvg = icon
          })
        }
      })
    }
  }
  setIcons()
  node.on('created', setIcons)
}
