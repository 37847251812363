import type { UserTagDAO, UserTagRetrieveResData } from '~/api/account/UserTag.types'
import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule } from '~/api/shared/core/modules'

export class UserTagAPI extends DAOBase implements UserTagDAO {
  private commonModule: CommonModule
  private prefix = '/user_tags'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
  }

  public readonly retrieve: UserTagDAO['retrieve'] = (args) =>
    this.commonModule.retrieve<UserTagRetrieveResData>({ ...args, prefix: this.prefix })
}
