export default {
  wrapper: tw`
    h-fit w-fit rounded border border-slate-300 bg-white-950 hover:border-blue-600
    group-data-[disabled]:border-none`,
  label: tw`pointer-events-none`,
  inner: tw`
    flex h-10 w-14 
    group-data-[size=sm]:h-8 
    group-data-[size=sm]:w-12`,
  input: tw`
    $reset m-auto box-border h-6 w-10 cursor-pointer appearance-none overflow-clip rounded 
    group-data-[disabled]:pointer-events-none
    group-data-[size=sm]:h-5 
    group-data-[size=sm]:w-9 
    [&::-webkit-color-swatch-wrapper]:p-0 
    [&::-webkit-color-swatch]:border-none`,
  suffixIcon: tw`m-auto px-0`
}
