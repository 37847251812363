import { type FormKitBaseProps, type FormKitInputs } from '@formkit/inputs'
import type { RouteLocationNormalizedLoadedGeneric } from 'vue-router'
import type { DirectoryNavigationLinkItem } from '~/components/shared/directory-navigation/types'
import type { FavoriteButtonProps } from '~/components/shared/favorite-button/types'
import type { BadgeGroupProps } from '~/components/ui/badge-group/types'
import type { ButtonProps } from '~/components/ui/button/types'
import type { DropdownMenuItems } from '~/components/ui/dropdown-menu/types'
import type { MediaProps } from '~/components/ui/media/types'
import { usePrefetchQuery } from '~/composables/shared/usePrefetchQuery'
import { PAGE_META } from '~/constants/shared/query'

export interface PageMetaMenuItem extends DirectoryNavigationLinkItem {
  class?: string
  children?: {
    [menu: string]: PageMetaMenuItem
  }
}

export interface PageMetaQueryResult {
  headline?: {
    value: string | number
    favorite?: FavoriteButtonProps
  }
  category?: {
    value: CollectionTemplate
  }
  title: string
  description?: string
  image?: string
  created?: string
  media?: MediaProps
  badgeGroups?: BadgeGroupProps[]
  select?: Partial<FormKitInputs<FormKitBaseProps>> & {
    toPrefix?: string
  }
  link?: ButtonProps
  breadcrumbs?: {
    [slug: string]: {
      label: string
      items?: DropdownMenuItems[][]
    }
  }
  directoryNavigation?: {
    [menu: string]: PageMetaMenuItem
  }
}

export const usePageMetaQuery = async (
  queryFn: (
    route: RouteLocationNormalizedLoadedGeneric,
    createPageMeta: (args: PageMetaQueryResult) => PageMetaQueryResult
  ) => Promise<PageMetaQueryResult>
) => {
  const route = useRoute()

  const query = await usePrefetchQuery<PageMetaQueryResult>({
    queryKey: [PAGE_META.primaryKey, route.path, route.params],
    queryFn: () => queryFn(route, createPageMeta),
    ...PAGE_META.config
  })

  return query
}

export const createPageMeta = (args: PageMetaQueryResult) => args
