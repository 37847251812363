import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule } from '~/api/shared/core/modules'
import type {
  GroundTruthDatasetVersionDAO,
  GroundTruthDatasetVersionsCreateResData,
  GroundTruthDatasetVersionsExportsCreateResData,
  GroundTruthDatasetVersionsExportsListResData,
  GroundTruthDatasetVersionsListResData,
  GroundTruthDatasetVersionsRetrieveResData
} from '~/api/shared/ground-truth/GroundTruthDatasetVersion.types'

export class GroundTruthDatasetVersionAPI extends DAOBase implements GroundTruthDatasetVersionDAO {
  private commonModule: CommonModule
  private prefix = '/ground_truth_dataset_versions'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
  }

  public readonly list: GroundTruthDatasetVersionDAO['list'] = (args) =>
    this.commonModule.list<GroundTruthDatasetVersionsListResData>({ prefix: this.prefix, ...args })

  public readonly retrieve: GroundTruthDatasetVersionDAO['retrieve'] = (args) =>
    this.commonModule.retrieve<GroundTruthDatasetVersionsRetrieveResData>({ prefix: this.prefix, ...args })

  public readonly create: GroundTruthDatasetVersionDAO['create'] = (args) =>
    this.commonModule.create<GroundTruthDatasetVersionsCreateResData>({ prefix: this.prefix, ...args })

  public readonly exportList: GroundTruthDatasetVersionDAO['exportList'] = (args) =>
    this.commonModule.list<GroundTruthDatasetVersionsExportsListResData>({
      prefix: '/ground_truth_dataset_version_exports',
      ...args
    })

  public readonly runExport: GroundTruthDatasetVersionDAO['runExport'] = (args) =>
    this.commonModule.create<GroundTruthDatasetVersionsExportsCreateResData>({
      prefix: '/ground_truth_dataset_version_exports/run_export',
      ...args
    })
}
