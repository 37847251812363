<template>
  <div class="relative w-full rounded" :class="Object.keys(context.messages).length ? 'border border-red-600' : ''">
    <ckeditor
      v-if="editor"
      v-model="modelValue"
      :config="editorConfig"
      :editor
      @input="handleInput"
      @ready="uploadAdapterPlugin" />
    <div v-if="context.disabled" class="absolute inset-0 bg-slate-100 opacity-25" />
  </div>
</template>

<script setup>
import { useCKEditor } from '~/composables/shared/useCKEditor'
import { useTokenCookies } from '~/composables/shared/useTokenCookies'
import { TOKEN } from '~/constants/shared'
import { toolbarPresets } from '.'

const props = defineProps({
  context: {
    type: Object,
    required: true
  }
})

const editor = ref(null)

const modelValue = ref(props.context._value || '')
const handleInput = (value) => {
  if (!props.context.disabled) {
    props.context.node.input(value)
  }
}
let toolbar = toolbarPresets.default
if (props.context.toolbar.length) toolbar = props.context.toolbar
else if (toolbarPresets?.[props.context.preset]) toolbar = toolbarPresets[props.context.preset]
const editorConfig = {
  language: 'ko',
  toolbar
}

if (props.context.disabled) {
  editorConfig.title = { placeholder: ' ' }
  editorConfig.placeholder = ' '
}

const uploadAdapterPlugin = (editor) => {
  editor.plugins.get('FileRepository').createUploadAdapter = function (loader) {
    return new UploadAdapter(loader)
  }
}

class UploadAdapter {
  constructor(loader) {
    this.loader = loader
  }

  upload() {
    return this.loader.file.then(
      (file) =>
        new Promise((resolve, reject) => {
          this._initRequest()
          this._initListeners(resolve, reject, file)
          this._sendRequest(file)
        })
    )
  }

  abort() {
    if (this.xhr) {
      this.xhr.abort()
    }
  }

  _initRequest() {
    const xhr = (this.xhr = new XMLHttpRequest())
    const config = useRuntimeConfig()
    const baseUrl = config.public.apiUrl
    const { cookie } = useTokenCookies()
    xhr.open('POST', `${baseUrl}/ckeditor5/image_upload/`, true)
    xhr.setRequestHeader('Authorization', `Token ${cookie.get(TOKEN.AUTH.value)}`)
    xhr.responseType = 'json'
  }

  _initListeners(resolve, reject, file) {
    const { xhr } = this
    const { loader } = this
    const genericErrorText = `Couldn't upload file: ${file.name}.`
    xhr.addEventListener('error', () => reject(genericErrorText))
    xhr.addEventListener('abort', () => reject())
    xhr.addEventListener('load', () => {
      const { response } = xhr
      if (!response || response.error) {
        return reject(response && response.error ? response.error.message : genericErrorText)
      }
      resolve({
        default: response.url
      })
    })
    if (xhr.upload) {
      xhr.upload.addEventListener('progress', (evt) => {
        if (evt.lengthComputable) {
          loader.uploadTotal = evt.total
          loader.uploaded = evt.loaded
        }
      })
    }
  }

  _sendRequest(file) {
    const data = new FormData()
    data.append('upload', file)
    this.xhr.send(data)
  }
}

onMounted(() => {
  editor.value = useCKEditor()
})
</script>

<style scoped>
:deep(.ck-editor__main *) {
  all: revert;
}

:deep(.ck-content) {
  @apply min-h-52;
}
</style>
