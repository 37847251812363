import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule } from '~/api/shared/core/modules'
import type {
  TaskTagDAO,
  TaskTagsCreateResData,
  TaskTagsDestroyResData,
  TaskTagsListResData,
  TaskTagsPartialUpdateResData,
  TaskTagsRetrieveResData,
  TaskTagsUpdateResData
} from './TaskTag.types'

export class TaskTagAPI extends DAOBase implements TaskTagDAO {
  private commonModule: CommonModule
  private prefix = '/task_tags'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
  }

  public readonly list: TaskTagDAO['list'] = (args) =>
    this.commonModule.list<TaskTagsListResData>({ prefix: this.prefix, ...args })

  public readonly retrieve: TaskTagDAO['retrieve'] = (args) =>
    this.commonModule.retrieve<TaskTagsRetrieveResData>({ prefix: this.prefix, ...args })

  public readonly create: TaskTagDAO['create'] = (args) =>
    this.commonModule.create<TaskTagsCreateResData>({ prefix: this.prefix, ...args })

  public readonly update: TaskTagDAO['update'] = (args) =>
    this.commonModule.update<TaskTagsUpdateResData>({ prefix: this.prefix, ...args })

  public readonly partialUpdate: TaskTagDAO['partialUpdate'] = (args) =>
    this.commonModule.partialUpdate<TaskTagsPartialUpdateResData>({ prefix: this.prefix, ...args })

  public readonly destroy: TaskTagDAO['destroy'] = (args) =>
    this.commonModule.destroy<TaskTagsDestroyResData>({ prefix: this.prefix, ...args })
}
