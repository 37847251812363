export default {
  outer: tw`group min-w-0 max-w-[20em] grow text-base data-[disabled]:pointer-events-none data-[disabled]:cursor-not-allowed data-[disabled]:select-none`,
  wrapper: tw`mb-1.5 flex flex-col items-start justify-start last:mb-0`,
  inner: tw`flex h-10 w-14 rounded border border-slate-300 bg-white-950 hover:border-blue-600 group-data-[size=sm]:h-8 
    group-data-[size=sm]:w-12 
    group-data-[disabled]:border-none`,
  label: tw`$reset`,
  swatchPreview: tw`flex w-full cursor-pointer items-center justify-start rounded text-sm outline-none`,
  canvasSwatchPreviewWrapper: tw`m-auto`,
  canvasSwatchPreview: tw`box-border h-6 w-10 cursor-pointer appearance-none overflow-clip rounded 
    group-data-[disabled]:pointer-events-none 
    group-data-[size=sm]:h-5 
    group-data-[size=sm]:w-9 
    [&::-webkit-color-swatch-wrapper]:p-0 
    [&::-webkit-color-swatch]:border-none`,
  valueString: tw`ml-2 mr-1.5 inline-block font-mono text-base selection:bg-blue-100`,
  swatch: tw`relative m-[0.16em] w-[calc((100%/10)-0.5em)] max-w-[22px] cursor-pointer rounded text-base before:pointer-events-none before:absolute before:inset-0 before:z-[2] before:rounded before:shadow-[inset_0_0_0_1px_rgba(0,0,0,0.2)] before:content-[''] data-[active=true]:after:pointer-events-none data-[active=true]:after:absolute data-[active=true]:after:left-1/2 data-[active=true]:after:top-1/2 data-[active=true]:after:z-[2] data-[active=true]:after:block data-[active=true]:after:h-1.5 data-[active=true]:after:w-1.5 data-[active=true]:after:-translate-x-1/2 data-[active=true]:after:-translate-y-1/2 data-[active=true]:after:rounded-full data-[active=true]:after:bg-white-950 data-[active=true]:after:ring-1 data-[active=true]:after:ring-black-400 data-[active=true]:after:content-['']`,
  swatches: tw`mx-auto -mb-2 mt-2 inline-flex max-h-[200px] w-full min-w-0 select-none flex-wrap justify-self-center overflow-auto border-t px-[1px] pb-2 pt-2 first:-mt-[3px] first:border-t-0 first:pt-px first:last:-mb-[3px] first:last:pb-[2px]`,
  swatchCanvas: tw`rounded`,
  panel: tw`absolute z-[99] flex w-[100vw] max-w-[18.5em] translate-y-10 touch-manipulation flex-col rounded border border-slate-500 bg-white-950 p-2 shadow-md group-data-[inline]:group-data-[disabled]:!pointer-events-none 
    group-data-[inline]:static 
    group-data-[inline]:z-auto 
    group-data-[inline]:w-full 
    group-data-[inline]:max-w-none 
    group-data-[size=sm]:translate-y-8 
    group-data-[inline]:group-data-[disabled]:!cursor-not-allowed group-data-[inline]:shadow`
}
