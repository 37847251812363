import type { FormKitNode } from '@formkit/core'
import { findSection } from '@formkit/inputs'

export function verificationPlugin(node: FormKitNode) {
  if (node.props.type !== 'iamport') return

  const schemaFn = node.props.definition.schema
  node.props.definition.schema = (sectionsSchema = {}) => {
    const definitionSchema = schemaFn(sectionsSchema)
    const outerSection = findSection(definitionSchema, 'outer')[1].else
    const wrapperSection = findSection(outerSection.children, 'wrapper')[1].else
    wrapperSection.children.splice(0, 1) // label section 제거
    return definitionSchema
  }
}
