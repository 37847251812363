import { cva } from 'class-variance-authority'

export { default as Spinner } from './Spinner.vue'

export const spinnerVariants = cva('relative', {
  variants: {
    size: {
      md: 'h-8 w-8',
      sm: 'h-4 w-4'
    }
  }
})

export const spinnerFillVariants = cva(
  "h-full w-full animate-spin rounded-full bg-gradient-conic from-transparent before:absolute before:left-[12.5%] before:top-[12.5%] before:h-3/4 before:w-3/4 before:rounded-full before:bg-white-950 before:content-[''] after:absolute after:left-0 after:left-[45%] after:top-0 after:h-[12.5%] after:w-[12.5%] after:rounded-full after:content-['']",
  {
    variants: {
      color: {
        slate: 'to-slate-500 after:bg-slate-500',
        blue: 'to-blue-500 after:bg-blue-500'
      }
    }
  }
)
