export function inputSizePlugin(node) {
  node.addProps({
    size: {
      default: 'md'
    }
  })

  node.on('created', () => {
    node.props.definition.schemaMemoKey = `data-size_${node.props.definition.schemaMemoKey}`

    const schemaFn = node.props.definition.schema
    node.props.definition.schema = (sectionsSchema = {}) => {
      sectionsSchema.outer = {
        attrs: {
          'data-size': '$size'
        }
      }
      return schemaFn(sectionsSchema)
    }
  })
}
