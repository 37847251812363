<template>
  <div :class="cn('flex flex-wrap gap-1', $attrs.class ?? '')">
    <template v-for="(badge, i) in badges.slice(0, maxCount)" :key="i">
      <Badge
        v-if="badge.value"
        :size
        :color
        :variant
        :ui
        v-bind="badge"
        :value="truncateText(badge.value, maxLength)"
        :class="badgeGroupVariant({ size })" />
    </template>

    <Badge v-if="badges.length > maxCount" :size :color :variant :class="badgeGroupVariant({ size })">
      <Icon name="heroicons:ellipsis-horizontal" />
    </Badge>
    <slot />
  </div>
</template>

<script setup lang="ts">
import { cn } from '~/lib/utils'
import { badgeGroupVariant } from '.'
import type { BadgeGroupProps } from './types'

withDefaults(defineProps<BadgeGroupProps>(), {
  size: 'md',
  color: 'slate',
  variant: 'tint',
  maxCount: 7,
  maxLength: 6
})

function truncateText(text: string, maxLength: number) {
  if (text.length > maxLength) {
    return text.substring(0, maxLength) + '...'
  }
  return text
}
</script>
