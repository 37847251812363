import dayjs from 'dayjs'
import { useAPI } from '~/composables/shared/useAPI'
import { usePageMetaQuery, type PageMetaQueryResult } from '~/composables/shared/usePageMetaQuery'
import { ANNOTATOR_CATEGORY_COLOR, ANNOTATOR_TASK_ICON } from '~/constants/project'

export const useMLMetaQuery = async () => {
  const api = useAPI()

  return await usePageMetaQuery(async (route, createPageMeta) => {
    const results = await Promise.all([
      route.params.job_id &&
        api.machineLearning.job.retrieve({
          path: String(route.params.job_id)
        }),
      route.params.model_id &&
        api.machineLearning.model.retrieve({
          path: String(route.params.model_id)
        }),
      route.params.experiment_id &&
        api.machineLearning.experiment.retrieve({
          path: String(route.params.experiment_id)
        })
    ])

    const [job, model, experiment] = results || []

    const pageMetaMap: PageMetaQueryResult = {
      title: '',
      breadcrumbs: {
        job_id: {
          label: job?.params?.name || 'job_id'
        },
        model_id: {
          label: model?.name || 'model_id'
        },
        experiment_id: {
          label: experiment?.name || 'experiment_id'
        }
      },
      directoryNavigation: {}
    }

    if (model) {
      Object.assign(pageMetaMap, {
        headline: {
          value: `${model?.category_display.toUpperCase()} MODEL` || ''
        },
        title: model?.name || '',
        created: dayjs(model?.created).format('YYYY-MM-DD HH:mm:ss'),
        media: {
          thumbnail: model?.created_by?.thumbnail,
          title: model?.created_by?.name
        },
        image: '/synapse.png',
        badgeGroups: [
          {
            badges: model?.tasks.map((task) => ({
              avatarType: 'icon',
              avatarValue: ANNOTATOR_TASK_ICON[task.data_type?.toUpperCase() as keyof typeof ANNOTATOR_TASK_ICON],
              value: task.name_display,
              color:
                ANNOTATOR_CATEGORY_COLOR[task.data_type?.toUpperCase() as keyof typeof ANNOTATOR_CATEGORY_COLOR] ||
                'slate'
            }))
          },
          {
            badges: model?.tasks.map((task) => ({
              value: task.data_type
            }))
          }
        ]
      })
    } else if (experiment) {
      Object.assign(pageMetaMap, {
        headline: {
          value: experiment?.id || '',
          favorite: {
            active: experiment?.is_favorite,
            target: experiment?.id,
            category: 'experiment'
          }
        },
        title: experiment?.name || '',
        description: experiment?.description || '',
        image: '/synapse.png',

        badgeGroups: [
          {
            badges: [
              {
                avatarType: 'icon',
                avatarValue:
                  ANNOTATOR_TASK_ICON[experiment?.task?.data_type?.toUpperCase() as keyof typeof ANNOTATOR_TASK_ICON] ||
                  '',
                value: experiment?.task?.name_display || '',
                color:
                  ANNOTATOR_CATEGORY_COLOR[
                    experiment?.task?.data_type?.toUpperCase() as keyof typeof ANNOTATOR_CATEGORY_COLOR
                  ] || 'slate'
              }
            ]
          }
        ]
      })
    }

    return createPageMeta(pageMetaMap)
  })
}
