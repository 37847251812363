import type { AuthTokenDAO, AuthTokenLoginReqArgsResData } from '~/api/auth/types'
import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'

export class AuthTokenAPI extends DAOBase implements AuthTokenDAO {
  private prefix = '/users'

  public login: AuthTokenDAO['login'] = (args) => {
    const response = this.fetchBase.request<AuthTokenLoginReqArgsResData>(`${this.prefix}/login/`, {
      ...args,
      method: 'POST'
    })
    return response
  }
}
