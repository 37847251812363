import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import type { ProjectSettingModuleDAO } from '~/api/shared/core/modules/types'
import type { ExtractModuleParams } from '~/api/shared/core/types'

export class ProjectSettingModule extends DAOBase implements ProjectSettingModuleDAO {
  public readonly queuePosition = <TReturn>({
    prefix,
    path,
    options
  }: ExtractModuleParams<ProjectSettingModuleDAO['queuePosition']>): Promise<unknown> => {
    const response = this.fetchBase.request<TReturn>(`${prefix}/${path}/queue_position/`, {
      ...options,
      method: 'GET'
    })
    return response
  }

  public readonly outFromQueue = <TReturn>({
    prefix,
    path,
    body,
    options
  }: ExtractModuleParams<ProjectSettingModuleDAO['outFromQueue']>): Promise<unknown> => {
    const response = this.fetchBase.request<TReturn>(`${prefix}/${path}/out_from_queue/`, {
      ...options,
      method: 'POST',
      body
    })
    return response
  }

  public readonly join = <TReturn>({
    prefix,
    path,
    body,
    options
  }: ExtractModuleParams<ProjectSettingModuleDAO['join']>): Promise<unknown> => {
    const response = this.fetchBase.request<TReturn>(`${prefix}/${path}/join`, {
      ...options,
      method: 'POST',
      body
    })
    return response
  }

  public readonly participate = <TReturn>({
    prefix,
    path,
    body,
    options
  }: ExtractModuleParams<ProjectSettingModuleDAO['participate']>): Promise<unknown> => {
    const response = this.fetchBase.request<TReturn>(`${prefix}/${path}/participate`, {
      ...options,
      method: 'POST',
      body
    })
    return response
  }
}
