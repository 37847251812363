import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import type { StatisticsModuleDAO } from '~/api/shared/core/modules/types'
import type { ExtractModuleParams } from '~/api/shared/core/types'

export class StatisticsModule extends DAOBase implements StatisticsModuleDAO {
  public readonly statistics = <TReturn>({
    prefix,
    path,
    options
  }: ExtractModuleParams<StatisticsModuleDAO['statistics']>): Promise<TReturn> => {
    const response = this.fetchBase.request<TReturn>(`${prefix}/${path}/statistics/`, options)
    return response
  }

  public readonly createStatistics = <TReturn>({
    prefix,
    path,
    body,
    options
  }: ExtractModuleParams<StatisticsModuleDAO['createStatistics']>): Promise<TReturn> => {
    const response = this.fetchBase.request<TReturn>(`${prefix}/${path}/statistics/`, {
      ...options,
      method: 'POST',
      body
    })
    return response
  }
}
