import * as AccountAPIs from '~/api/account'
import * as AuthAPIs from '~/api/auth'
import * as MachineLearningAPIs from '~/api/machine-learning'
import * as ProjectAPIs from '~/api/project'
import * as BoardAPIs from '~/api/shared/board'
import * as APIFetchBases from '~/api/shared/core/fetch-base'
import * as APIModules from '~/api/shared/core/modules'
import * as TutorialAPIs from '~/api/shared/tutorial'
import * as UtilAPIs from '~/api/shared/utils'
import * as WorkerAPIs from '~/api/worker'
import { useTokenCookies } from '~/composables/shared/useTokenCookies'
import { FETCH_OPTIONS } from '~/constants/shared'
import { type DAOModules, unfoldModule } from '~/utils/api'
import * as CatalogueAPIs from '../api/catalog'
import * as GroundTruthAPIs from '../api/shared/ground-truth'

const createDAO = ({
  defaultFetchInstance,
  modules
}: {
  defaultFetchInstance: APIFetchBases.DefaultFetchBase
  modules: DAOModules
}) => {
  return {
    account: {
      region: new AccountAPIs.RegionAPI(defaultFetchInstance, {
        commonModule: modules.common
      }),
      user: new AccountAPIs.UserAPI(defaultFetchInstance, {
        commonModule: modules.common
      }),
      userEmail: new AccountAPIs.UserEmailAPI(defaultFetchInstance, { commonModule: modules.common }),
      userTag: new AccountAPIs.UserTagAPI(defaultFetchInstance, { commonModule: modules.common }),
      verficationMethod: new AccountAPIs.VerificationMethodAPI(defaultFetchInstance, { commonModule: modules.common })
    },
    auth: {
      authToken: new AuthAPIs.AuthTokenAPI(defaultFetchInstance, { commonModule: modules.common })
    },
    catalog: {
      dataFile: new CatalogueAPIs.DataFileAPI(defaultFetchInstance, { commonModule: modules.common }),
      dataGroup: new CatalogueAPIs.DataGroupAPI(defaultFetchInstance, { commonModule: modules.common }),
      dataGroupLabeler: new CatalogueAPIs.DataGroupLabelerAPI(defaultFetchInstance, { commonModule: modules.common }),
      dataGroupReviewer: new CatalogueAPIs.DataGroupReviewerAPI(defaultFetchInstance, { commonModule: modules.common }),
      dataset: new CatalogueAPIs.DatasetAPI(defaultFetchInstance, {
        commonModule: modules.common,
        favoriteModule: modules.favorite
      }),
      dataUnit: new CatalogueAPIs.DataUnitAPI(defaultFetchInstance, {
        commonModule: modules.common,
        bulkDestroyModule: modules.bulkDestroy
      }),
      dataUnitLabeler: new CatalogueAPIs.DataUnitFileAPI(defaultFetchInstance, { commonModule: modules.common })
    },
    machineLearning: {
      agent: new MachineLearningAPIs.AgentAPI(defaultFetchInstance, { commonModule: modules.common }),
      agentProxy: new MachineLearningAPIs.AgentProxyAPI(defaultFetchInstance, { commonModule: modules.common }),
      model: new MachineLearningAPIs.ModelAPI(defaultFetchInstance, { commonModule: modules.common }),
      plugin: new MachineLearningAPIs.PluginAPI(defaultFetchInstance, { commonModule: modules.common }),
      pluginRelease: new MachineLearningAPIs.PluginReleaseAPI(defaultFetchInstance, { commonModule: modules.common }),
      prediction: new MachineLearningAPIs.PredictionAPI(defaultFetchInstance, { commonModule: modules.common }),
      script: new MachineLearningAPIs.ScriptAPI(defaultFetchInstance, { commonModule: modules.common }),
      storage: new MachineLearningAPIs.StorageAPI(defaultFetchInstance, { commonModule: modules.common }),
      task: new MachineLearningAPIs.TaskAPI(defaultFetchInstance, { commonModule: modules.common }),
      log: new MachineLearningAPIs.LogAPI(defaultFetchInstance, { commonModule: modules.common }),
      trainData: new MachineLearningAPIs.TrainDataAPI(defaultFetchInstance, { commonModule: modules.common }),
      modelTask: new MachineLearningAPIs.ModelTaskAPI(defaultFetchInstance, { commonModule: modules.common }),
      job: new MachineLearningAPIs.JobAPI(defaultFetchInstance, { commonModule: modules.common }),
      experiment: new MachineLearningAPIs.ExperimentAPI(defaultFetchInstance, {
        commonModule: modules.common,
        favoriteModule: modules.favorite
      }),
      train: new MachineLearningAPIs.TrainAPI(defaultFetchInstance, { commonModule: modules.common })
    },
    project: {
      allocation: new ProjectAPIs.AllocationAPI(defaultFetchInstance, { commonModule: modules.common }),
      allocationLabeler: new ProjectAPIs.AllocationLabelerAPI(defaultFetchInstance, { commonModule: modules.common }),
      allocationReviewer: new ProjectAPIs.AllocationReviewerAPI(defaultFetchInstance, { commonModule: modules.common }),
      assignmentDataGroups: new ProjectAPIs.AssignmentDataGroupsAPI(defaultFetchInstance, {
        commonModule: modules.common
      }),
      assignment: new ProjectAPIs.AssignmentAPI(defaultFetchInstance, {
        commonModule: modules.common,
        bulkDestroyModule: modules.bulkDestroy,
        favoriteModule: modules.favorite
      }),
      assignmentLabeler: new ProjectAPIs.AssignmentLabelerAPI(defaultFetchInstance, {
        commonModule: modules.common,
        assignmentModule: modules.assignment,
        fileModule: modules.file
      }),
      assignmentReviewer: new ProjectAPIs.AssignmentReviewerAPI(defaultFetchInstance, {
        commonModule: modules.common,
        assignmentModule: modules.assignment,
        fileModule: modules.file
      }),
      task: new ProjectAPIs.TaskAPI(defaultFetchInstance, {
        commonModule: modules.common,
        fileModule: modules.file,
        bulkDestroyModule: modules.bulkDestroy,
        tagActionModule: modules.tagAction
      }),
      labelParticipation: new ProjectAPIs.LabelParticipationAPI(defaultFetchInstance, {
        commonModule: modules.common,
        bulkDestroyModule: modules.bulkDestroy
      }),
      taskTag: new ProjectAPIs.TaskTagAPI(defaultFetchInstance, { commonModule: modules.common }),
      project: new ProjectAPIs.ProjectAPI(defaultFetchInstance, {
        commonModule: modules.common,
        favoriteModule: modules.favorite,
        statisticsModule: modules.statistics
      }),
      projectLabeler: new ProjectAPIs.ProjectLabelerAPI(defaultFetchInstance, {
        commonModule: modules.common,
        statisticsModule: modules.statistics,
        projectSettingModule: modules.projectSetting
      }),
      projectReviewer: new ProjectAPIs.ProjectReviewerAPI(defaultFetchInstance, {
        commonModule: modules.common,
        statisticsModule: modules.statistics,
        projectSettingModule: modules.projectSetting
      }),
      review: new ProjectAPIs.ReviewAPI(defaultFetchInstance, { commonModule: modules.common }),
      reviewParticipation: new ProjectAPIs.ReviewParticipationAPI(defaultFetchInstance, {
        commonModule: modules.common,
        bulkDestroyModule: modules.bulkDestroy
      }),
      reviewReviewer: new ProjectAPIs.ReviewReviewerAPI(defaultFetchInstance, { commonModule: modules.common }),
      reviewTag: new ProjectAPIs.ReviewTagAPI(defaultFetchInstance, { commonModule: modules.common }),
      stage: new ProjectAPIs.StageAPI(defaultFetchInstance, { commonModule: modules.common }),
      workshop: new ProjectAPIs.WorkshopAPI(defaultFetchInstance, { commonModule: modules.common }),
      workshopLabeler: new ProjectAPIs.WorkshopLabelerAPI(defaultFetchInstance, { commonModule: modules.common }),
      workshopReviewer: new ProjectAPIs.WorkshopReviewerAPI(defaultFetchInstance, { commonModule: modules.common })
    },
    board: {
      comment: new BoardAPIs.CommentAPI(defaultFetchInstance, { commonModule: modules.common }),
      faq: new BoardAPIs.FAQAPI(defaultFetchInstance, { commonModule: modules.common }),
      popup: new BoardAPIs.PopupAPI(defaultFetchInstance, { commonModule: modules.common }),
      post: new BoardAPIs.PostAPI(defaultFetchInstance, { commonModule: modules.common }),
      postCategory: new BoardAPIs.PostCategoryAPI(defaultFetchInstance, { commonModule: modules.common })
    },
    groundTruth: {
      groundTruth: new GroundTruthAPIs.GroundTruthAPI(defaultFetchInstance, {
        commonModule: modules.common,
        bulkDestroyModule: modules.bulkDestroy
      }),
      groundTruthEvent: new GroundTruthAPIs.GroundTruthEventAPI(defaultFetchInstance, { commonModule: modules.common }),
      groundTruthDataset: new GroundTruthAPIs.GroundTruthDatasetAPI(defaultFetchInstance, {
        commonModule: modules.common
      }),
      groundTruthDatasetVersion: new GroundTruthAPIs.GroundTruthDatasetVersionAPI(defaultFetchInstance, {
        commonModule: modules.common
      })
    },
    tutorial: {
      calculationTemplate: new TutorialAPIs.CalculationTemplateAPI(defaultFetchInstance, {
        commonModule: modules.common
      }),
      courseLabeler: new TutorialAPIs.CourseLabelerAPI(defaultFetchInstance, { commonModule: modules.common })
    },
    utils: {
      CKEditorUpload: new UtilAPIs.CKEditorUploadAPI(defaultFetchInstance, { commonModule: modules.common }),
      modelTemplate: new UtilAPIs.ModelTemplateAPI(defaultFetchInstance, { commonModule: modules.common }),
      draft: new UtilAPIs.DraftAPI(defaultFetchInstance, { commonModule: modules.common })
    },
    worker: {
      worker: new WorkerAPIs.WorkerAPI(defaultFetchInstance, {
        commonModule: modules.common,
        bulkDestroyModule: modules.bulkDestroy
      }),
      member: new WorkerAPIs.MemberAPI(defaultFetchInstance, {
        commonModule: modules.common,
        bulkDestroyModule: modules.bulkDestroy
      }),
      tenant: new WorkerAPIs.TenantAPI(defaultFetchInstance, { commonModule: modules.common }),
      memberRole: new WorkerAPIs.MemberRoleAPI(defaultFetchInstance, {
        commonModule: modules.common,
        bulkDestroyModule: modules.bulkDestroy
      }),
      workerPost: new WorkerAPIs.WorkerPostAPI(defaultFetchInstance, {
        commonModule: modules.common,
        bulkDestroyModule: modules.bulkDestroy
      }),
      workerPostLabeler: new WorkerAPIs.WorkerPostLabelerAPI(defaultFetchInstance, { commonModule: modules.common }),
      workerPostReviewer: new WorkerAPIs.WorkerPostReviewerAPI(defaultFetchInstance, {
        commonModule: modules.common
      })
    }
  }
}

export type DAO = ReturnType<typeof createDAO>

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig()

  const temp = useTokenCookies()

  const headers = {}

  if (temp.token.auth) headers.Authorization = `Token ${temp.token.auth}`
  if (temp.token.tenant) headers['Synapse-Tenant'] = `Token ${temp.token.tenant}`

  const options = {
    baseURL: config.public.apiUrl,
    headers,
    timeout: FETCH_OPTIONS.timeout
  }

  const fetchInstance = {
    instance: $fetch.create(options),
    options
  }

  const defaultFetchInstance = new APIFetchBases.DefaultFetchBase(fetchInstance)
  const modules = unfoldModule(APIModules, defaultFetchInstance)

  const DAO = createDAO({
    defaultFetchInstance,
    modules
  })

  return {
    provide: {
      api: DAO
    }
  }
})
