/**
 * input event handler : 대표 체크박스와 하위 체크박스들간의 체크 로직
 * submit hook handler : 대표 체크박스의 value 값을 payload에서 제거하는 로직
 */
export default function nestedCheckboxes(node) {
  node.on('input', () => {
    node.props.options?.forEach((option) => {
      if (option.options) {
        const nestedValues = option.options
          .filter((option) => !option.attrs?.disabled || node._value.includes(option.value))
          .map((option) => option.value)
        if (node._value.includes(option.label) && !node.value.includes(option.label))
          node._value.push(
            ...option.options
              .filter((option) => !option.attrs?.disabled && !node._value.includes(option.value))
              .map((option) => option.value)
          )
        else if (!node._value.includes(option.label) && node.value.includes(option.label))
          node._value = node._value.filter((value) => !nestedValues.includes(value))
        const nestedCheckedValues = node._value.filter((value) => nestedValues.includes(value))
        if (nestedValues.length === nestedCheckedValues.length && !node._value.includes(option.label))
          node._value.push(option.label)
        else if (nestedValues.length !== nestedCheckedValues.length)
          node._value = node._value.filter((value) => value !== option.label)
        option.partiallyChecked = nestedCheckedValues.length > 0 && nestedCheckedValues.length < nestedValues.length
      }
    })
  })

  // TODO : 추후 해당 로직 또한 input 이벤트 로직 안에 포함되도록 고려
  if (node.root.props.type === 'form') {
    node.root.hook.submit((payload, next) => {
      if (Array.isArray(node._value)) {
        payload[node.name] = node._value.filter(
          (value) =>
            !node.props.options
              .filter((option) => option?.options)
              .map((option) => option.label)
              .includes(value)
        )
      }
      return next(payload)
    })
  }
}
