import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule } from '~/api/shared/core/modules'
import type { CalculationTemplateDAO } from '~/api/shared/tutorial/types'

export class CalculationTemplateAPI extends DAOBase implements CalculationTemplateDAO {
  private commonModule: CommonModule
  private prefix = '/calculation_templates'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
  }

  public readonly list: CalculationTemplateDAO['list'] = ({ options }) =>
    this.commonModule.list<unknown>({ prefix: this.prefix, options })

  public readonly retrieve: CalculationTemplateDAO['retrieve'] = ({ id, options }) =>
    this.commonModule.retrieve<unknown>({ prefix: this.prefix, id, options })

  public readonly create: CalculationTemplateDAO['create'] = ({ body, options }) =>
    this.commonModule.create<unknown>({ prefix: this.prefix, body, options })

  public readonly update: CalculationTemplateDAO['update'] = ({ id, body, options }) =>
    this.commonModule.update<unknown>({ prefix: this.prefix, id, body, options })

  public readonly partialUpdate: CalculationTemplateDAO['partialUpdate'] = ({ id, body, options }) =>
    this.commonModule.partialUpdate<unknown>({ prefix: this.prefix, id, body, options })

  public readonly destroy: CalculationTemplateDAO['destroy'] = ({ id, body, options }) =>
    this.commonModule.destroy<unknown>({ prefix: this.prefix, id, body, options })
}
