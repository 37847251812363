import type {
  UserChangeEmailResData,
  UserChangeImageResData,
  UserCheckPasswordResData,
  UserCreateResData,
  UserDAO,
  UserDestroyMeResData,
  UserFindEmailResData,
  UserPartialUpdateAddTagsResData,
  UserPartialUpdateMeResData,
  UserResetPasswordConfirmResData,
  UserResetPasswordResData,
  UserRetrieveMeResData,
  UserSearchResData,
  UserUpdateAddTagsResData,
  UserUpdateMeResData,
  UserUpdatePasswordResData,
  UserVerifyEmailResData,
  UserVerifyResData
} from '~/api/account/User.types'
import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule } from '~/api/shared/core/modules'

export class UserAPI extends DAOBase implements UserDAO {
  private commonModule: CommonModule
  private prefix = '/users'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
  }

  public readonly create: UserDAO['create'] = (args) =>
    this.commonModule.create<UserCreateResData>({ ...args, prefix: this.prefix })

  public readonly retrieveMe: UserDAO['retrieveMe'] = (args) => {
    const response = this.fetchBase.request<UserRetrieveMeResData>(`${this.prefix}/me/`, { ...args })
    return response
  }

  public readonly updateMe: UserDAO['updateMe'] = (args) => {
    const response = this.fetchBase.request<UserUpdateMeResData>(`${this.prefix}/me/`, {
      ...args,
      method: 'PUT'
    })
    return response
  }

  public readonly partialUpdateMe: UserDAO['partialUpdateMe'] = (args) => {
    const response = this.fetchBase.request<UserPartialUpdateMeResData>(`${this.prefix}/me/`, {
      ...args,
      method: 'PATCH'
    })
    return response
  }

  public readonly destroyMe: UserDAO['destroyMe'] = (args) => {
    const response = this.fetchBase.request<UserDestroyMeResData>(`${this.prefix}/me/`, {
      ...args,
      method: 'DELETE'
    })
    return response
  }

  public readonly changeEmail: UserDAO['changeEmail'] = (args) => {
    const response = this.fetchBase.request<UserChangeEmailResData>(`${this.prefix}/change_email/`, {
      ...args,
      method: 'PUT'
    })
    return response
  }

  public readonly updatePassword: UserDAO['updatePassword'] = (args) => {
    const response = this.fetchBase.request<UserUpdatePasswordResData>(`${this.prefix}/me/update_password/`, {
      ...args,
      method: 'PUT'
    })
    return response
  }

  public readonly resetPassword: UserDAO['resetPassword'] = (args) => {
    const response = this.fetchBase.request<UserResetPasswordResData>(`${this.prefix}/reset_password/`, {
      ...args,
      method: 'POST'
    })
    return response
  }

  public readonly resetPasswordConfirm: UserDAO['resetPasswordConfirm'] = (args) => {
    const response = this.fetchBase.request<UserResetPasswordConfirmResData>(`${this.prefix}/reset_password_confirm/`, {
      ...args,
      method: 'PUT'
    })
    return response
  }

  public readonly findEmail: UserDAO['findEmail'] = (args) => {
    const response = this.fetchBase.request<UserFindEmailResData>(`${this.prefix}/find_email/`, {
      ...args,
      method: 'POST'
    })
    return response
  }

  public readonly verifyEmail: UserDAO['verifyEmail'] = (args) => {
    const response = this.fetchBase.request<UserVerifyEmailResData>(`${this.prefix}/verify_email/`, {
      ...args,
      method: 'POST'
    })
    return response
  }

  public readonly updateAddTags: UserDAO['updateAddTags'] = (args) => {
    const response = this.fetchBase.request<UserUpdateAddTagsResData>(`${this.prefix}/add_tags/`, {
      ...args,
      method: 'PUT'
    })
    return response
  }

  public readonly partialUpdateAddTags: UserDAO['partialUpdateAddTags'] = (args) => {
    const response = this.fetchBase.request<UserPartialUpdateAddTagsResData>(`${this.prefix}/add_tags/`, {
      ...args,
      method: 'PATCH'
    })
    return response
  }

  public readonly checkPassword: UserDAO['checkPassword'] = (args) => {
    const response = this.fetchBase.request<UserCheckPasswordResData>(`${this.prefix}/check_password/`, {
      ...args,
      method: 'POST'
    })
    return response
  }

  public readonly verify: UserDAO['verify'] = (args) => {
    const response = this.fetchBase.request<UserVerifyResData>(`${this.prefix}/verify/`, {
      ...args,
      method: 'POST'
    })
    return response
  }

  public readonly changeImage: UserDAO['changeImage'] = (args) => {
    const response = this.fetchBase.request<UserChangeImageResData>(`${this.prefix}/me/change_image/`, {
      ...args,
      method: 'PUT'
    })
    return response
  }

  public readonly search: UserDAO['search'] = (args) => {
    const response = this.fetchBase.request<UserSearchResData>(`${this.prefix}/search/`, { ...args })
    return response
  }
}
