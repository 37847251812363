import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule, FavoriteModule, BulkDestroyModule } from '~/api/shared/core/modules'
import type { AssignmentDataGroupsDAO } from './AssignmentDataGroups.types'
import type { AssignmentDataGroupsListResData } from './AssignmentDataGroups.types'

export class AssignmentDataGroupsAPI extends DAOBase implements AssignmentDataGroupsDAO {
  private commonModule: CommonModule
  private prefix = '/assignment_data_groups'

  constructor(fetchBase: DefaultFetchBase, modules: { commonModule: CommonModule }) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
  }

  public readonly list: AssignmentDataGroupsDAO['list'] = (args) =>
    this.commonModule.list<AssignmentDataGroupsListResData>({ ...args, prefix: this.prefix })
}
