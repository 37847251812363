import type { DAO } from '~/plugins/api'

export const useAPI = () => {
  // const fetcher = async () => {
  //   const fetchInstance = $fetch.create({
  //     baseURL: 'https://platform.datamaker.io/api',
  //     headers: FETCH_OPTIONS.headers,
  //     timeout: FETCH_OPTIONS.timeout,
  //     onRequest({ options }) {
  //       const interceptedHeader = (options.headers || {}) as Record<string, string>
  //       // TODO : useCookie 기반 토큰 저장 로직 개발 예정 ( 임시로 토큰 하드코딩 )
  //       const authToken = 'c0292dee9f17d83a23f9d5fbf6cb6271531c76c2'
  //       const workspaceToken = '5f719c6001eab26f47a80fb642eb5e577169f51f'
  //
  //       // TODO : Check if the request has an Authorization header
  //       if (!FETCH_OPTIONS.headers!.Authorization && authToken) {
  //         interceptedHeader.Authorization = `Token ${authToken}`
  //         FETCH_OPTIONS.headers!.Authorization = `Token ${authToken}`
  //       }
  //
  //       // TODO : Check if the request has an DATAMAKER-Workspace header
  //       if (!FETCH_OPTIONS.headers!['DATAMAKER-Workspace'] && workspaceToken) {
  //         interceptedHeader['DATAMAKER-Workspace'] = `Token ${workspaceToken}`
  //         FETCH_OPTIONS.headers!['DATAMAKER-Workspace'] = `Token ${workspaceToken}`
  //       }
  //     }
  //   })
  //
  //   const defaultFetchInstance = new DefaultFetchBase(fetchInstance)
  //
  //   const agentDAO = new AgentAPI(defaultFetchInstance, { commonModule: new CommonModule(defaultFetchInstance) })
  //
  //   const data = await agentDAO.list()
  //
  //   return {
  //     data
  //   }
  // }

  return useNuxtApp().$api as DAO
}
