import { useCookies } from '@vueuse/integrations/useCookies'
import type { CookieChangeListener, CookieGetOptions, CookieSetOptions } from 'universal-cookie'
import Cookies from 'universal-cookie/cjs/Cookies'
import { TOKEN } from '~/constants/shared'

interface TokenCookies {
  cookie: {
    get: <T = any>(name: string, options?: CookieGetOptions | undefined) => T
    getAll: <T = any>(options?: CookieGetOptions | undefined) => T
    set: (name: string, value: any, options?: CookieSetOptions | undefined) => void
    remove: (name: string, options?: CookieSetOptions | undefined) => void
    addChangeListener: (callback: CookieChangeListener) => void
    removeChangeListener: (callback: CookieChangeListener) => void
  }
  token: {
    auth?: string
    tenant?: string
  }
}

export const useTokenCookies = (
  cookieObject?: TokenCookies['cookie'],
  cookieOptions?: {
    dependencies?: string[] | null
    options?: {
      doNotParse?: boolean | undefined
      autoUpdateDependencies?: boolean | undefined
    }
    cookies?: Cookies
  }
): TokenCookies => {
  const cookie = cookieObject ?? useCookies(cookieOptions?.dependencies, cookieOptions?.options, cookieOptions?.cookies)

  // if (import.meta.env.DEV) {
  //   const config = useRuntimeConfig()
  //   cookie.set(TOKEN.AUTH, config.public.dev.auth)
  //   cookie.set(TOKEN.TENANT, config.public.dev.tenant)
  // }

  const auth = cookie.get(TOKEN.AUTH.value) as string | undefined
  const tenant = cookie.get(TOKEN.TENANT.value) as string | undefined

  return {
    cookie,
    token: {
      auth,
      tenant
    }
  }
}
