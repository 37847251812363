import { generateClasses } from '@formkit/themes'
import global from './global'
import button from './inputs/button'
import checkbox from './inputs/checkbox'
import color from './inputs/color'
import colorpicker from './inputs/colorpicker'
import date from './inputs/date'
import file from './inputs/file'
import form from './inputs/form'
import json from './inputs/json'
import number from './inputs/number'
import radio from './inputs/radio'
import select from './inputs/select'
import slider from './inputs/slider'
import text from './inputs/text'
import textarea from './inputs/textarea'

export default generateClasses({
  global,
  form,
  button,
  submit: button,
  text,
  number,
  textarea,
  password: text,
  checkbox,
  email: text,
  radio,
  json,
  phoneNumber: text,
  slider,
  file,
  color,
  select,
  colorpicker,
  date
})
