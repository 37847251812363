import type { FormKitNode, FormKitPlaceholderNode } from '@formkit/core'

export const uniqueColumnValueRules = function (node: FormKitNode, argument: unknown) {
  const nodeName = node.name
  let parentsNodes: (FormKitNode<unknown> | FormKitPlaceholderNode<unknown>)[] | undefined

  // TODO : 주어진 인자를 타고 dfs 후위 순회 하여 노드 리스트를 제공해야함. 시간이 부족해서 미구현
  if (argument === 'attributes') {
    const listSibling = node.parent?.parent?.parent?.parent?.children

    parentsNodes = listSibling.map((node) => {
      return node.children?.[0].children?.[0]
    })
  } else {
    parentsNodes = node.parent?.parent?.children
  }

  if (!parentsNodes || parentsNodes.length < 1) return true

  const columnNodes: FormKitNode<unknown>[] = []

  for (const siblingNode of parentsNodes) {
    const columnNode = siblingNode?.children.find((childNode) => childNode.name === nodeName)

    if (columnNode) {
      columnNodes.push(columnNode)
    }
  }

  const result: FormKitNode<unknown>[] = columnNodes.reduce(
    (acc, columnNode) => (columnNode.value === node.value ? [...acc, columnNode.value] : acc),
    [] as FormKitNode<unknown>[]
  )

  return result.length < 2
}
