import type { ReviewParticipationDAO } from '~/api/project/types'
import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { BulkDestroyModule, CommonModule } from '~/api/shared/core/modules'

export class ReviewParticipationAPI extends DAOBase implements ReviewParticipationDAO {
  private commonModule: CommonModule
  private bulkDestroyModule: BulkDestroyModule
  private prefix = '/review_participations'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
      bulkDestroyModule: BulkDestroyModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
    this.bulkDestroyModule = modules.bulkDestroyModule
  }

  public readonly list: ReviewParticipationDAO['list'] = (args) =>
    this.commonModule.list<unknown>({ prefix: this.prefix, ...args })

  public readonly retrieve: ReviewParticipationDAO['retrieve'] = (args) =>
    this.commonModule.retrieve<unknown>({ prefix: this.prefix, ...args })

  public readonly create: ReviewParticipationDAO['create'] = ({ body, options }) =>
    this.commonModule.create<unknown>({ prefix: this.prefix, body, options })

  public readonly update: ReviewParticipationDAO['update'] = (args) =>
    this.commonModule.update<unknown>({ prefix: this.prefix, ...args })

  public readonly partialUpdate: ReviewParticipationDAO['partialUpdate'] = (args) =>
    this.commonModule.partialUpdate<unknown>({ prefix: this.prefix, ...args })

  public readonly destroy: ReviewParticipationDAO['destroy'] = (args) =>
    this.commonModule.destroy<unknown>({ prefix: this.prefix, ...args })

  public readonly bulkDestroy: ReviewParticipationDAO['bulkDestroy'] = (args) =>
    this.bulkDestroyModule.bulkDestroy({ prefix: this.prefix, ...args })
}
