import { upperFirst } from 'lodash-es'
import { useAPI } from '~/composables/shared/useAPI'
import { usePageMetaQuery } from '~/composables/shared/usePageMetaQuery'
import { ANNOTATOR_CATEGORY_COLOR, ANNOTATOR_CATEGORY_ICON } from '~/constants/project'

export const useWorkerMetaQuery = async () => {
  const { t } = useI18n({ useScope: 'global' })
  const api = useAPI()

  return await usePageMetaQuery(async (route, createPageMeta) => {
    const projectId = Number(route.params.project_id as string)
    const workshopId = Number(route.params.workshop_id as string)
    const guideId = Number(route.params.guide_id as string)
    const role = route.params.role

    const workerFetchList = [
      guideId &&
        api.worker.workerPost.retrieve({
          path: Number(guideId)
        })
    ]

    if (route.params.project_id) {
      workerFetchList.unshift(
        api.project[role === 'labeler' ? 'workshopLabeler' : 'workshopReviewer'].retrieve({
          path: workshopId
        })
      )
    }

    const results = await Promise.all(workerFetchList)

    const [workshopEssentialInfo, workerPost] = results || []

    const annotatorCategory = workshopEssentialInfo?.project?.category
    return createPageMeta({
      headline: {
        value: workshopEssentialInfo?.project?.id || ''
      },
      title: workshopEssentialInfo?.project?.title || '',
      description: workshopEssentialInfo?.project?.description || '',
      category: {
        value: annotatorCategory || ''
      },
      badgeGroups: [
        {
          badges: [
            {
              variant: 'tint',
              color:
                ANNOTATOR_CATEGORY_COLOR[annotatorCategory?.toUpperCase() as keyof typeof ANNOTATOR_CATEGORY_COLOR] ||
                'slate',
              size: 'md',
              value: upperFirst(annotatorCategory || ''),
              avatarType: 'icon',
              avatarValue:
                ANNOTATOR_CATEGORY_ICON[annotatorCategory?.toUpperCase() as keyof typeof ANNOTATOR_CATEGORY_ICON] || ''
            }
          ]
        },
        {
          badges:
            workshopEssentialInfo?.project?.annotation_types?.map((types) => ({
              value: t(`annotationTool.${types}`)
            })) || [],
          maxLength: 20
        }
      ],
      breadcrumbs: {
        project_id: {
          label: workshopEssentialInfo?.project?.title || 'project_id'
        },
        worker_id: {
          // TODO: API 미연동
          label: 'worker_id'
        },
        workshop_id: {
          label: workshopEssentialInfo?.name || 'workshop_id'
        },
        guide_id: {
          label: workerPost?.title || 'guide_id'
        },
        role: {
          label: (route.params.role as string) || 'role'
        }
      },
      directoryNavigation: {
        assignments: {
          children: {
            tasks: {},
            'discarded-tasks': {}
          }
        },
        statistics: {
          class: '!h-12 items-center',
          children: {
            task: {}
          }
        },
        'guide-line': {
          to: `/data-lab/${role}/${projectId}/workshop/${workshopId}/guide-line`,
          prefixIcon: 'rectangle-h:empty-forward-sign',
          class: '!h-12 items-center',
          children: {}
        }
      }
    })
  })
}
