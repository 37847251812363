import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import type { TagActionModuleDAO } from '~/api/shared/core/modules/types'
import type { ExtractModuleParams } from '~/api/shared/core/types'

export class TagActionModule extends DAOBase implements TagActionModuleDAO {
  public readonly setTags = <TReturn>({
    prefix,
    body,
    options
  }: ExtractModuleParams<TagActionModuleDAO['setTags']>): Promise<TReturn> => {
    const response = this.fetchBase.request<TReturn>(`${prefix}/set_tags/`, {
      ...options,
      method: 'POST',
      body
    })
    return response
  }

  public readonly removeTags = ({
    prefix,
    body,
    options
  }: ExtractModuleParams<TagActionModuleDAO['removeTags']>): Promise<unknown> => {
    const response = this.fetchBase.request<unknown>(`${prefix}/remove_tags/`, {
      ...options,
      method: 'POST',
      body
    })
    return response
  }
}
