import type { AssignmentLabelerDAO, AssignmentReviewerDAO } from '~/api/project/types'
import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule, AssignmentModule, FileModule } from '~/api/shared/core/modules'

export class AssignmentReviewerAPI extends DAOBase implements AssignmentReviewerDAO {
  private commonModule: CommonModule
  private assignmentModule: AssignmentModule
  private fileModule: FileModule
  private prefix = '/assignments/reviewer'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
      assignmentModule: AssignmentModule
      fileModule: FileModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
    this.assignmentModule = modules.assignmentModule
    this.fileModule = modules.fileModule
  }

  public readonly list: AssignmentReviewerDAO['list'] = (args) =>
    this.commonModule.list<unknown>({ ...args, prefix: this.prefix })

  public readonly retrieve: AssignmentReviewerDAO['retrieve'] = ({ id, options }) =>
    this.commonModule.retrieve<unknown>({ prefix: this.prefix, id, options })

  public readonly update: AssignmentReviewerDAO['update'] = ({ id, body, options }) =>
    this.commonModule.update<unknown>({ prefix: this.prefix, id, body, options })

  public readonly partialUpdate: AssignmentReviewerDAO['partialUpdate'] = ({ id, body, options }) =>
    this.commonModule.partialUpdate<unknown>({ prefix: this.prefix, id, body, options })

  public readonly files: AssignmentReviewerDAO['files'] = ({ id, options }) =>
    this.fileModule.files<unknown>({ prefix: this.prefix, id, options })

  public readonly runService: AssignmentReviewerDAO['runService'] = ({ id, body, options }) =>
    this.assignmentModule.runService<unknown>({ prefix: this.prefix, id, body, options })

  public readonly start: AssignmentReviewerDAO['start'] = ({ id, body, options }) =>
    this.assignmentModule.start<unknown>({ prefix: this.prefix, id, body, options })

  public readonly actions: AssignmentReviewerDAO['actions'] = ({ options }) =>
    this.assignmentModule.actions<unknown>({ prefix: this.prefix, options })

  public readonly countByStatus: AssignmentLabelerDAO['countByStatus'] = (args) =>
    this.assignmentModule.countByStatus<unknown>({ prefix: this.prefix, ...args })
}
