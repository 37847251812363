import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule } from '~/api/shared/core/modules'
import type {
  GroundTruthDatasetDAO,
  GroundTruthDatasetsListResData,
  GroundTruthDatasetsPartialUpdateResData,
  GroundTruthDatasetsRetrieveResData,
  GroundTruthDatasetsUpdateResData
} from '~/api/shared/ground-truth/GroundTruthDataset.types'

export class GroundTruthDatasetAPI extends DAOBase implements GroundTruthDatasetDAO {
  private commonModule: CommonModule
  private prefix = '/ground_truth_datasets'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
  }

  public readonly list: GroundTruthDatasetDAO['list'] = (args) =>
    this.commonModule.list<GroundTruthDatasetsListResData>({ prefix: this.prefix, ...args })

  public readonly retrieve: GroundTruthDatasetDAO['retrieve'] = (args) =>
    this.commonModule.retrieve<GroundTruthDatasetsRetrieveResData>({ prefix: this.prefix, ...args })

  public readonly update: GroundTruthDatasetDAO['update'] = (args) =>
    this.commonModule.update<GroundTruthDatasetsUpdateResData>({ prefix: this.prefix, ...args })

  public readonly partialUpdate: GroundTruthDatasetDAO['partialUpdate'] = (args) =>
    this.commonModule.partialUpdate<GroundTruthDatasetsPartialUpdateResData>({ prefix: this.prefix, ...args })
}
