import { loadIcon as loadIconifyIcon, getIcon as getIconifyIcon } from '@iconify/vue'

export async function loadIcon(iconName: string): Promise<string | undefined> {
  try {
    const icon = await loadIconifyIcon(iconName)
    return getIconSvg(icon.body)
  } catch (err) {
    return undefined
  }
}

export function getIcon(iconName: string): string | undefined {
  const icon = getIconifyIcon(iconName)
  return icon ? getIconSvg(icon.body) : undefined
}

export function getIconSvg(iconContent: string): string {
  return `
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      ${iconContent}
    </svg>`
}
