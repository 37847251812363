import type { RouterConfig } from '@nuxt/schema'

const blackList = ['data-lab', 'auth']

export default {
  routes: (_routes) => {
    return _routes.map((route) => ({
      ...route,
      path: blackList.some((item) => route.name === 'index' || route.path.includes(item))
        ? route.path
        : `/:tenant?${route.path}`
    }))
  }
} satisfies RouterConfig

export { blackList as tenantSlugBlackList }
