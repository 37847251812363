import { cva } from 'class-variance-authority'

export { default as BadgeGroup } from './BadgeGroup.vue'

export const badgeGroupVariant = cva('truncate-ellipsis min-w-5', {
  variants: {
    size: {
      md: '',
      sm: ''
    }
  }
})
