import type {
  PluginCreateResData,
  PluginDAO,
  PluginDestroyResData,
  PluginListResData,
  PluginPartialUpdateResData,
  PluginRetrieveResData,
  PluginRunResData,
  PluginUpdateResData
} from '~/api/machine-learning/Plugin.types'
import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule } from '~/api/shared/core/modules'

export class PluginAPI extends DAOBase implements PluginDAO {
  private commonModule: CommonModule
  private prefix = '/plugins'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
  }

  public readonly list: PluginDAO['list'] = (args) =>
    this.commonModule.list<PluginListResData>({ ...args, prefix: this.prefix })

  public readonly retrieve: PluginDAO['retrieve'] = (args) =>
    this.commonModule.retrieve<PluginRetrieveResData>({ ...args, prefix: this.prefix })

  public readonly create: PluginDAO['create'] = (args) =>
    this.commonModule.create<PluginCreateResData>({ ...args, prefix: this.prefix })

  public readonly update: PluginDAO['update'] = (args) =>
    this.commonModule.update<PluginUpdateResData>({ ...args, prefix: this.prefix })

  public readonly partialUpdate: PluginDAO['partialUpdate'] = (args) =>
    this.commonModule.partialUpdate<PluginPartialUpdateResData>({ ...args, prefix: this.prefix })

  public readonly destroy: PluginDAO['destroy'] = (args) =>
    this.commonModule.destroy<PluginDestroyResData>({ ...args, prefix: this.prefix })

  public readonly run: PluginDAO['run'] = (args) => {
    return this.fetchBase.request<PluginRunResData>(`${this.prefix}/${args?.path}/run/`, {
      ...args,
      method: 'POST'
    })
  }
}
