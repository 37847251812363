import type { TaskDAO } from '~/api/machine-learning/types'
import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule } from '~/api/shared/core/modules'

export class TaskAPI extends DAOBase implements TaskDAO {
  private commonModule: CommonModule
  private prefix = '/tasks'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
  }

  public readonly list: TaskDAO['list'] = ({ options }) =>
    this.commonModule.list<unknown>({ prefix: this.prefix, options })

  public readonly retrieve: TaskDAO['retrieve'] = ({ id, options }) =>
    this.commonModule.retrieve<unknown>({ prefix: this.prefix, id, options })

  public readonly create: TaskDAO['create'] = ({ body, options }) =>
    this.commonModule.create<unknown>({ prefix: this.prefix, body, options })

  public readonly update: TaskDAO['update'] = ({ id, body, options }) =>
    this.commonModule.update<unknown>({ prefix: this.prefix, id, body, options })

  public readonly partialUpdate: TaskDAO['partialUpdate'] = ({ id, body, options }) =>
    this.commonModule.partialUpdate<unknown>({ prefix: this.prefix, id, body, options })
}
