import { text as basePassword } from '@formkit/inputs'
import { cloneAny } from '@formkit/utils'

export const password = cloneAny(basePassword)

function showPassword(node) {
  node.props.suffixIcon = 'heroicons:eye'
  node.props.onSuffixIconClick = (node) => {
    if (node.props.type === 'password') {
      node.props.suffixIcon = 'heroicons:eye-slash'
      node.props.type = 'text'
    } else {
      node.props.suffixIcon = 'heroicons:eye'
      node.props.type = 'password'
    }
  }
}

password.features.push(showPassword)
