<template>
  <div class="flex h-screen flex-col items-center justify-center">
    <NuxtImg src="/synapse.png" alt="Synapse Logo" class="h-16 w-16" />
    <div class="mb-10 text-center">
      <div class="mt-4">
        <p class="text-5xl font-semibold">{{ error.statusCode }} error</p>
        <p class="mt-2 text-base text-slate-600">{{ computedErrMessage }}</p>
        <div
          v-if="isDev"
          class="mb-[-1.5rem] mt-4 max-h-[600px] overflow-auto rounded-lg bg-slate-200 p-4 text-left"
          v-html="props.error?.stack" />
      </div>
    </div>
    <Button
      as="Button"
      color="blue"
      variant="outline"
      size="lg"
      rounded="lg"
      value="Home"
      @click="clearError({ redirect: '/' })" />
  </div>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app'

const props = defineProps({
  error: Object as () => NuxtError
})

const isDev = import.meta.dev

const computedErrMessage = computed(() => {
  const errorMessages = {
    400: '잘못된 요청입니다. 입력한 정보를 확인해주세요.',
    401: '로그인이 필요합니다. 다시 로그인해주세요.',
    403: '해당 페이지에 대한 권한이 없습니다.',
    404: '요청하신 페이지를 찾을 수 없습니다.',
    405: '허용되지 않은 요청 방식입니다.',
    408: '요청 시간이 초과되었습니다. 다시 시도해주세요.',

    500: '앗! 문제가 발생했습니다.',
    502: '잘못된 게이트웨이입니다. 서버 연결에 문제가 있습니다.',
    503: '현재 서비스를 사용할 수 없습니다. 잠시 후 다시 시도해주세요.',
    504: '게이트웨이 시간이 초과되었습니다. 서버가 응답하지 않습니다.',

    default: '알 수 없는 오류가 발생했습니다. 다시 시도해주세요.'
  }
  return errorMessages[props.error.statusCode] || errorMessages.default
})
</script>
