import type {
  TaskAnnotateCalculationTemplateRetrieveResData,
  TaskAnnotateCalculationTemplateUpdateResData,
  TaskBulkDestroyReqArgs,
  TaskBulkDestroyResData,
  TaskCreateAssignmentsResData,
  TaskCreateGroundTruthsResData,
  TaskCreateResData,
  TaskDAO,
  TaskDestroyResData,
  TaskFilesRetrieveReqArgs,
  TaskFilesRetrieveResData,
  TaskListResData,
  TaskPredictResData,
  TaskRemoveTagsCreateResData,
  TaskRetrieveResData,
  TaskSetDiscardedUpdateResData,
  TaskSetTagsCreateResData,
  TaskSetWorkshopCreateResData
} from '~/api/project/Task.types'
import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { BulkDestroyModule, CommonModule, FileModule, TagActionModule } from '~/api/shared/core/modules'

export class TaskAPI extends DAOBase implements TaskDAO {
  private commonModule: CommonModule
  private tagActionModule: TagActionModule
  private bulkDestroyModule: BulkDestroyModule
  private fileModule: FileModule

  private prefix = '/tasks'
  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
      bulkDestroyModule: BulkDestroyModule
      tagActionModule: TagActionModule
      fileModule: FileModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
    this.bulkDestroyModule = modules.bulkDestroyModule
    this.tagActionModule = modules.tagActionModule
    this.fileModule = modules.fileModule
  }

  public readonly list: TaskDAO['list'] = (args) =>
    this.commonModule.list<TaskListResData>({ prefix: this.prefix, ...args })

  public readonly create: TaskDAO['create'] = (args) =>
    this.commonModule.create<TaskCreateResData>({ prefix: this.prefix, ...args })

  public readonly retrieve: TaskDAO['retrieve'] = (args) =>
    this.commonModule.retrieve<TaskRetrieveResData>({ prefix: this.prefix, ...args })

  public readonly bulkDestroy: TaskDAO['bulkDestroy'] = (args) =>
    this.bulkDestroyModule.bulkDestroy<TaskBulkDestroyResData>({ prefix: this.prefix, ...args })

  public readonly destroy: TaskDAO['destroy'] = (args) =>
    this.commonModule.destroy<TaskDestroyResData>({ prefix: this.prefix, ...args })

  public readonly retrieveAnnotateCalculationTemplate: TaskDAO['retrieveAnnotateCalculationTemplate'] = (args) =>
    this.fetchBase.request<TaskAnnotateCalculationTemplateRetrieveResData>(
      `${this.prefix}/${args.path}/annotate_calculation_template/`,
      { ...args, method: 'GET' }
    )

  public readonly updateAnnotateCalculationTemplate: TaskDAO['updateAnnotateCalculationTemplate'] = (args) =>
    this.fetchBase.request<TaskAnnotateCalculationTemplateUpdateResData>(
      `${this.prefix}/${args.path}/annotate_calculation_template`,
      { ...args, method: 'PUT' }
    )

  public readonly retrieveTaskFiles: TaskDAO['retrieveTaskFiles'] = (args) =>
    this.fetchBase.request<TaskFilesRetrieveResData>(`${this.prefix}/${args.path}/files`, { ...args, method: 'GET' })

  public readonly createAssignments: TaskDAO['createAssignments'] = (args) =>
    this.fetchBase.request<TaskCreateAssignmentsResData>(`${this.prefix}/create_assignments/`, {
      ...args,
      method: 'POST'
    })

  public readonly createGroundTruths: TaskDAO['createGroundTruths'] = (args) =>
    this.fetchBase.request<TaskCreateGroundTruthsResData>(`${this.prefix}/create_ground_truths/`, {
      ...args,
      method: 'POST'
    })

  public readonly createPredict: TaskDAO['createPredict'] = (args) =>
    this.fetchBase.request<TaskPredictResData>(`${this.prefix}/predict/`, {
      ...args,
      method: 'POST'
    })

  public readonly createRemoveTaskTags: TaskDAO['createRemoveTaskTags'] = (args) =>
    this.fetchBase.request<TaskRemoveTagsCreateResData>(`${this.prefix}/remove_tags/`, {
      ...args,
      method: 'POST'
    })

  public readonly updateSetDiscarded: TaskDAO['updateSetDiscarded'] = (args) =>
    this.fetchBase.request<TaskSetDiscardedUpdateResData>(`${this.prefix}/set_discarded/`, {
      ...args,
      method: 'PUT'
    })

  public readonly createSetTags: TaskDAO['createSetTags'] = (args) =>
    this.fetchBase.request<TaskSetTagsCreateResData>(`${this.prefix}/set_tags/`, {
      ...args,
      method: 'POST'
    })

  public readonly createSetWorkshop: TaskDAO['createSetWorkshop'] = (args) =>
    this.fetchBase.request<TaskSetWorkshopCreateResData>(`${this.prefix}/set_workshop/`, {
      ...args,
      method: 'POST'
    })
}
