import type {
  WorkshopCreateResData,
  WorkshopDAO,
  WorkshopDestroyResData,
  WorkshopListResData,
  WorkshopPartialUpdateResData,
  WorkshopRetrieveResData,
  WorkshopUpdateResData
} from '~/api/project/Workshop.types'
import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule } from '~/api/shared/core/modules'

export class WorkshopAPI extends DAOBase implements WorkshopDAO {
  private commonModule: CommonModule
  private prefix = '/workshops'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
  }

  public readonly list: WorkshopDAO['list'] = (args) =>
    this.commonModule.list<WorkshopListResData>({ ...args, prefix: this.prefix })

  public readonly retrieve: WorkshopDAO['retrieve'] = (args) =>
    this.commonModule.retrieve<WorkshopRetrieveResData>({ ...args, prefix: this.prefix })

  public readonly create: WorkshopDAO['create'] = (args) =>
    this.commonModule.create<WorkshopCreateResData>({ ...args, prefix: this.prefix })

  public readonly update: WorkshopDAO['update'] = (args) =>
    this.commonModule.update<WorkshopUpdateResData>({ ...args, prefix: this.prefix })

  public readonly partialUpdate: WorkshopDAO['partialUpdate'] = (args) =>
    this.commonModule.partialUpdate<WorkshopPartialUpdateResData>({ ...args, prefix: this.prefix })

  public readonly destroy: WorkshopDAO['destroy'] = (args) =>
    this.commonModule.destroy<WorkshopDestroyResData>({ ...args, prefix: this.prefix })
}
