import { usePageMetaQuery } from '~/composables/shared/usePageMetaQuery'

export const useAccountMetaQuery = async () => {
  return await usePageMetaQuery(async (route, createPageMeta) => {
    await Promise.resolve()

    return createPageMeta({
      title: 'account',
      directoryNavigation: {
        profile: {
          prefixIcon: 'heroicons:user'
        },
        manage: {
          prefixIcon: 'heroicons:cog-8-tooth'
        },
        verification: {
          prefixIcon: 'heroicons:shield-check'
        },
        deactivate: {
          class: 'hidden'
        }
      }
    })
  })
}
