import { cva } from 'class-variance-authority'

export { default as Avatar } from './Avatar.vue'
export { default as AvatarImage } from './AvatarImage.vue'
export { default as AvatarIcon } from './AvatarIcon.vue'
export { default as AvatarText } from './AvatarText.vue'

export const avatarTextVariant = cva('truncate font-medium leading-none text-slate-600', {
  variants: {
    multiple: {
      true: '',
      false: ''
    },
    size: {
      '3xs': '',
      '2xs': '',
      xs: '',
      sm: '',
      md: '',
      lg: '',
      xl: '',
      '2xl': '',
      '3xl': ''
    }
  },
  compoundVariants: [
    { multiple: false, size: '3xs', class: 'text-2xs' },
    { multiple: false, size: '2xs', class: 'text-xs' },
    { multiple: false, size: 'xs', class: 'text-sm' },
    { multiple: false, size: 'sm', class: 'text-base' },
    { multiple: false, size: 'md', class: 'text-lg' },
    { multiple: false, size: 'lg', class: 'text-xl' },
    { multiple: false, size: 'xl', class: 'text-2xl' },
    { multiple: false, size: '2xl', class: 'text-3xl' },
    { multiple: false, size: '3xl', class: 'text-4xl' },
    { multiple: true, size: '3xs', class: 'text-4xs' },
    { multiple: true, size: '2xs', class: 'text-3xs' },
    { multiple: true, size: 'xs', class: 'text-2xs' },
    { multiple: true, size: 'sm', class: 'text-xs' },
    { multiple: true, size: 'md', class: 'text-sm' },
    { multiple: true, size: 'lg', class: 'text-base' },
    { multiple: true, size: 'xl', class: 'text-lg' },
    { multiple: true, size: '2xl', class: 'text-xl' },
    { multiple: true, size: '3xl', class: 'text-2xl' }
  ]
})
export const avatarIconVariant = cva('', {
  variants: {
    size: {
      '3xs': 'h-2 w-2',
      '2xs': 'h-3 w-3',
      xs: 'h-4 w-4',
      sm: 'h-5 w-5',
      md: 'h-6 w-6',
      lg: 'h-8 w-8',
      xl: 'h-10 w-10',
      '2xl': 'h-12 w-12',
      '3xl': 'h-14 w-14'
    }
  }
})

export const avatarImageVariant = cva('', {
  variants: {
    rounded: { true: 'rounded-full', false: 'rounded' }
  }
})

export const avatarVariant = cva(
  'group relative inline-flex flex-shrink-0 items-center justify-center bg-slate-200 text-slate-600',
  {
    variants: {
      size: {
        '3xs': 'h-4 w-4',
        '2xs': 'h-5 w-5',
        xs: 'h-6 w-6',
        sm: 'h-8 w-8',
        md: 'h-10 w-10',
        lg: 'h-12 w-12',
        xl: 'h-14 w-14',
        '2xl': 'h-16 w-16',
        '3xl': 'h-20 w-20'
      },
      rounded: {
        true: 'rounded-full',
        false: 'rounded-lg'
      },
      border: {
        true: 'ring-1 ring-slate-300',
        false: 'ring-2 ring-inset ring-white-950'
      }
    }
  }
)
