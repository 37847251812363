import { createSection } from '@formkit/inputs'

export const boxOption = createSection('boxOption', () => ({
  $el: 'li',
  for: ['option', '$options'],
  attrs: {
    class: '$classes.option',
    'data-disabled': '$option.attrs.disabled || $disabled || undefined'
  }
}))
