import type { DataGroupDAO, DataGroupListResData, DataGroupRetrieveResData } from '~/api/catalog/DataGroup.types'
import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule } from '~/api/shared/core/modules'

export class DataGroupAPI extends DAOBase implements DataGroupDAO {
  private commonModule: CommonModule
  private prefix = '/data_groups'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
  }

  public readonly list: DataGroupDAO['list'] = (args) =>
    this.commonModule.list<DataGroupListResData>({ ...args, prefix: this.prefix })

  public readonly retrieve: DataGroupDAO['retrieve'] = (args) =>
    this.commonModule.retrieve<DataGroupRetrieveResData>({ ...args, prefix: this.prefix })
}
