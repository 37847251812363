import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule, FavoriteModule, StatisticsModule } from '~/api/shared/core/modules'
import type {
  ProjectCreateResData,
  ProjectDAO,
  ProjectDestroyResData,
  ProjectListResData,
  ProjectPartialUpdateResData,
  ProjectRetrieveResData,
  ProjectUpdateResData,
  ProjectCreateFavoritesResData,
  ProjectDestroyFavoritesResData,
  ProjectCategoriesResData,
  ProjectUnarchiveCreateResData,
  ProjectArchiveCreateResData,
  ProjectCreateInviteResData,
  ProjectMyRoleRetrieveResData,
  ProjectRolesRetrieveResData,
  ProjectDefaultRolesRetrieveResData,
  ProjectStatisticsResData,
  ProjectTotalStatisticsResData
} from './Project.types'

export class ProjectAPI extends DAOBase implements ProjectDAO {
  private commonModule: CommonModule
  private favoriteModule: FavoriteModule
  private statisticsModule: StatisticsModule

  private prefix = '/projects'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
      favoriteModule: FavoriteModule
      statisticsModule: StatisticsModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
    this.favoriteModule = modules.favoriteModule
    this.statisticsModule = modules.statisticsModule
  }

  public readonly list: ProjectDAO['list'] = (args) =>
    this.commonModule.list<ProjectListResData>({ prefix: this.prefix, ...args })

  public readonly retrieve: ProjectDAO['retrieve'] = (args) =>
    this.commonModule.retrieve<ProjectRetrieveResData>({ prefix: this.prefix, ...args })

  public readonly create: ProjectDAO['create'] = (args) =>
    this.commonModule.create<ProjectCreateResData>({ prefix: this.prefix, ...args })

  public readonly update: ProjectDAO['update'] = (args) =>
    this.commonModule.update<ProjectUpdateResData>({ prefix: this.prefix, ...args })

  public readonly partialUpdate: ProjectDAO['partialUpdate'] = (args) =>
    this.commonModule.partialUpdate<ProjectPartialUpdateResData>({ prefix: this.prefix, ...args })

  public readonly destroy: ProjectDAO['destroy'] = (args) =>
    this.commonModule.destroy<ProjectDestroyResData>({ prefix: this.prefix, ...args })

  public readonly createFavorites: ProjectDAO['createFavorites'] = (args) =>
    this.favoriteModule.createFavorites<ProjectCreateFavoritesResData>({ prefix: this.prefix, ...args })

  public readonly destroyFavorites: ProjectDAO['destroyFavorites'] = (args) =>
    this.favoriteModule.destroyFavorites<ProjectDestroyFavoritesResData>({ prefix: this.prefix, ...args })

  public readonly categories: ProjectDAO['categories'] = (args) =>
    this.fetchBase.request<ProjectCategoriesResData>(`${this.prefix}/categories/`, {
      ...args,
      method: 'GET'
    })

  public readonly invite: ProjectDAO['invite'] = (args) =>
    this.fetchBase.request<ProjectCreateInviteResData>(`${this.prefix}/${args.path}/invite/`, {
      ...args,
      method: 'POST'
    })

  public readonly myRole: ProjectDAO['myRole'] = (args) =>
    this.fetchBase.request<ProjectMyRoleRetrieveResData>(`${this.prefix}/${args.path}/my_role/`, {
      ...args,
      method: 'GET'
    })

  public readonly roles: ProjectDAO['roles'] = (args) =>
    this.fetchBase.request<ProjectRolesRetrieveResData>(`${this.prefix}/${args.path}/roles/`, {
      ...args,
      method: 'GET'
    })

  public readonly archive: ProjectDAO['archive'] = (args) =>
    this.fetchBase.request<ProjectArchiveCreateResData>(`${this.prefix}/${args.path}/archive/`, {
      ...args,
      method: 'POST'
    })

  public readonly unarchive: ProjectDAO['unarchive'] = (args) => {
    return this.fetchBase.request<ProjectUnarchiveCreateResData>(`${this.prefix}/${args.path}/unarchive/`, {
      ...args,
      method: 'POST'
    })
  }

  public readonly statistics: ProjectDAO['statistics'] = (args) =>
    this.fetchBase.request<ProjectStatisticsResData>(`${this.prefix}/${args.path}/statistics/`, {
      ...args,
      method: 'GET'
    })

  public defaultRoles: ProjectDAO['defaultRoles'] = (args) =>
    this.fetchBase.request<ProjectDefaultRolesRetrieveResData>(`${this.prefix}/default_roles/`, {
      ...args,
      method: 'GET'
    })

  public totalStatistics: ProjectDAO['totalStatistics'] = (args) =>
    this.fetchBase.request<ProjectTotalStatisticsResData>(`${this.prefix}/${args.path}/total_statistics/`, {
      ...args,
      method: 'GET'
    })
}
