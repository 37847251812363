export default {
  outer: tw`group/option mb-6`,
  options: tw`
    group/options grid grid-flow-row gap-2
    has-[[data-slot]]:auto-rows-fr
    has-[[data-slot]]:gap-3
    data-[child]:ml-8
    data-[child]:mt-5
    group-data-[ui=card]:auto-rows-fr
    [&[outline=true]]:rounded-lg
    [&[outline=true]]:border
    [&[outline=true]]:border-slate-300
    [&[outline=true]]:p-3`,
  option: tw`group/option`,
  optionLabelOuter: tw`flex gap-x-2`,
  optionLabel: tw`
    text-nowrap text-slate-900
    group-data-[child]/options:py-0.5
    group-data-[child]/options:text-xs
    group-data-[size=sm]:text-xs
    group-data-[disabled]/option:text-slate-400`,
  optionDescription: tw`truncate`,
  optionHelp: tw`text-sm group-data-[child]/options:text-xs group-data-[size=sm]:text-xs`,
  icon: tw`
    group-data-[checked]/wrapper:text-blue-600
    group-data-[disabled]/option:group-data-[checked]/wrapper:text-blue-300
    group-data-[disabled]/option:text-slate-300`,
  wrapper: tw`
    group/wrapper flex cursor-pointer gap-x-2.5
    data-[slot]:relative
    data-[slot]:h-full
    data-[single]:items-center
    group-data-[ui=card]:h-full
    group-data-[disabled]/option:cursor-not-allowed
    group-data-[ui=card]:rounded-lg
    group-data-[ui=card]:p-3
    group-data-[ui=card]:ring-1
    group-data-[ui=card]:ring-inset
    group-data-[ui=card]:ring-slate-300
    group-data-[ui=card]:group-data-[disabled]/option:hover:bg-inherit
    group-data-[ui=card]:hover:bg-slate-200
    group-data-[ui=card]:data-[checked]:ring-2
    group-data-[ui=card]:data-[checked]:ring-blue-600
    group-data-[ui=card]:group-data-[disabled]/option:data-[checked]:ring-blue-300
    [&>div]:data-[slot]:w-full
    [&>div]:data-[slot]:data-[checked]:ring-2
    [&>div]:data-[slot]:data-[checked]:ring-inset
    [&>div]:data-[slot]:data-[checked]:ring-blue-600
    [&>div]:data-[slot]:group-data-[disabled]/option:bg-slate-100
    [&>div]:data-[slot]:data-[checked]:group-data-[disabled]/option:ring-blue-300
    [&[outline=true]]:rounded-lg
    [&[outline=true]]:border
    [&[outline=true]]:border-slate-300
    [&[outline=true]]:p-3`,
  inner: tw`
    flex p-0.5
    group-data-[ui=card]:order-last`,
  label: tw`
    $reset formkit-label mb-0 flex min-w-0 grow flex-col gap-y-1 text-base text-slate-600
    group-data-[size=sm]:group-data-[single]/wrapper:text-xs
    group-data-[disabled]/option:text-slate-400`,
  input: tw`
    h-5 w-5 cursor-pointer rounded border-slate-500 bg-white-950 text-blue-600
    focus:ring-blue-600 
    focus:ring-offset-1
    data-[partially-checked=true]:border-x-4
    data-[partially-checked=true]:border-y-[9px]
    data-[partially-checked=true]:border-blue-600
    group-data-[slot]/wrapper:absolute
    group-data-[slot]/wrapper:right-2
    group-data-[slot]/wrapper:top-2
    group-data-[child]/options:h-4
    group-data-[size=sm]:h-4
    group-data-[child]/options:w-4
    group-data-[size=sm]:w-4
    group-data-[disabled]/option:cursor-not-allowed
    group-data-[disabled]/option:border-slate-300
    group-data-[disabled]/option:group-data-[checked]/wrapper:border-blue-300
    group-data-[disabled]/option:text-blue-300
    group-data-[slot]/wrapper:ring-0
    group-data-[slot]/wrapper:ring-transparent
    group-data-[disabled]/option:group-[&:not([data-checked])]/wrapper:bg-slate-100
    group-data-[disabled]/option:data-[partially-checked=true]:border-blue-300`
}
