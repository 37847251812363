import type { ProjectReviewerStatisticsResData } from '~/api/project/ProjectReviewer.types'
import type { ProjectReviewerDAO } from '~/api/project/types'
import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule, ProjectSettingModule, StatisticsModule } from '~/api/shared/core/modules'

export class ProjectReviewerAPI extends DAOBase implements ProjectReviewerDAO {
  private commonModule: CommonModule
  private projectSettingModule: ProjectSettingModule
  private statisticsModule: StatisticsModule
  private prefix = '/projects/reviewer'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
      projectSettingModule: ProjectSettingModule
      statisticsModule: StatisticsModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
    this.projectSettingModule = modules.projectSettingModule
    this.statisticsModule = modules.statisticsModule
  }

  public readonly list: ProjectReviewerDAO['list'] = (args) =>
    this.commonModule.list<unknown>({ prefix: this.prefix, ...args })

  public readonly retrieve: ProjectReviewerDAO['retrieve'] = (args) =>
    this.commonModule.retrieve<unknown>({ prefix: this.prefix, ...args })

  public readonly queuePosition: ProjectReviewerDAO['queuePosition'] = (args) =>
    this.projectSettingModule.queuePosition({ prefix: this.prefix, ...args })

  public readonly outFromQueue: ProjectReviewerDAO['outFromQueue'] = (args) =>
    this.projectSettingModule.outFromQueue({ prefix: this.prefix, ...args })

  public readonly statistics: ProjectReviewerDAO['statistics'] = (args) =>
    this.fetchBase.request<ProjectReviewerStatisticsResData>(`${this.prefix}/${args.path}/statistics`, {
      ...args,
      method: 'GET'
    })

  public readonly participate: ProjectReviewerDAO['participate'] = (args) =>
    this.fetchBase.request<unknown>(`${this.prefix}/${args.path}/participate/`, {
      method: 'POST',
      ...args
    })
}
