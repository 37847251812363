import type { PopupDAO } from '~/api/shared/board/types'
import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule } from '~/api/shared/core/modules'

export class PopupAPI extends DAOBase implements PopupDAO {
  private commonModule: CommonModule
  private prefix = '/popups'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
  }

  public readonly list: PopupDAO['list'] = ({ options }) =>
    this.commonModule.list<unknown>({ prefix: this.prefix, options })

  public readonly retrieve: PopupDAO['retrieve'] = ({ id, options }) =>
    this.commonModule.retrieve<unknown>({ prefix: this.prefix, id, options })

  public readonly create: PopupDAO['create'] = ({ body, options }) =>
    this.commonModule.create<unknown>({ prefix: this.prefix, body, options })

  public readonly update: PopupDAO['update'] = ({ id, body, options }) =>
    this.commonModule.update<unknown>({ prefix: this.prefix, id, body, options })

  public readonly partialUpdate: PopupDAO['partialUpdate'] = ({ id, body, options }) =>
    this.commonModule.partialUpdate<unknown>({ prefix: this.prefix, id, body, options })

  public readonly destroy: PopupDAO['destroy'] = ({ id, body, options }) =>
    this.commonModule.destroy<unknown>({ prefix: this.prefix, id, body, options })
}
