import { createSection } from '@formkit/inputs'

/**
 * Option section used to show an option
 *
 * @public
 */
export const optionLabelOuter = createSection('optionLabelOuter', () => ({
  $el: 'div',
  attrs: {
    class: '$classes.optionLabelOuter'
  }
}))
