import FetchBase from '~/api/shared/core/fetch-base/FetchBase'
import type { $FetchOptions, $FetchRequest } from '~/api/shared/core/types'
import { TOKEN } from '~/constants/shared'

const whiteListConfig = {
  arrayQueryParams: ['ids', 'ids_ex']
}

export class DefaultFetchBase extends FetchBase {
  public readonly request = <TReturn>(uri: $FetchRequest, options: $FetchOptions = {}) => {
    const fetchOptions: $FetchOptions = {
      ...options,
      // @ts-ignore
      onRequest: ({ _, options }) => {
        const authToken = useCookie(TOKEN.AUTH.value)
        const tenantToken = useCookie(TOKEN.TENANT.value)

        if (authToken.value) {
          options.headers.set('Authorization', `Token ${authToken.value}`)
        }
        if (tenantToken.value) {
          options.headers.set('Synapse-Tenant', `Token ${tenantToken.value}`)
        }

        try {
          const temp = new URLSearchParams(options.query)

          for (const key in options.query) {
            if (Array.isArray(options.query[key]) && whiteListConfig.arrayQueryParams.includes(key)) {
              options.query[key] = temp.get(key)
            }
          }
        } catch (error) {
          throw new Error(JSON.stringify(error))
        }
      },
      onResponseError: ({ response }) => {
        if (response.status === 403) {
          showError({ statusCode: 403 })
        }
      }
    }

    const response = this.$fetch<TReturn, $FetchRequest, $FetchOptions>(uri, fetchOptions)

    return response as Promise<TReturn>
  }
}
