import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { BulkDestroyModule, CommonModule } from '~/api/shared/core/modules'
import type {
  MemberCreateResData,
  MemberDAO,
  MemberDestroyResData,
  MemberGrantableRolesResData,
  MemberInviteResData,
  MemberListResData,
  MemberPartialUpdateResData,
  MemberRetrieveResData,
  MemberRolesResData,
  MembersBulkDestroyResData,
  MemberUpdateResData
} from '~/api/worker/Member.types'

export class MemberAPI extends DAOBase implements MemberDAO {
  private commonModule: CommonModule
  private prefix = '/members'
  private bulkDestroyModule: BulkDestroyModule

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
      bulkDestroyModule: BulkDestroyModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
    this.bulkDestroyModule = modules.bulkDestroyModule
  }

  public readonly list: MemberDAO['list'] = (args) =>
    this.commonModule.list<MemberListResData>({ ...args, prefix: this.prefix })

  public readonly retrieve: MemberDAO['retrieve'] = (args) =>
    this.commonModule.retrieve<MemberRetrieveResData>({ ...args, prefix: this.prefix })

  public readonly create: MemberDAO['create'] = (args) =>
    this.commonModule.create<MemberCreateResData>({ ...args, prefix: this.prefix })

  public readonly update: MemberDAO['update'] = (args) =>
    this.commonModule.update<MemberUpdateResData>({ ...args, prefix: this.prefix })

  public readonly partialUpdate: MemberDAO['partialUpdate'] = (args) =>
    this.commonModule.partialUpdate<MemberPartialUpdateResData>({ ...args, prefix: this.prefix })

  public readonly destroy: MemberDAO['destroy'] = (args) =>
    this.commonModule.destroy<MemberDestroyResData>({ ...args, prefix: this.prefix })

  public readonly bulkDestroy: MemberDAO['bulkDestroy'] = (args) =>
    this.bulkDestroyModule.bulkDestroy<MembersBulkDestroyResData>({ ...args, prefix: this.prefix })

  public readonly roles: MemberDAO['roles'] = (args) => {
    const response = this.fetchBase.request<MemberRolesResData>(`${this.prefix}/roles/`, { ...args })
    return response
  }

  public readonly grantableRoles: MemberDAO['grantableRoles'] = (args) => {
    const response = this.fetchBase.request<MemberGrantableRolesResData>(`${this.prefix}/grantable_roles/`, {
      ...args
    })
    return response
  }

  public readonly invite: MemberDAO['invite'] = (args) => {
    return this.fetchBase.request<MemberInviteResData>(`${this.prefix}/invite/`, {
      ...args,
      method: 'POST'
    })
  }
}
