import { cva } from 'class-variance-authority'

export { default as Button } from './Button.vue'

export const buttonContentVariants = cva('group flex items-center truncate text-nowrap', {
  variants: {
    variant: {
      solid: '',
      tint: '',
      outline: '',
      ghost: '',
      link: 'gap-x-1'
    },
    size: {
      xl: '',
      lg: '',
      md: '',
      sm: ''
    }
  },
  compoundVariants: [
    { variant: ['solid', 'tint', 'outline', 'ghost'], size: 'xl', class: 'gap-x-2' },
    { variant: ['solid', 'tint', 'outline', 'ghost'], size: 'lg', class: 'gap-x-1.5' },
    { variant: ['solid', 'tint', 'outline', 'ghost'], size: 'md', class: 'gap-x-1' },
    { variant: ['solid', 'tint', 'outline', 'ghost'], size: 'sm', class: 'gap-x-0.5' }
  ]
})

export const buttonIconVariants = cva('', {
  variants: {
    variant: {
      solid: 'text-white-950',
      tint: '',
      outline: '',
      ghost: '',
      link: ''
    },
    color: { blue: '', slate: '', red: '', black: '', yellow: '', green: '' },
    size: {
      xl: '',
      lg: '',
      md: '',
      sm: '',
      xs: ''
    },
    affix: {
      true: '',
      false: ''
    }
  },
  compoundVariants: [
    {
      variant: ['tint', 'outline', 'ghost', 'link'],
      color: 'blue',
      class: 'text-blue-500 group-disabled:text-blue-300'
    },
    { variant: 'link', color: 'blue', class: 'group-hover:text-blue-600' },
    {
      variant: ['tint', 'outline', 'ghost', 'link'],
      color: 'slate',
      class: 'text-slate-500 group-disabled:text-slate-300'
    },
    { variant: 'link', color: 'slate', class: 'group-hover:text-slate-600' },
    { variant: ['tint', 'outline', 'ghost', 'link'], color: 'red', class: 'text-red-500 group-disabled:text-red-300' },
    { variant: 'link', color: 'red', class: 'group-hover:text-red-600' },
    { variant: 'tint', color: 'black', class: 'text-white-950' },
    { variant: ['outline', 'ghost'], color: 'black', class: 'text-black-500 group-disabled:text-black-300' },
    { variant: ['tint', 'outline', 'ghost'], color: 'yellow', class: 'text-yellow-500 group-disabled:text-yellow-300' },
    { variant: ['tint', 'outline', 'ghost'], color: 'green', class: 'text-green-500 group-disabled:text-green-300' },
    { affix: false, size: 'xl', class: 'h-7 w-7' },
    { affix: false, size: 'lg', class: 'h-6 w-6' },
    { affix: false, size: 'md', class: 'h-5 w-5' },
    { affix: false, size: 'sm', class: 'h-4 w-4' },
    { affix: false, size: 'xs', class: 'h-3 w-3' },
    { affix: true, size: 'xl', class: 'h-6 w-6' },
    { affix: true, size: 'lg', class: 'h-5 w-5' },
    { affix: true, size: 'md', class: 'h-4 w-4' },
    { affix: true, size: 'sm', class: 'h-3 w-3' }
  ],
  defaultVariants: {
    affix: false
  }
})

export const buttonVariants = cva(
  'group flex h-fit w-fit cursor-pointer items-center justify-center disabled:pointer-events-none',
  {
    variants: {
      variant: {
        solid: '',
        tint: '',
        outline: '',
        ghost: '',
        link: ''
      },
      size: {
        xl: 'py-2.5 text-xl',
        lg: 'py-2 text-base',
        md: 'py-1.5 text-sm',
        sm: 'py-1 text-xs',
        xs: 'py-0.5'
      },
      color: { blue: '', slate: '', red: '', black: '', yellow: '', green: '' },
      icon: {
        true: '',
        false: ''
      },
      prefixIcon: {
        true: '',
        false: ''
      },
      suffixIcon: {
        true: '',
        false: ''
      },
      squared: {
        true: 'rounded-sm',
        false: 'rounded-lg'
      }
    },
    compoundVariants: [
      { variant: 'link', size: ['xl', 'lg', 'md', 'sm'], class: 'py-0' },
      { icon: true, size: 'xl', class: 'px-2.5' },
      { icon: true, size: 'lg', class: 'px-2' },
      { icon: true, size: 'md', class: 'px-1.5' },
      { icon: true, size: 'sm', class: 'px-1' },
      { icon: true, size: 'xs', class: 'px-0.5' },
      { icon: true, variant: ['solid', 'tint', 'outline', 'ghost'], size: 'xl', class: 'px-2.5' },
      { icon: true, variant: ['solid', 'tint', 'outline', 'ghost'], size: 'lg', class: 'px-2' },
      { icon: true, variant: ['solid', 'tint', 'outline', 'ghost'], size: 'md', class: 'px-1.5' },
      { icon: true, variant: ['solid', 'tint', 'outline', 'ghost'], size: 'sm', class: 'px-1' },
      { icon: true, variant: ['solid', 'tint', 'outline', 'ghost'], size: 'xs', class: 'px-0.5' },
      {
        icon: false,
        prefixIcon: false,
        suffixIcon: false,
        variant: ['solid', 'tint', 'outline', 'ghost'],
        size: 'xl',
        class: 'px-6'
      },
      {
        icon: false,
        prefixIcon: false,
        suffixIcon: false,
        variant: ['solid', 'tint', 'outline', 'ghost'],
        size: 'lg',
        class: 'px-5'
      },
      {
        icon: false,
        prefixIcon: false,
        suffixIcon: false,
        variant: ['solid', 'tint', 'outline', 'ghost'],
        size: 'md',
        class: 'px-4'
      },
      {
        icon: false,
        prefixIcon: false,
        suffixIcon: false,
        variant: ['solid', 'tint', 'outline', 'ghost'],
        size: 'sm',
        class: 'px-3'
      },
      {
        icon: false,
        prefixIcon: true,
        suffixIcon: false,
        variant: ['solid', 'tint', 'outline', 'ghost'],
        size: 'xl',
        class: 'pl-4 pr-5'
      },
      {
        icon: false,
        prefixIcon: true,
        suffixIcon: false,
        variant: ['solid', 'tint', 'outline', 'ghost'],
        size: 'lg',
        class: 'pl-3 pr-4'
      },
      {
        icon: false,
        prefixIcon: true,
        suffixIcon: false,
        variant: ['solid', 'tint', 'outline', 'ghost'],
        size: 'md',
        class: 'pl-2 pr-3'
      },
      {
        icon: false,
        prefixIcon: true,
        suffixIcon: false,
        variant: ['solid', 'tint', 'outline', 'ghost'],
        size: 'sm',
        class: 'pl-1.5 pr-2'
      },
      {
        icon: false,
        prefixIcon: false,
        suffixIcon: true,
        variant: ['solid', 'tint', 'outline', 'ghost'],
        size: 'xl',
        class: 'pl-5 pr-4'
      },
      {
        icon: false,
        prefixIcon: false,
        suffixIcon: true,
        variant: ['solid', 'tint', 'outline', 'ghost'],
        size: 'lg',
        class: 'pl-4 pr-3'
      },
      {
        icon: false,
        prefixIcon: false,
        suffixIcon: true,
        variant: ['solid', 'tint', 'outline', 'ghost'],
        size: 'md',
        class: 'pl-3 pr-2'
      },
      {
        icon: false,
        prefixIcon: false,
        suffixIcon: true,
        variant: ['solid', 'tint', 'outline', 'ghost'],
        size: 'sm',
        class: 'pl-2 pr-1.5'
      },
      {
        icon: false,
        prefixIcon: true,
        suffixIcon: true,
        variant: ['solid', 'tint', 'outline', 'ghost'],
        size: 'xl',
        class: 'px-4'
      },
      {
        icon: false,
        prefixIcon: true,
        suffixIcon: true,
        variant: ['solid', 'tint', 'outline', 'ghost'],
        size: 'lg',
        class: 'px-3'
      },
      {
        icon: false,
        prefixIcon: true,
        suffixIcon: true,
        variant: ['solid', 'tint', 'outline', 'ghost'],
        size: 'md',
        class: 'px-2'
      },
      {
        icon: false,
        prefixIcon: true,
        suffixIcon: true,
        variant: ['solid', 'tint', 'outline', 'ghost'],
        size: 'sm',
        class: 'px-1.5'
      },
      {
        variant: 'solid',
        color: 'blue',
        class: 'bg-blue-600 text-white-950 hover:bg-blue-700 active:bg-blue-800 disabled:bg-blue-400'
      },
      {
        variant: 'tint',
        color: 'blue',
        class: 'bg-blue-200 text-blue-600 hover:bg-blue-300 active:bg-blue-400 disabled:text-blue-400'
      },
      {
        variant: 'outline',
        color: 'blue',
        class:
          'text-blue-600 ring-1 ring-slate-300 hover:ring-blue-500 active:bg-blue-200 active:ring-blue-500 disabled:bg-transparent disabled:text-blue-400 disabled:ring-blue-300'
      },
      {
        variant: 'ghost',
        color: 'blue',
        class: 'text-blue-600 hover:bg-blue-200 active:bg-blue-300 disabled:bg-transparent disabled:text-blue-400'
      },
      {
        variant: 'link',
        color: 'blue',
        class: 'text-blue-600 hover:text-blue-700 disabled:text-blue-400'
      },
      {
        variant: 'tint',
        color: 'slate',
        class:
          'bg-slate-200 text-slate-700 hover:bg-slate-300 active:bg-slate-400 disabled:bg-slate-200 disabled:text-slate-400'
      },
      {
        variant: 'outline',
        color: 'slate',
        class:
          'text-slate-900 ring-1 ring-slate-300 hover:bg-slate-200 active:bg-slate-300 disabled:bg-transparent disabled:text-slate-400'
      },
      {
        variant: 'ghost',
        color: 'slate',
        class: 'text-slate-900 hover:bg-slate-200 active:bg-slate-300 disabled:bg-transparent disabled:text-slate-400'
      },
      {
        variant: 'link',
        color: 'slate',
        class: 'text-slate-600 hover:text-slate-700 disabled:text-slate-400'
      },
      {
        variant: 'solid',
        color: 'red',
        class: 'bg-red-600 text-white-950 hover:bg-red-700 active:bg-red-800 disabled:bg-red-400'
      },
      {
        variant: 'tint',
        color: 'red',
        class: 'bg-red-200 text-red-600 hover:bg-red-300 active:bg-red-400 disabled:text-red-400'
      },
      {
        variant: 'outline',
        color: 'red',
        class:
          'text-red-600 ring-1 ring-red-300 hover:bg-red-200 active:bg-red-300 disabled:bg-transparent disabled:text-red-400'
      },
      {
        variant: 'ghost',
        color: 'red',
        class: 'text-red-600 hover:bg-red-200 active:bg-red-300 disabled:bg-transparent disabled:text-red-400'
      },
      {
        variant: 'link',
        color: 'red',
        class: 'text-red-600 hover:text-red-700 disabled:text-red-400'
      },
      {
        variant: 'tint',
        color: 'black',
        class: 'bg-black-400 text-white-950 hover:bg-black-500 active:bg-black-600 disabled:bg-black-200'
      },
      {
        variant: 'outline',
        color: 'black',
        class:
          'text-black-700 ring-1 ring-black-300 hover:bg-black-200 active:bg-black-300 disabled:bg-transparent disabled:text-black-400'
      },
      {
        variant: 'ghost',
        color: 'black',
        class: 'text-black-700 hover:bg-black-200 active:bg-black-300 disabled:bg-transparent disabled:text-black-400'
      },
      {
        variant: 'tint',
        color: 'yellow',
        class: 'bg-yellow-200 text-yellow-600 hover:bg-yellow-300 active:bg-yellow-400 disabled:text-yellow-400'
      },
      {
        variant: 'outline',
        color: 'yellow',
        class:
          'text-yellow-600 ring-1 ring-yellow-300 hover:bg-yellow-200 active:bg-yellow-300 disabled:bg-transparent disabled:text-yellow-400'
      },
      {
        variant: 'ghost',
        color: 'yellow',
        class:
          'text-yellow-600 hover:bg-yellow-200 active:bg-yellow-300 disabled:bg-transparent disabled:text-yellow-400'
      },
      {
        variant: 'tint',
        color: 'green',
        class: 'bg-green-200 text-green-600 hover:bg-green-300 active:bg-green-400 disabled:text-green-400'
      },
      {
        variant: 'outline',
        color: 'green',
        class:
          'text-green-600 ring-1 ring-green-300 hover:bg-green-200 active:bg-green-300 disabled:bg-transparent disabled:text-green-400'
      },
      {
        variant: 'ghost',
        color: 'green',
        class: 'text-green-600 hover:bg-green-200 active:bg-green-300 disabled:bg-transparent disabled:text-green-400'
      }
    ],
    defaultVariants: {
      variant: 'solid',
      size: 'md',
      color: 'blue',
      squared: false
    }
  }
)
