<template>
  <div>
    <Head>
      <Meta http-equiv="Page-Enter" content="revealtrans(Duration=1, Transition=12)" />
      <!--      <Title>{{ title }}</Title>-->
      <!--      <Meta name="description" :content="title" />-->
      <!--      <Style type="text/css" children="body { background-color: green; }"></Style>-->
    </Head>
    <NuxtLoadingIndicator />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
    <ClientOnly>
      <LazyPasswordConfirmModal v-if="dialogStore[DIALOG.PASSWORD_CONFIRM]" />
      <LazyMemberRemoveConfirm v-if="alertStore[DIALOG.MEMBER_REMOVE_CONFIRM]" />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { defineAlertDialogStore } from '~/components/ui/alert-dialog/useAlertDialog'
import { defineDialogStore } from '~/components/ui/dialog/useDialog'
import { usePageMetaPrefetch } from '~/composables/shared/usePageMetaPrefetch'
import { DIALOG } from '~/constants/shared/dialog'

const dialogStore = defineDialogStore({
  [DIALOG.PASSWORD_CONFIRM]: false
})

const alertStore = defineAlertDialogStore({
  [DIALOG.MEMBER_REMOVE_CONFIRM]: false
})

useSeoMeta({
  charset: 'utf-8',
  viewport: 'width=device-width, initial-scale=1.0',
  contentType: 'text/html; charset=utf-8',
  title: 'Synapse Workspace',
  ogTitle: 'Synapse Workspace',
  description: 'The All-In-One MLOps Platform',
  ogDescription: 'The All-In-One MLOps Platform'
})

useHead({
  link: [
    // {
    //   href: 'https://cdn.jsdelivr.net/npm/@blowstack/ckeditor5-full-free-build@27.0.1/build/ckeditor.min.js',
    //   as: 'script',
    //   rel: 'prefetch'
    // }
    // {
    //   href: 'https://cdn.jsdelivr.net/npm/@ckeditor/ckeditor5-vue@7.2.0/dist/ckeditor.min.js',
    //   as: 'script',
    //   rel: 'prefetch'
    // }
  ]
})

await usePageMetaPrefetch()
</script>
