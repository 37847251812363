import type {
  ModelTaskDAO,
  ModelTaskListResData,
  ModelTaskRetrieveResData
} from '~/api/machine-learning/ModelTask.types'
import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule } from '~/api/shared/core/modules'

export class ModelTaskAPI extends DAOBase implements ModelTaskDAO {
  private commonModule: CommonModule
  private prefix = '/model_tasks'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
  }

  public readonly list: ModelTaskDAO['list'] = (args) =>
    this.commonModule.list<ModelTaskListResData>({ ...args, prefix: this.prefix })

  public readonly retrieve: ModelTaskDAO['retrieve'] = (args) =>
    this.commonModule.retrieve<ModelTaskRetrieveResData>({ ...args, prefix: this.prefix })
}
