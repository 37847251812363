import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule } from '~/api/shared/core/modules'
import type {
  GroundTruthEventCreateGroundTruthsResData,
  GroundTruthEventCreateResData,
  GroundTruthEventDAO,
  GroundTruthEventListResData,
  GroundTruthEventRetrieveResData
} from '~/api/shared/ground-truth/GroundTruthEvent.types'

export class GroundTruthEventAPI extends DAOBase implements GroundTruthEventDAO {
  private commonModule: CommonModule
  private prefix = '/ground_truth_events'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
  }

  public readonly list: GroundTruthEventDAO['list'] = (args) =>
    this.commonModule.list<GroundTruthEventListResData>({ prefix: this.prefix, ...args })

  public readonly retrieve: GroundTruthEventDAO['retrieve'] = (args) =>
    this.commonModule.retrieve<GroundTruthEventRetrieveResData>({ prefix: this.prefix, ...args })

  public readonly create: GroundTruthEventDAO['create'] = (args) =>
    this.commonModule.create<GroundTruthEventCreateResData>({ prefix: this.prefix, ...args })

  public readonly createGroundTruths: GroundTruthEventDAO['createGroundTruths'] = (args) =>
    this.fetchBase.request<GroundTruthEventCreateGroundTruthsResData>(`${this.prefix}/create_ground_truths/`, {
      ...args,
      method: 'POST',
      body: args.body
    })
}
