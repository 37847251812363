import { createInput } from '@formkit/vue'
import RichTextInput from './RichText.client.vue'

export const richText = createInput(RichTextInput, {
  props: {
    toolbar: { default: [] },
    preset: { default: 'default' }
  }
})

export const toolbarPresets = {
  default: [
    'Undo',
    'Redo',
    'RemoveFormat',
    'Heading',
    '|',
    'FontSize',
    'bold',
    'italic',
    'Underline',
    'Strikethrough',
    'FontColor',
    'FontBackgroundColor',
    '|',
    'Link',
    'alignment',
    'indent',
    'outdent',
    '|',
    'BulletedList',
    'NumberedList',
    'todoList',
    'InsertImage',
    'InsertTable',
    'Horizontalline'
  ]
}
