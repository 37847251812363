import { extend } from '@formkit/utils'

export default function variant(node) {
  node.addProps({
    variant: {
      default: 'native'
    }
  })

  const schemaFn = node.props.definition.schema
  node.props.definition.schema = (sectionsSchema = {}) => {
    sectionsSchema.outer = extend(sectionsSchema.outer, {
      attrs: {
        'data-ui': '$variant'
      }
    })
    return schemaFn(sectionsSchema)
  }
}
