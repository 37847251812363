import type { AlertDialogOptions, DialogStore } from '~/components/ui/alert-dialog/types'

let eventStore: AlertDialogOptions['events'] = {
  onOpen: () => {},
  onClose: () => {},
  onConfirm: () => {},
  onCancel: () => {}
}

export const useAlertDialogStore = () => {
  return useState<DialogStore>('ALERT_DIALOG_STORE', () => ({}))
}

export const defineAlertDialogStore = (object: { [key: string]: boolean }) => {
  const store = useAlertDialogStore()
  store.value = { ...object }
  return store
}

function useAlertDialog(key: string) {
  const dialogStore = useAlertDialogStore()

  const dialog = useState<AlertDialogOptions>(key, () => ({
    get open() {
      return dialogStore.value[key] || false
    },
    set open(value) {
      dialogStore.value[key] = value
    },
    title: '',
    description: '',
    footerButtons: [{ label: '취소', variant: 'outline', color: 'black' }, { label: '실행' }]
  }))

  function closeDialog(managedKey?: string) {
    if (typeof managedKey === 'string') {
      dialogStore.value[managedKey] = false
    } else {
      dialogStore.value[key] = false
    }

    dialog.value.open = false
  }

  function open(options?: Omit<AlertDialogOptions, 'open'> | null, managedKey?: string) {
    if (!(options instanceof Event) && options) {
      dialog.value = { ...options, open: true }
      if (options.events && import.meta.client) eventStore = { ...options.events }
    }
    dialog.value.open = true
    dialogStore.value[managedKey ?? key] = true
    eventStore?.onOpen?.()
  }

  function close(managedKey?: string) {
    closeDialog(managedKey)
    eventStore?.onClose?.()
  }

  function confirm(managedKey?: string) {
    closeDialog(managedKey)
    eventStore?.onConfirm?.()
  }

  function cancel(managedKey?: string) {
    closeDialog(managedKey)
    eventStore?.onCancel?.()
  }

  return {
    open,
    close,
    confirm,
    cancel,
    dialog,
    dialogStore
  }
}

export function useAlert(key: string = 'ALERT_DIALOG') {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { cancel, ...rest } = useAlertDialog(key)
  rest.dialog.value.footerButtons = [{ label: '확인', onClick: rest.confirm }]

  return rest
}

export function useConfirm(key: string = 'CONFIRM_DIALOG') {
  return useAlertDialog(key)
}
