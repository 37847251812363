import { usePageMetaQuery } from '~/composables/shared/usePageMetaQuery'

export const useTenantMetaQuery = async () => {
  return await usePageMetaQuery(async (route, createPageMeta) => {
    await Promise.resolve()

    return createPageMeta({
      title: 'settings',
      directoryNavigation: {
        personal: {
          prefixIcon: 'heroicons:cog-8-tooth'
        },
        members: {
          prefixIcon: 'heroicons:user'
        },
        integrations: {
          children: {
            agents: {}
          }
        },
        plugins: {
          children: {
            neural_net: {}
          }
        },
        '': {
          class: 'hidden'
        },
        'account-change': {
          class: 'hidden'
        },
        create: {
          class: 'hidden'
        },
        groups: {
          class: 'hidden'
        },
        manage: {
          class: 'hidden'
        }
      }
    })
  })
}
