import type {
  DataFileCreateResData,
  DataFileDAO,
  DataFileRetrieveResData,
  DataFileVerifyChecksumsResData
} from '~/api/catalog/DataFile.types'
import { DAOBase } from '~/api/shared/core/dao-base/DAOBase'
import { DefaultFetchBase } from '~/api/shared/core/fetch-base'
import { CommonModule } from '~/api/shared/core/modules'

export class DataFileAPI extends DAOBase implements DataFileDAO {
  private commonModule: CommonModule
  private prefix = '/data_files'

  constructor(
    fetchBase: DefaultFetchBase,
    modules: {
      commonModule: CommonModule
    }
  ) {
    super(fetchBase, modules)
    this.commonModule = modules.commonModule
  }

  public readonly retrieve: DataFileDAO['retrieve'] = (args) =>
    this.commonModule.retrieve<DataFileRetrieveResData>({ ...args, prefix: this.prefix })

  public readonly create: DataFileDAO['create'] = (args) =>
    this.commonModule.create<DataFileCreateResData>({ ...args, prefix: this.prefix })

  public readonly verifyChecksums: DataFileDAO['verifyChecksums'] = (args) => {
    const response = this.fetchBase.request<DataFileVerifyChecksumsResData>(`${this.prefix}/verify_checksums/`, {
      ...args,
      method: 'POST'
    })
    return response
  }
}
